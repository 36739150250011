import { convertStringToBoolean, parseObjectToUrlQuery } from 'src/helpers'
import axios from '../../axios'
import { notifyError } from '../notify'

const DailyOffersService = {
  getDailyOffers: (params) => async () => {
    return await axios
      .get(`/api/daily-offers${parseObjectToUrlQuery(params)}`)
      .then((res) => {
        console.log('@GetFlashOffers data:', res.data)
        // notifySucces()
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  getDailyOffer: (id) => async () => {
    return await axios
      .get(`/api/daily-offers/${id}`)
      .then((res) => {
        console.log(res.data)
        return res.data.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  addDailyOffer: async (data) => {
    console.log(data)
    return await axios
      .post('/api/daily-offers', convertStringToBoolean(data))
      .then((res) => {
        console.log(res.data)
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  editDailyOffer: (id) => async (data) => {
    console.log('@editFlashOffer payload', data)
    return await axios
      .put(`/api/daily-offers/${id}`, data)
      .then((res) => {
        console.log('saved data', res.data)
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
}

export default DailyOffersService
