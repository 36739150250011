const formFields = (maxToReserve) => {
  return [
    [
      {
        id: 'contractor',
        category: '',
        label: 'KLIENT',
        type: 'dynamicDropdown',
        endpoint: 'contractors',
        nameField: 'name',
        placeholder: 'Klient',
        required: true,
        valueAsNumber: true,
      },
      {
        id: 'amountOfCodes',
        category: '',
        label: 'ILOŚĆ KODÓW',
        placeholder: '',
        type: 'number',
        required: true,
        valueAsNumber: true,
        min: 0,
      },
      {
        id: 'sellingPrice',
        category: '',
        label: 'CENA',
        placeholder: '',
        type: 'number',
        required: true,
        valueAsNumber: true,
        step: 0.01,
      },
    ],
  ]
}

export default formFields
