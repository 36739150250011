import axios from '../axios'
import { notifyError, notifySucces } from './notify'

const AuthService = {
  CheckToken: async (token) => {
    return await axios
      .get('/api/me', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200 && res.data) {
          console.log('@CheckedToken', res.data)
          return res.data.data
        }
        return
      })
      .catch((e) => {
        console.log(e)
        return
      })
  },
  Login: async ({ username, password }) => {
    return await axios
      .post(
        '/api/login',
        {
          username,
          password,
        },
        {
          validateStatus: function (status) {
            return status < 400
          },
        }
      )
      .then((res) => {
        return res.data
      })
      .catch((e) => {
        notifyError('Błąd logowania')
        //omit if error on 1 step of logging in
        return
      })
  },
  Refresh: async () => {
    return await axios
      .post('/api/token/refresh')
      .then((res) => {
        notifySucces('Sesja została odświeżona')
        return res.data
      })
      .catch((e) => {
        notifyError('Nastąpiło wylogowanie')
      })
  },
  Logout: () => {
    // TODO logout request to server to invalidate token
  },

  ValidateTfa: async ({ authCode }) => {
    return axios
      .post(
        '/api/2fa/check',
        { authCode },
        {
          validateStatus: function (status) {
            return status < 400
          },
        }
      )
      .then((res) => {
        // console.log(res)
        if (res.data.error || !res.data) {
          // notifyError('Niepoprawne dane logowania')
          throw new Error('')
        }
        return res.data
      })
      .catch((e) => {
        console.log(e)
        notifyError('Niepoprawne dane logowania')
      })
  },
}

export default AuthService
