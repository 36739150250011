import { CContainer, CSpinner } from '@coreui/react-pro'
import React from 'react'

const IsLoading = () => {
  return (
    <CContainer className="fill d-flex vh-100 mx-auto my-auto px-auto align-items-center justify-content-center ">
      <CSpinner color="primary" />
    </CContainer>
  )
}

export default IsLoading
