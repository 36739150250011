import { CModal, CModalBody, CModalFooter } from '@coreui/react-pro'
import React from 'react'
import CancelButton from 'src/components/CancelButton'
import EditButton from 'src/components/EditButton'
import { copyToClipboard } from 'src/helpers'

const OrderPassModal = ({ visible, setVisible, data, orderNumber }) => {
  const handleCopyToClipboard = () => {
    copyToClipboard(`JK-ORDER-${orderNumber}: ${data.uid}, pass: ${data.password}`)
  }
  return (
    <>
      <CModal visible={visible}>
        <CModalBody className="pt-4">
          <div className="h5 mb-4">Dane dostępowe:</div>
          <p>JK-ORDER-{orderNumber}</p>
          <p>pass: {data.password}</p>
        </CModalBody>
        <CModalFooter className="flex flex-row justify-content-between">
          <EditButton onClick={handleCopyToClipboard} label="Skopiuj do schowka" />
          <CancelButton onClick={() => setVisible(false)} label="Zamknij" className="me-4" />
        </CModalFooter>
      </CModal>
    </>
  )
}

export default OrderPassModal
