import { CCard, CCardBody, CCardHeader, CCol, CContainer, CRow } from '@coreui/react-pro'
import React, { useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import DataService from 'src/services/DataService'
import { useParams } from 'react-router-dom'
import BasicList from 'src/components/BasicList'

import listFields from './DeliveryList'
import EditButton from 'src/components/EditButton'
import HistoryList from '../../components/HistoryList'
import AddProductCodesWrapper from '../../components/AddProductCodesWrapper'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import ProductCodesSummaryWrapper from '../../components/ProductCodesSummaryWrapper'
import {
  convertPriceToWholeNumber,
  fileToBase64,
  formatPriceToDisplayDecimals,
} from '../../helpers'
import IsLoadingOverlay from 'src/components/IsLoadingOverlay'
import IsLoading from 'src/components/IsLoading'
import { notifyError, notifySucces } from 'src/services/notify'
import AddCodesSummaryWrapper2 from 'src/components/AddCodesSummaryWrapper2'

const DeliveryEdit2 = () => {
  const { id } = useParams()
  const [summaryData, setSummaryData] = useState([])
  const [isDisabled] = useState(false)
  const [isLoadingOverlay, setIsLoadingOverlay] = useState(false)
  const [isQueryEnabled, setIsQueryEnabled] = useState(true)
  // const navigate = useNavigate()

  const filesToUploadRef = useRef()

  const { data, isError, error, isLoading } = useQuery(
    ['delivery', id],
    DataService.deliveries.getDelivery(id),
    {
      enabled: isQueryEnabled,
      onSuccess: (data) => {
        //reformat prices from decimals to full (123 gr to 1,23PLN)
        data.products.forEach(
          (product, id) =>
            (data.products[id].purchasePrice = formatPriceToDisplayDecimals(
              data.products[id].purchasePrice
            ))
        )
        // if (getValues('products').length === 0) {
        setValue('products', data.products)
        console.log('values changed by useQuery')
        setIsQueryEnabled(false)
        // }
        // console.log(getValues('products'), data.products)
      },
    }
  )

  const { mutateAsync: mutateAddProduct } = useMutation(
    DataService.deliveries.addProductContainer(),
    {
      onSuccess: (data) => {
        toast.success('Dodano nowy Produkt (' + data.productName + ') do Dostawy')
      },
      onError: (e) => {
        if (e?.message) {
          console.log(e.message)
          // toast.error(e.message)
        }
      },
    }
  )

  const { mutateAsync: mutateAddCodes } = useMutation(DataService.deliveries.addDeliveryCodes(), {
    onSuccess: (data) => {
      setSummaryData((prev) => {
        console.log(prev, data.data)
        return [...prev, data.data]
      })
    },
    onError: (e) => {
      if (e?.message) {
        console.log(e.message)
        // toast.error(e.message)
      }
    },
  })

  const { mutateAsync: mutateEditProductContainer } = useMutation(
    DataService.deliveries.editProductContainer(),
    {
      onError: (e) => {
        if (e?.message) {
          console.log(e.message)
          // toast.error(e.message)
        }
      },
    }
  )

  const { mutate: mutateAddFiles } = useMutation(DataService.deliveries.addDeliveryFiles2(), {
    onSuccess: ({ res, productId, name, sentCodesCount }) => {
      //add info about sucesfull save
      // const index = summaryDataRef.current.findIndex((product) => product.id === productId)
      // if (!summaryDataRef.current[index].files) summaryDataRef.current[index].files = []
      // summaryDataRef.current[index].files.push({
      //   name: name,
      //   status: 'succes',
      //   count: sentCodesCount,
      // })

      if (filesToUploadRef.current.length > 0) {
        handleFileSending()
      } else {
        //no more files to send
        // console.log(summaryDataRef.current)
        // let { sum, expected } = countAddedCodes(summaryDataRef.current)
        // console.log('@expectd: ', expected, ' codes, got: ', sum)
        // //not all codes were added
        // notifySucces(`Dodano ${sum} kodów z oczekiwanych ${expected}`)
        // setIsLoading(false)
        // console.log(summaryDataRef.current)
        setSummaryData((prev) => [...prev, res.data])
        // setSummaryData(summaryDataRef.current)
        // setIsDisabled(true)

        setIsLoadingOverlay(false)
        // navigate(`/dostawa/${id}`)
      }
    },
    onError: ({ res, productId, name }) => {
      console.log('saving falied ', res)

      // const index = summaryDataRef.current.findIndex((product) => (product.id = productId))
      // if (!summaryDataRef.current[index].files) summaryDataRef.current[index].files = []
      // summaryDataRef.current[index].files.push({ name: name, status: 'failed' })

      //despite of error try sendig rest
      handleFileSending()
    },
  })

  const handleFileSending = () => {
    if (filesToUploadRef.current.length === 0) {
    }
    const fileToSend = filesToUploadRef.current.shift()
    if (fileToSend) {
      // console.log(fileToSend)
      fileToSend.purchasePrice = convertPriceToWholeNumber(fileToSend.purchasePrice)
      mutateAddFiles({
        data: fileToSend,
        deliveryId: id,
        productId: fileToSend.deliveryProductId,
      })
    }
  }

  const { register, unregister, watch, handleSubmit, setValue } = useForm({
    defaultValues: {
      products: [], // array of products objects with codes and files and productId)
    },
  })

  const parseFiles = async (prod, deliveryProductId) => {
    console.log(prod)
    if (prod.files) {
      const fileListArray = Array.from(prod.files)
      for (const [index, file] of fileListArray.entries()) {
        console.log(index)
        filesToUploadRef.current.push({
          file: {
            base64: await fileToBase64(file),
            type: file.type,
          },
          date: new Date(),
          name: file.name,
          codesInFile: prod.codesInFile[index],
          purchasePrice: prod.purchasePrice,
          currency: 'EUR',
          productId: prod.id,
          deliveryProductId: deliveryProductId,
        })
      }
      console.log('parsed Files in global ref', filesToUploadRef)
    }
    return true
  }
  const onSubmitAddCodes = async ({ products }) => {
    setIsLoadingOverlay(true)
    filesToUploadRef.current = []
    console.log(products)

    for (const key in products) {
      const prod = products[key]
      console.log(key)

      // execute add-product endpoint for all new products
      if (!prod.hasOwnProperty('product')) {
        console.log('@adding new prod to delivery')
        await mutateAddProduct({
          formData: {
            product: prod.id,
            purchasePrice: convertPriceToWholeNumber(prod.purchasePrice),
            expectedQuantity: prod.expectedQuantity,
            currency: 'EUR',
          },
          deliveryId: id,
        }).then(async ({ deliveryProductId }) => {
          // add codes for new product
          if (prod.hasOwnProperty('codes') && prod.codes !== null) {
            console.log('@adding text codes to delivery new prod')
            mutateAddCodes({
              data: {
                codes: prod.codes,
              },
              deliveryId: id,
              deliveryProductId: deliveryProductId,
            })
              .then(() => {
                toast.success(`Kody textowe dla produktu (${prod.product.name}) zostały dodane`)
              })
              .catch(() => {
                toast.error(
                  'Coś poszło nie tak! Nie udało się dodać kodów dla produktu ' + prod.name
                )
              })
            // add code-files for new product
          }
          if (prod.files.length > 0) {
            console.log('@adding files for new prod in delivery')
            const parsed = await parseFiles(prod, deliveryProductId)
            if (parsed === true) console.log('@files parsed succesfuly')
          }
        })
      } else {
        // edit if needed previously created product
        if (
          prod.purchasePrice !== data.products[key].purchasePrice ||
          prod.expectedQuantity !== data.products[key].expectedQuantity
        ) {
          await mutateEditProductContainer({
            formData: {
              expectedQuantity: prod.expectedQuantity,
              purchasePrice: convertPriceToWholeNumber(prod.purchasePrice),
            },
            productContainerId: prod.id,
          })
            .then(() => {
              notifySucces('Dane produktu dostawy zostały zaktualizowane')
            })
            .catch(() => {
              notifyError(
                'Coś poszło nie tak! Nie udało się zmienić danych produktu ' + prod.product.name
              )
            })
        }

        if (prod.hasOwnProperty('codes') && prod.codes !== null) {
          // add codes to previously created product
          await mutateAddCodes({
            data: {
              codes: prod.codes,
            },
            deliveryId: id,
            deliveryProductId: prod.id,
          })
            .then((e) => {
              const erroredCodes = e?.data.filter((obj) => 'error' in obj && obj.error !== null)
              if (erroredCodes) {
                erroredCodes.forEach((code) => {
                  notifyError(code.error)
                })
                notifySucces(
                  `Tylko część kodów tekstowych spełniająca warunki została dodana do produktu ${prod.product.name}`
                )
              } else {
                notifySucces(`Kody tekstowe dla produktu (${prod.product.name}) zostały dodane`)
              }
            })
            .catch(() => {
              notifyError('Coś poszło nie tak! Nie udało się dodać kodów dla produktu ' + prod.name)
            })
        }
        if (prod?.files.length > 0) {
          console.log('@adding files for new prod in delivery')
          const parsed = await parseFiles(prod, prod.id)
          if (parsed === true) console.log('@files parsed succesfuly')
        }
      }
    }
    //send all files gathered above one by one:
    console.log('@starting files upload')
    if (filesToUploadRef.current.length > 0) {
      handleFileSending()
    } else {
      // LoaderService.hide()
      setIsLoadingOverlay(false)
      // navigate(`/dostawa/${id}`)
    }
  }

  if (isLoading) return <IsLoading />

  if (isError) {
    console.log(error)
    return <span>bląd</span>
  }

  return (
    <>
      <CContainer fluid className="h-100">
        {isLoadingOverlay && <IsLoadingOverlay />}
        <CRow>
          <CCol className="d-flex flex-row justify-content-between pb-4">
            <h2>Dodawanie kodów/produktów do Dostawy nr {data?.id}</h2>
            <div>
              {summaryData.length === 0 && (
                <EditButton
                  onClick={handleSubmit((data) => onSubmitAddCodes(data))}
                  label={'Zapisz kody'}
                />
              )}
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol xs={3}>
            <CCard>
              <CCardHeader>Dane Dostawcy</CCardHeader>
              <BasicList listFields={listFields} data={data} />
            </CCard>
            <CCard>
              <CCardHeader>Historia</CCardHeader>
              <CCardBody>
                <HistoryList data={data.history} />
              </CCardBody>
            </CCard>
          </CCol>
          <CCol lg={5}>
            <CCard className="bg-transparent">
              <CCardBody className="px-0 ">
                {!summaryData || summaryData.length === 0 ? (
                  <AddProductCodesWrapper
                    register={register}
                    unregister={unregister}
                    watch={watch}
                    setValue={setValue}
                    disabled={isDisabled}
                    edition={data.products}
                  />
                ) : (
                  <AddCodesSummaryWrapper2 data={summaryData} disabled={isDisabled} />
                )}
              </CCardBody>
            </CCard>
          </CCol>
          <CCol lg={4}>
            <CCard>
              <CCardHeader>Podsumowanie</CCardHeader>
              <CCardBody>
                {watch().products && <ProductCodesSummaryWrapper data={watch().products} />}
              </CCardBody>
            </CCard>
            <CCard>
              <CCardHeader>Uwagi</CCardHeader>
              <CCardBody>
                <span>#Formatuj</span>
                <br />
                <span>
                  czyści formatowanie kodów usuwając &lsquo;,&lsquo; &lsquo;;&lsquo; oraz
                  wielokrotne symbole nowej lini oraz spacje
                </span>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
      {/* {console.log(generateRandomCodes(100, 13))} */}
    </>
  )
}

export default DeliveryEdit2
