import { CModal, CModalBody, CModalFooter } from '@coreui/react-pro'
import React from 'react'
import { useForm } from 'react-hook-form'
import BasicForm from 'src/components/BasicForm'
import formFields from './editCodeForm'
import IsLoadingOverlay from 'src/components/IsLoadingOverlay'
import { useMutation } from 'react-query'
import DataService from 'src/services/DataService'
import { notifyError } from 'src/services/notify'
import EditButton from 'src/components/EditButton'
import CancelButton from 'src/components/CancelButton'

const EditCodeModal = ({ code, setCode, refetch }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: { ...code },
    values: { ...code },
  })

  const {
    mutate: editCodeMutation,
    isLoading,
    reset,
  } = useMutation(DataService.deliveries.editCodeOfProductInDelivery(code.id), {
    onSuccess: () => {
      setCode(undefined)
      // notifySucces('Rezerwacja została zapisana')
      reset()
      refetch()
    },
    onError: (e) => {
      if (e?.response?.data?.error) {
        notifyError(e.response.data.error)
      }
    },
  })

  const onSubmit = async (data) => {
    editCodeMutation({
      code: data.code,
    })
  }

  return (
    <>
      <CModal visible={code !== undefined}>
        {isLoading && <IsLoadingOverlay />}
        <CModalBody className="pt-4">
          <div className="h5 mb-4">Edycja kodu Id: {code.id}:</div>
          <BasicForm
            formFields={formFields()}
            onSubmit={handleSubmit((data) => onSubmit(data))}
            register={register}
          />
        </CModalBody>
        <CModalFooter>
          <EditButton label={'Edytuj Kod'} />
          <CancelButton onClick={() => setCode(undefined)} />
        </CModalFooter>
      </CModal>
    </>
  )
}

export default EditCodeModal
