import React from 'react'
import { CSmartTable } from '@coreui/react-pro'
import { useQuery } from 'react-query'
import DataService from '../../services/DataService'
import { navigationStylingProps } from '../../assets/custom/tableSorting'

import IsLoading from 'src/components/IsLoading'
import { formatData, formatPriceToDisplayDecimals } from 'src/helpers'

export const tableProps = (navigate = null) => {
  return {
    scopedColumns: {
      endDate: ({ endDate }) => (
        <td>{endDate ? formatData(endDate.date, 'ddmmyyyyhhmm') : '-brak-'}</td>
      ),
      deliveryTime: ({ deliveryTime }) => <td>{deliveryTime}</td>,
      numberOfCodes: (data) => (
        <td>
          {data.reservedCodes} / {data.numberOfCodes ? data.numberOfCodes : 'unlimited'}
        </td>
      ),
      totalConfirmedAmountOfCodes: (data) => (
        <td>
          {data.totalConfirmedAmountOfCodes} /{' '}
          {data.numberOfCodes ? data.numberOfCodes : 'unlimited'}
        </td>
      ),
      buyingPrice: ({ buyingPrice }) => <td>{formatPriceToDisplayDecimals(buyingPrice)} EUR</td>,
      srp: ({ srp }) => <td>{srp ? formatPriceToDisplayDecimals(srp) + ' EUR' : '-'}</td>,
    },
    onRowClick: (e) => {
      navigate(`/flash/${e.id}`)
    },
    clickableRows: true,
    columnSorter: true,
    pagination: true,
    itemsPerPageSelect: true,
    itemsPerPage: 10,
    tableProps: {
      striped: true,
      hover: true,
    },
    ...navigationStylingProps,
  }
}

const ActiveFlashOffersTable = ({ id, navigate = null }) => {
  const { data, isLoading, isSuccess } = useQuery(
    ['flashOffers'],
    DataService.flashOffers.getFlashOffers({ active: true })
  )

  const columns = [
    {
      key: 'id',
      label: 'ID',
      _style: { minWidth: '60px' },
    },
    {
      key: 'name',
      category: 'product',
      label: 'Nazwa',
      _style: { minWidth: '150px' },
    },
    {
      key: 'buyingPrice',
      label: 'Cena zakupu',
      _style: { minWidth: '100px' },
    },
    {
      key: 'srp',
      label: 'SRP',
      _style: { minWidth: '100px' },
    },
    {
      key: 'numberOfCodes',
      label: 'Zgłoszone rezerwacje',
      _style: { minWidth: '100px' },
    },
    {
      key: 'totalConfirmedAmountOfCodes',
      label: 'Zarezerwowane Kody',
      _style: { minWidth: '100px' },
    },
    {
      key: 'endDate',
      label: 'Data zakończenia',
      _style: { minWidth: '100px' },
    },
    {
      key: 'deliveryTime',
      label: 'Czas dostawy',
      _style: { minWidth: '100px' },
    },
  ]

  return (
    <div>
      {isLoading && <IsLoading />}
      {isSuccess && (
        <CSmartTable
          columns={columns}
          items={data.data.results.sort((a, b) => b.id - a.id)}
          loading={isLoading}
          pagination
          {...tableProps(navigate)}
        />
      )}
    </div>
  )
}

export default ActiveFlashOffersTable
