import React from 'react'
import { CBadge } from '@coreui/react-pro'

import { navigationStylingProps } from 'src/assets/custom/tableSorting'

export const tableColumns = [
  {
    key: 'id',
    label: 'Id',
    _style: { minWidth: '60px' },
    filter: true,
  },
  {
    key: 'name',
    label: 'Imię',
    _style: { minWidth: '80px' },
  },
  {
    key: 'surname',
    label: 'Nazwisko',
    _style: { minWidth: '130px' },
  },
  {
    key: 'email',
    label: 'Email',
    _style: { minWidth: '130px' },
  },
  {
    key: 'role',
    label: 'Rola',
    _style: { minWidth: '130px' },
  },
  {
    key: 'status',
    label: 'Status',
    _style: { minWidth: '100px' },
    type: 'text',
    setValueAs: (v) => {
      return v === 'true'
    },
  },
]

export const tableProps = (navigate = null) => {
  return {
    columns: tableColumns,
    scopedColumns: {
      status: ({ status }) =>
        status === true ? (
          <td>
            <CBadge color="success">Aktywny</CBadge>
          </td>
        ) : (
          <td>
            <CBadge color="danger">Nieaktywny</CBadge>
          </td>
        ),
      role: ({ role }) =>
        role === 'ROLE_USER' ? (
          <td>Użytkownik</td>
        ) : role === 'ROLE_ACCOUNT_MANAGER' ? (
          <td>Manager</td>
        ) : (
          <td>Administrator</td>
        ),
    },
    onRowClick: (e) => {
      navigate(`/uzytkownicy/${e.id}/edit`)
    },
    clickableRows: true,
    // columnSorter: false,
    pagination: true,
    itemsPerPageSelect: true,
    itemsPerPage: 10,
    tableProps: {
      striped: true,
      hover: true,
    },
    ...navigationStylingProps,
  }
}
