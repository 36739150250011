import React from 'react'
import AddCodesSummary from './AddCodesSummary'

const AddCodesSummaryWrapper = ({ data }) => {
  return (
    <>
      {/* {console.log(data)} */}
      {data &&
        data.map((product, id) => (
          <div key={id}>
            <AddCodesSummary data={product} id={id} />
          </div>
        ))}
    </>
  )
}

export default AddCodesSummaryWrapper
