import React from 'react'

const EditButton = ({ onClick = null, label = 'Zapisz', lg = false, sm = false, className }) => {
  return (
    <label
      htmlFor="submit-form"
      tabIndex="0"
      className={
        'btn btn-primary btn-outline fw-semibold my-1 ' +
        (lg ? 'py-2' : 'py-1') +
        ' ' +
        (sm ? 'ms-2 px-2' : 'ms-4 px-5') +
        ' ' +
        className
      }
      onClick={onClick}
    >
      {label}
    </label>
  )
}

export default EditButton
