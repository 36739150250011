import { action, computed, persist, thunk } from 'easy-peasy'
import AuthService from '../services/AuthService'
import jwtDecode from 'jwt-decode'

const AuthStore = persist({
  token: null,
  tokenExpiration: null,
  user: null,
  tfa: false,
  role: computed((state) => {
    return state.user?.role || [null]
  }),
  setUser: action((state, { user }) => {
    state.user = user
  }),

  setToken: action((state, { token = null }) => {
    state.token = token
    if (!!token) state.tokenExpiration = new Date(jwtDecode(token).exp * 1000)
  }),
  getToken: action((state) => {
    return state.token
  }),

  setTfa: action((state, { two_factor_complete }) => {
    // console.log('setting tfa')
    // console.log(two_factor_complete)
    state.tfa = two_factor_complete
  }),

  isAuthenticated: computed((state) => {
    return !!state.user && !!state.token && state.tfa
    // return true
  }),

  isAdmin: computed((state) => {
    if (state.role === 'ROLE_ADMIN' || state.role === 'ROLE_SUPER_ADMIN') return true
    return false
  }),

  isSuper: computed((state) => {
    if (state.role === 'ROLE_SUPER_ADMIN') return true
    return false
  }),

  clearUser: action((state) => {
    state.user = null
    state.token = null
    state.tokenExpiration = null
    state.tfa = false
  }),

  //action to verify login credentials, recive jwt  and to set appropriate state
  loginUser: thunk(async (actions, payload) => {
    // console.log(payload)
    try {
      const { token, twoFactorSecret, twoFactorNeeded } = await AuthService.Login(payload)
      // const tokenExpiration = await AuthService.TokenExpiration(token)
      const user = await AuthService.CheckToken(token)
      actions.setUser({ user })
      actions.setToken({ token })
      if (twoFactorSecret) return twoFactorSecret
      if (twoFactorNeeded === false) {
        actions.setTfa({ two_factor_complete: true })
      }
    } catch (e) {
      return 'failed'
      // console.log(e.message)
      // throw new Error(e)
    }
  }),

  //action to clear previously loged user
  logoutUser: thunk(async (actions, payload) => {
    try {
      actions.clearUser()
      // await AuthService.Logout(paylod);
    } catch (e) {
      // console.log(e)
    }
  }),

  //action to verify if 2fa token is valid and to set appropriate state
  validateTfa: thunk(async (actions, payload, { getState }) => {
    try {
      const data = await AuthService.ValidateTfa(payload)
      // console.log(data)
      // console.log(!!data.two_factor_complete)
      if (!!data.two_factor_complete) actions.setTfa(data)
      const user = await AuthService.CheckToken(getState().token)
      actions.setUser({ user })
    } catch (e) {
      console.log('error in 2fa validation:', e)
      actions.clearUser()
    }
  }),

  refreshToken: thunk(async (actions) => {
    try {
      // console.log('refreshToken action')
      const tokenData = await AuthService.Refresh()
      actions.setToken(tokenData)
    } catch (e) {
      actions.clearUser()
    }
  }),
})

export default AuthStore
