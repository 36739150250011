import { cilPen, cilXCircle } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CFormCheck } from '@coreui/react-pro'
import React from 'react'
import { navigationStylingProps } from 'src/assets/custom/tableSorting'
import Badge from 'src/components/Badge'
import { formatPriceToDisplayDecimals } from 'src/helpers'

const SelectCompontent = ({ handleSelectAll, checkedPage }) => {
  return (
    <CFormCheck
      checked={checkedPage}
      style={{
        width: '18px',
        height: '18px',
        margin: '0px',
        padding: '0px',
      }}
      onChange={(e) => {
        handleSelectAll()
      }}
    ></CFormCheck>
  )
}

const columns = (handleSelectAll, checkedPage) => {
  return [
    {
      key: 'select',
      label: <SelectCompontent handleSelectAll={handleSelectAll} checkedPage={checkedPage} />,
      _style: { minWidth: '45px', maxWidth: '45px', width: '45px', overflow: 'hidden' },
      sorter: false,
    },
    {
      key: 'id',
      label: 'Id',
      _style: { minWidth: '60px' },
    },
    {
      key: 'sold',
      label: 'Status',
      _style: { minWidth: '110px' },
    },
    {
      key: 'code',
      label: 'Kod',
      _style: { minWidth: '200px' },
    },
    {
      key: 'action',
      label: '',
      _style: { minWidth: '100px', maxWidth: '100px', width: '100px' },
      sorter: false,
    },
  ]
}

export const tableProps = (
  handleEdit,
  handleRemove,
  handleSelectCode,
  handleSelectAll,
  selectedIds,
  checkedPage,
  setCheckedPage,
  setSelectedIds
) => {
  return {
    columns: columns(handleSelectAll, checkedPage, setCheckedPage, setSelectedIds),
    scopedColumns: {
      select: ({ id, sold }) => {
        if (checkedPage && !selectedIds.includes(id)) handleSelectCode(id)
        return (
          <td>
            {!sold && (
              <CFormCheck
                checked={selectedIds && selectedIds.includes(id)}
                style={{
                  width: '18px',
                  height: '18px',
                  margin: '0px',
                  padding: '0px',
                }}
                onChange={(e) => {
                  handleSelectCode(id)
                }}
              ></CFormCheck>
            )}
          </td>
        )
      },
      sold: ({ sold }) => (
        <td>
          <Badge status={sold ? 'sprzedane' : 'dostępne'} module={'sold'}></Badge>
        </td>
      ),
      value: ({ price, expectedQuantity, currency }) => {
        return (
          <td>
            {formatPriceToDisplayDecimals(price * expectedQuantity) + ' ' + (currency || 'EUR')}
          </td>
        )
      },
      action: (code) => {
        return (
          <td className="text-end" style={{ maxHeight: '30px', padding: '8px' }}>
            {!code.sold && (
              <>
                <CIcon
                  icon={cilPen}
                  style={{ height: '30px', width: '30px' }}
                  customClassName="btn btn-ghost-primary"
                  onClick={() => handleEdit(code)}
                />
                <CIcon
                  icon={cilXCircle}
                  style={{ height: '30px', width: '30px' }}
                  customClassName="btn btn-ghost-danger"
                  onClick={() => handleRemove([code.id])}
                />
              </>
            )}
          </td>
        )
      },
    },
    clickableRows: false,
    columnSorter: true,
    itemsPerPageSelect: true,
    itemsPerPage: 50,
    pagination: true,
    onActivePageChange: () => {
      setCheckedPage(false)
      setSelectedIds([])
    },
    tableProps: {
      striped: true,
      hover: true,
    },
    ...navigationStylingProps,
  }
}
