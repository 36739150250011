import { convertStringToBoolean, parseObjectToUrlQuery } from 'src/helpers'
import axios from '../../axios'
import { notifyError, notifySucces } from '../notify'

const FlashOffersService = {
  getFlashOffers: (params) => async () => {
    console.log(
      '@GetFlashOffers, filter by',
      `/api/flash-offers${parseObjectToUrlQuery(convertStringToBoolean(params))}`
    )
    return await axios
      .get(`/api/flash-offers${parseObjectToUrlQuery(convertStringToBoolean(params))}`)
      .then((res) => {
        console.log('@GetFlashOffers data:', res.data)
        // notifySucces()
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  getFlashOffer: (id) => async () => {
    return await axios
      .get(`/api/flash-offers/${id}`)
      .then((res) => {
        console.log('@getFlashOffer res data', res.data.data)
        return res.data.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  addFlashOffer: async (data) => {
    console.log(data)
    return await axios
      .post('/api/flash-offers', convertStringToBoolean(data))
      .then((res) => {
        console.log(res.data)
        return res.data
      })
      .catch((e) => {
        console.log(e)
        notifyError(e)
        throw e
      })
  },
  editFlashOffer: (id) => async (data) => {
    console.log('@editFlashOffer payload', data)
    return await axios
      .put(`/api/flash-offers/${id}`, data)
      .then((res) => {
        console.log('saved data', res.data)
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },

  getFlashOfferReservations: (id) => async () => {
    // console.log(`/api/flash-offers/${id}/reservations`)
    return await axios
      .get(`/api/flash-offers/${id}/reservations`)
      .then((res) => {
        console.log('@getFlashOfferReservations res data', res.data.data)
        return res.data.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  reserveFlashOffer: (id) => async (data) => {
    console.log(convertStringToBoolean(data))
    return await axios
      .post(`/api/flash-offers-reserve/${id}`, convertStringToBoolean(data))
      .then((res) => {
        console.log('@ReserveFlashOffer response data:', res.data.data)
        notifySucces('Rezerwacja została dodana')
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  editReserveFlashOffer:
    () =>
    async ({ data, reserveId }) => {
      console.log('@EditFlashOffer reservation to:', convertStringToBoolean(data))
      return await axios
        .put(`/api/flash-offers-reserve/${reserveId}`, convertStringToBoolean(data))
        .then((res) => {
          console.log('@ReserveFlashOffer data from server after edit:', res.data.data)
          notifySucces('Edycja rezerwacji została zapisana')
          return res.data
        })
        .catch((e) => {
          notifyError(e)
          throw e
        })
    },
}

export default FlashOffersService
