import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CFormLabel,
  CFormSelect,
  CPagination,
  CPaginationItem,
  CRow,
} from '@coreui/react-pro'
import React, { useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import DataService from 'src/services/DataService'
import { /* useNavigate,*/ useParams } from 'react-router-dom'
import BasicList from 'src/components/BasicList'

import listFields from './ComplaintList'
import AddAttachmentRecord from 'src/components/AddAttachmentRecord'
import AttachmentRecordsList from 'src/components/AttachmentRecordsList'
import { useForm } from 'react-hook-form'
import IsLoading from 'src/components/IsLoading'
import IsLoadingOverlay from 'src/components/IsLoadingOverlay'

const Complaint = () => {
  const { id } = useParams()
  const [active, setActive] = useState(1)
  const pagesRef = useRef()
  const statusArray = ['W TRAKCIE', 'ROZWIĄZANE', 'ZAMKNIĘTE']

  const { data, isError, isLoading, refetch } = useQuery(
    ['complaint', id],
    DataService.complaints.getComplaint(id)
  )

  const { mutate: statusChangeMutation } = useMutation(DataService.complaints.editComplaint(id), {
    onSuccess: (res) => {
      refetch()
    },
    onError: (err) => {
      console.log(err)
    },
  })

  const handleStatusChange = (e) => {
    const prepedData = { ...data }
    prepedData.status = e.target.value
    prepedData.contractor = data.contractor.id
    prepedData.product = data.product.id
    prepedData.orderId = parseInt(id)
    // statusChangeMutation({ status: e.target.value, note: 'not blank forced' })
    statusChangeMutation(prepedData)
  }

  const { register } = useForm({ values: data })

  const {
    data: notesData,
    isSuccess: notesIsSuccess,
    isLoading: notesIsLoading,
    refetch: notesRefetch,
  } = useQuery(
    ['complaint', id, 'notes', active],
    DataService.complaints.getComplaintNotes(id, { page: active, perPage: 10 }),
    {
      keepPreviousData: true,
    }
  )

  const setLastPage = () => {
    setActive(Math.max(1, pagesRef.current))
  }
  //  const navigate = useNavigate()

  if (isLoading) return <IsLoading />
  if (isError) {
    return <span>błąd</span>
  }

  if (notesData?.data) pagesRef.current = notesData.data.pages

  return (
    <CContainer fluid className="h-100">
      <CRow>
        <CCol className="d-flex flex-row justify-content-between pb-4">
          <h2>{data?.contractorDetails?.companyName}</h2>
          {/*<div>
            <EditButton label={'Edytuj'} onClick={() => navigate(`/reklamacja/${id}/edit`)} />
          </div>*/}
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={4}>
          <CCard className="mb-3">
            <CCardHeader>Dane szczegółowe</CCardHeader>
            <BasicList listFields={listFields} data={data} />
          </CCard>
          <CCard>
            <CCardHeader>Działania</CCardHeader>
            <CCardBody className="p-4">
              <CFormLabel className="mb-3 mt-0 " htmlFor={'status'}>
                Zmień status:
              </CFormLabel>
              <CFormSelect
                {...register(`status`)}
                id={'status'}
                className="mb-3"
                onChange={(e) => handleStatusChange(e)}
                disabled={data.status === 'ZAMKNIĘTE'}
              >
                {statusArray.map((option, id) => (
                  <option key={id} value={option}>
                    {option}
                  </option>
                ))}
              </CFormSelect>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs={8}>
          <CCard>
            <CCardHeader>Wpisy reklamacji</CCardHeader>
            <CCardBody>
              <CRow className="px-3 mb-3">
                {notesIsLoading && <IsLoadingOverlay />}
                {notesIsSuccess && <AttachmentRecordsList data={notesData?.data?.results} />}
              </CRow>
              {notesData?.data?.results?.length > 0 && (
                <CRow>
                  <CPagination aria-label="pagination" align="center">
                    <CPaginationItem
                      disabled={active === 1}
                      onClick={() => setActive((prev) => Math.max(1, prev - 1))}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </CPaginationItem>
                    {Array.from({ length: pagesRef.current }, (_, i) => {
                      return (
                        <CPaginationItem
                          key={i}
                          active={active === i + 1}
                          onClick={() => {
                            setActive(i + 1)
                          }}
                        >
                          {i + 1}
                        </CPaginationItem>
                      )
                    })}
                    <CPaginationItem
                      disabled={active === pagesRef.current}
                      onClick={() => setActive((prev) => Math.min(pagesRef.current, prev + 1))}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </CPaginationItem>
                  </CPagination>
                </CRow>
              )}
            </CCardBody>
            <CCardHeader>Dodaj nowy wpis:</CCardHeader>
            <CCardBody>
              <AddAttachmentRecord
                productId={data.product.id}
                code={data.code}
                refetch={notesRefetch}
                setLastPage={setLastPage}
                status={data.status}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  )
}

export default Complaint
