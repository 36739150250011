const formFields = [
  [
    {
      id: 'contractor',
      category: '',
      label: 'Klient',
      placeholder: 'np. Firma',
      type: 'dynamicDropdown',
      endpoint: 'contractors',
      nameField: 'name',
      required: true,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
    {
      id: 'generateInvoice',
      category: '',
      label: 'Generuj fakturę',
      placeholder: '',
      type: 'select',
      required: false,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
  ],
]

export default formFields
