import React, { useEffect, useState, useRef } from 'react'
import {
  CButton,
  CCard,
  CCol,
  CContainer,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CRow,
  CSmartTable,
} from '@coreui/react-pro'
import { useQuery, useQueryClient } from 'react-query'
import { tableProps } from './OrdersTable'
import { useNavigate } from 'react-router-dom'
import DataService from 'src/services/DataService'
import { useForm } from 'react-hook-form'
import DynamicDropdownInput from 'src/components/DynamicDropdownInput'
import IsLoading from 'src/components/IsLoading'
import { convertPriceToWholeNumber } from 'src/helpers'
import IsLoadingOverlay from 'src/components/IsLoadingOverlay'
import { paginationProps } from 'src/assets/custom/tableSorting'

const Orders = () => {
  const navigate = useNavigate()
  const [filter, setFilter] = useState()

  const [activePage, setActivePage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [sortBy, setSortBy] = useState('id')
  const [sortDir, setSortDir] = useState('desc')

  const [prevVals, setPrevVals] = useState()

  const { data, isLoading, refetch, isRefetching } = useQuery(
    'orders',
    DataService.orders.getOrders({
      ...filter,
      page: activePage,
      perPage: itemsPerPage,
      sortBy,
      sortDir,
    }),
    { keepPreviousData: true }
  )

  const { watch, register, setValue } = useForm({
    defaultValues: {
      status: '',
      isPaid: '',
      contractor: '',
      user: '',
      valueMin: '',
      valueMax: '',
      dateFrom: '',
      dateTo: '',
      orderNumber: '',
    },
  })

  let currVals = watch()

  useEffect(() => {
    // currVals = watch()
    if (JSON.stringify(currVals) !== JSON.stringify(prevVals)) {
      setPrevVals({ ...currVals })

      //transform filter data
      let transformed = { ...currVals }
      if (transformed.valueMin)
        transformed.valueMin = convertPriceToWholeNumber(transformed.valueMin)
      if (transformed.valueMax)
        transformed.valueMax = convertPriceToWholeNumber(transformed.valueMax)
      setFilter(transformed)
    }
  }, [currVals, prevVals])

  const timeoutRef = useRef(null)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (isRefetching === true) queryClient.cancelQueries({ queryKey: 'orders' })

    if (timeoutRef.current) clearTimeout(timeoutRef.current)

    timeoutRef.current = setTimeout(() => {
      refetch()
    }, 500)
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
    // eslint-disable-next-line
  }, [filter])

  useEffect(() => {
    refetch()
    // eslint-disable-next-line
  }, [activePage, itemsPerPage, sortBy, sortDir])

  useEffect(() => {
    if (data?.data.pages < activePage) setActivePage(1)
  }, [data, activePage])

  if (isLoading) {
    return <IsLoading />
  }

  // if (!data) {
  //   console.log(error)
  //   return <span>missing data</span>
  // }

  const handleDropdownSelect = (e, id) => {
    if (e?.value) setValue(id, e.value)
  }

  const handleDropdownClear = (id) => {
    setValue(id, null)
  }

  return (
    <>
      <CContainer fluid>
        <CRow>
          <CCol className="d-flex justify-content-between pb-1">
            <h2>Zamówienia</h2>
            <CButton
              onClick={() => {
                navigate('/zamowienie/new')
              }}
              color="primary"
              className="fw-semibold px-4"
            >
              + Dodaj zamówienie
            </CButton>
          </CCol>
        </CRow>
        <CRow xs={{ gutterX: 3 }}>
          <CCol lg={3} className="align-self-start">
            <CCard className="mt-3 overflow-hidden px-3 fill h-100">
              <CForm>
                <CRow className="mb-2">
                  <CFormLabel className="mt-3" htmlFor="status">
                    Status zamówienia:
                  </CFormLabel>
                </CRow>
                <CRow className="px-2">
                  <CCol xs={6}>
                    <CRow>
                      <CFormCheck
                        button={{ color: 'primary', variant: 'outline' }}
                        {...register('status')}
                        inline
                        type="radio"
                        name="status"
                        id="status1"
                        value="NOWE"
                        label="Nowe"
                        // defaultChecked
                      />
                    </CRow>
                  </CCol>
                  <CCol xs={6}>
                    <CRow>
                      <CFormCheck
                        button={{ color: 'primary', variant: 'outline' }}
                        {...register('status')}
                        inline
                        type="radio"
                        name="status"
                        id="status2"
                        value="WYSŁANE"
                        label="Wysłane"
                      />
                    </CRow>
                  </CCol>
                </CRow>
                <CRow className="px-2 mb-3">
                  <CCol xs={6}>
                    <CRow>
                      <CFormCheck
                        button={{ color: 'primary', variant: 'outline' }}
                        {...register('status')}
                        inline
                        type="radio"
                        name="status"
                        id="status3"
                        value="ANULOWANE"
                        label="Anulowane"
                      />
                    </CRow>
                  </CCol>
                  <CCol>
                    <CRow>
                      <CFormCheck
                        button={{ color: 'primary', variant: 'outline' }}
                        {...register('status')}
                        inline
                        type="radio"
                        name="status"
                        id="status4"
                        value=""
                        label="Wszystkie"
                      />
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <CFormLabel className="mb-3" htmlFor="paymentStatus">
                    Status płatności:
                  </CFormLabel>
                </CRow>
                <CRow className="px-2 mb-3">
                  <CCol xl={4} className="">
                    <CRow>
                      <CFormCheck
                        className=""
                        button={{ color: 'primary', variant: 'outline' }}
                        {...register('isPaid')}
                        inline
                        type="radio"
                        name="isPaid"
                        id="isPaid1"
                        value=""
                        label="Wszystkie"
                        // defaultChecked
                      />
                    </CRow>
                  </CCol>
                  <CCol xl={4} className="">
                    <CRow>
                      <CFormCheck
                        className="px-0"
                        button={{ color: 'primary', variant: 'outline' }}
                        {...register('isPaid')}
                        inline
                        type="radio"
                        name="isPaid"
                        id="isPaid2"
                        value="0"
                        label="Nieopłacone"
                      />
                    </CRow>
                  </CCol>
                  <CCol xl={4}>
                    <CRow>
                      <CFormCheck
                        className="px-0"
                        button={{ color: 'primary', variant: 'outline', className: 'p-0' }}
                        {...register('isPaid')}
                        inline
                        type="radio"
                        name="isPaid"
                        id="isPaid3"
                        value="1"
                        label="Opłacone"
                      />
                    </CRow>
                  </CCol>
                </CRow>
                {/* <CRow className="px-2">
                  <CFormLabel className="mt-3" htmlFor="partner">
                    Dostawca:
                  </CFormLabel>
                </CRow> */}
                <CRow className="ps-2">
                  <CFormLabel className="mb-3 mt-3 px-0" htmlFor="orderNumber">
                    Numer Zamówienia
                  </CFormLabel>
                </CRow>
                <CRow className="px-0 mb-3">
                  <CCol xs={12} className="px-2">
                    <CFormInput
                      type="number"
                      name="orderNumber"
                      step="1"
                      min="0"
                      max={watch()?.valueMax || 9999999}
                      {...register('orderNumber')}
                    ></CFormInput>
                  </CCol>
                </CRow>
                <CRow className="px-2">
                  <DynamicDropdownInput
                    // key={reset}
                    label={'Klient:'}
                    id={'contractor'}
                    endpoint={'contractors'}
                    nameField={'name'}
                    onChange={handleDropdownSelect}
                    clear={true}
                    onClear={handleDropdownClear}
                  />
                </CRow>

                <CRow className="px-2">
                  <DynamicDropdownInput
                    // key={reset}
                    label={'Autor:'}
                    id={'user'}
                    endpoint={'users'}
                    nameField={'name'}
                    onChange={handleDropdownSelect}
                    clear={true}
                    onClear={handleDropdownClear}
                  />
                </CRow>
                <CRow className="ps-2">
                  <CFormLabel className="mb-3 mt-0 px-0" htmlFor="valueMin">
                    Wartość (od-do):
                  </CFormLabel>
                </CRow>
                <CRow className="px-0" xs={{ gutterX: 3 }}>
                  <CCol xs={6} className="px-1">
                    <CFormInput
                      type="number"
                      name="valueMin"
                      step="1"
                      min="0"
                      max={watch()?.valueMax || 9999999}
                      {...register('valueMin')}
                    ></CFormInput>
                  </CCol>
                  <CCol xs={6} className="px-1">
                    <CFormInput
                      type="number"
                      name="valueMax"
                      step="1"
                      min={watch()?.valueMin || 0}
                      {...register('valueMax')}
                    ></CFormInput>
                  </CCol>
                </CRow>
                <CRow className="ps-2">
                  <CFormLabel className="mb-3 mt-3 px-0" htmlFor="kod">
                    Data (od-do):
                  </CFormLabel>
                </CRow>
                <CRow className="px-0 mb-3" xs={{ gutterX: 3 }}>
                  <CCol xs={6} className="px-1">
                    <CFormInput type="date" name="dateFrom" {...register('dateFrom')}></CFormInput>
                  </CCol>
                  <CCol xs={6} className="px-1">
                    <CFormInput type="date" name="dateTo" {...register('dateTo')}></CFormInput>
                  </CCol>
                </CRow>
              </CForm>
            </CCard>
          </CCol>
          <CCol lg={9}>
            <CCard className="bg-transparent">
              {isRefetching && <IsLoadingOverlay />}
              <CSmartTable
                loading={isLoading}
                items={data?.data?.results && data.data.results}
                itemsPerPageSelect
                itemsPerPage={itemsPerPage}
                pagination={{ external: true }}
                paginationProps={{
                  activePage: activePage,
                  pages: data?.data?.pages || 1,
                  ...paginationProps,
                }}
                onActivePageChange={setActivePage}
                onItemsPerPageChange={(value) => {
                  setActivePage(1)
                  setItemsPerPage(value)
                }}
                onSorterChange={(value) => {
                  console.log('sorted', value)
                  if (value.column !== undefined && value.state !== undefined) {
                    console.log('@setSorted', value)
                    setSortBy(value.column)
                    setSortDir(value.state)
                  }
                }}
                {...tableProps(navigate)}
              />
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default Orders
