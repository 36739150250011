import React, { useEffect, useState } from 'react'
import { CHeaderToggler, CSidebar, CSidebarBrand, CSidebarNav } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { AppSidebarNav } from './AppSidebarNav'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { ReactComponent as Logo } from 'src/assets/brand/logo.svg'
import { ReactComponent as LogoLight } from 'src/assets/brand/logoLight.svg'
import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'
// sidebar nav config
import navigation from '../_nav'
import { cilMenu } from '@coreui/icons'

const AppSidebar = () => {
  const sidebarShow = useStoreState((state) => state.app.sidebarShow)
  const toggleSidebarShow = useStoreActions((action) => action.app.toggleSidebarShow)

  const [overlaid, setOverlaid] = useState(false)
  const theme = useStoreState((state) => state.app.theme)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1280) {
        setOverlaid(true)
      } else setOverlaid(false)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <CSidebar
      position="fixed"
      overlaid={overlaid}
      visible={sidebarShow}
      style={
        //   {
        //   borderWidth: '0px 1px 0px 0px',
        //   borderStyle: 'solid',
        //   // borderImage: 'linear-gradient(to bottom, #13192C, #2E3035) 1',
        //   // 'border-right': '1px solid red',
        // }}
        theme === 'dark'
          ? {
              borderWidth: '0px 1px 0px 0px',
              borderStyle: 'solid',
              // borderImage: 'linear-gradient(to bottom, #13192C, #2E3035) 1',
              borderImage: 'linear-gradient(to bottom, #13192C, #2E3035) 1',
            }
          : {
              borderWidth: '0px 1px 0px 0px',
              borderStyle: 'solid',
              // borderImage: 'linear-gradient(to b, #13192C, #2E3035) 1',
              borderImage: 'linear-gradient(to bottom, #C2C9D9, #ffffff) 1',
            }
      }
      className="shadow-none"
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        {theme === 'dark' ? <Logo className="w-75" /> : <LogoLight className="w-75" />}
      </CSidebarBrand>
      <CSidebarNav className="py-2 ">
        <SimpleBar style={{ height: '100%' }}>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CHeaderToggler
        style={{ position: 'absolute', right: '-35px', top: '22px' }}
        className="ps-1"
        onClick={() => toggleSidebarShow()}
      >
        <CIcon icon={cilMenu} size="lg" />
      </CHeaderToggler>
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
