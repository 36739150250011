import React from 'react'
import { useForm } from 'react-hook-form'

import {
  CCard,
  CCardHeader,
  CCol,
  CContainer,
  CListGroup,
  CListGroupItem,
  CRow,
} from '@coreui/react-pro'

import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import LoaderService from 'src/services/LoaderService'
import BasicForm from 'src/components/BasicForm'
import formFields from './ProductForm'
import DataService from 'src/services/DataService'
import { fileToBase64 } from 'src/helpers'
import CancelButton from 'src/components/CancelButton'
import EditButton from 'src/components/EditButton'
import IsLoading from 'src/components/IsLoading'

const ProductEdit = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { data, error, isLoading, refetch } = useQuery(
    ['produkt', id],
    DataService.products.getProduct(id)
  )
  // console.log(data)

  const { register, handleSubmit, setValue, watch } = useForm({
    values: data,
  })

  const { mutate } = useMutation(DataService.products.editProduct(id), {
    invalidateQueries: ['produkt', id],
    onSuccess: () => {
      refetch().then(() => {
        // console.log(data)
        navigate(`/produkt/${id}`)
      })
    },
    onError: (e) => {
      if (e?.message) {
        console.log(e.message)
        // toast.error(e.message)
      }
    },
  })

  if (isLoading) return <IsLoading />
  if (!data) {
    console.log(error)
    return <span>missing data</span>
  }

  // setValue(data)
  const onSubmit = async (data) => {
    console.log('onsubmit', { data })
    LoaderService.show()

    let newData = { ...data }
    if (data.productCategory?.id) newData.productCategory = data.productCategory.id
    // console.log('product data before file handling', data)
    //no attachement
    if (!data.file) delete newData.file
    //no new attachemt - original value
    else if (typeof data.file === 'string') {
      delete newData.file
      //keep initial value
    } else if (data.file[0]) {
      newData.file = {
        base64: await fileToBase64(newData.file[0]),
        type: data.file[0].type,
      }
    }

    if (typeof newData.region === 'object' && newData.region !== null) {
      newData.region = data.region.id
    }

    mutate(newData)
    LoaderService.hide()

    // LoaderService.show()

    // mutate(data)
    // LoaderService.hide()
  }

  return (
    <>
      <CContainer fluid className="h-100">
        <CRow>
          <CCol className="d-flex flex-row justify-content-between pb-4">
            <h2>Edycja produktu</h2>
            <div>
              <EditButton label="Zapisz" />
              <CancelButton label="Anuluj" onClick={() => navigate(`/produkt/${id}`)} />
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>Dane szczegółowe</CCardHeader>
              <CListGroup flush className="p-2">
                <CListGroupItem className="fs-6">
                  <BasicForm
                    formFields={formFields}
                    onSubmit={handleSubmit((data) => onSubmit(data))}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                  ></BasicForm>
                </CListGroupItem>
              </CListGroup>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default ProductEdit
