import { CCol, CContainer, CRow } from '@coreui/react-pro'
import React from 'react'
import DownloadButton from './DownloadButton'
import { formatData } from 'src/helpers'

const AttachmentRecordsList = ({ data }) => {
  return (
    <>
      {data.map((rec, id) => (
        <CContainer
          key={id}
          className="note-container p-2 mb-1"
          style={{ background: 'transparent' }}
        >
          <CRow className="border-bottom px-0 pb-2 mb-1">
            <CCol>
              <div className="">{rec.text}</div>
            </CCol>
            <CCol
              style={{ minWidth: '120px', maxWidth: '130px' }}
              className="d-flex align-items-center justify-content-end"
            >
              {rec.file && <DownloadButton endpoint={rec.file} />}
            </CCol>
            {/* <div className="d-flex flex-column justify-content-between px-0 pb-2 mb-2 border-bottom">
              <div >
              </div>
            </div> */}
          </CRow>
          <CRow>
            <span className="font-small text-end pt-0">
              {formatData(rec.createdAt.date, 'ddmmyyyyhhmm')} przez {rec.user.name}
            </span>
          </CRow>
        </CContainer>
      ))}
    </>
  )
}

export default AttachmentRecordsList
