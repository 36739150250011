import React from 'react'
import { CCard, CCardBody, CCardHeader, CCol, CContainer, CRow } from '@coreui/react-pro'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import LoaderService from 'src/services/LoaderService'
import BasicForm from 'src/components/BasicForm'
import formFields from './DailyOfferForm'
import DataService from 'src/services/DataService'
import EditButton from 'src/components/EditButton'
import CancelButton from 'src/components/CancelButton'
import { toast } from 'react-toastify'

const DailyOffersNew = () => {
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      numberOfCodes: '',
      buyingPrice: '',
      moq: '',
      deliveryTime: '',
      contractor: '',
      product: '',
    },
  })

  const navigate = useNavigate()

  const { mutate: addDailyOfferMutation } = useMutation(DataService.dailyOffers.addDailyOffer, {
    onSuccess: (res) => {
      navigate(`/dzienna/${res.data.id}`)
      toast.success('Oferta dzienna została utworzona')
    },
  })

  const onSubmit = async (data) => {
    LoaderService.show()
    addDailyOfferMutation(data)
    LoaderService.hide()
  }

  return (
    <>
      <CContainer fluid className="h-100">
        <CRow>
          <CCol className="d-flex flex-row justify-content-between pb-4">
            <h2>Tworzenie nowej oferty dziennej</h2>
            <div>
              <EditButton label="Zapisz" />
              <CancelButton label="Anuluj" onClick={() => navigate('/produkty')} />
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>Dane oferty</CCardHeader>
              <CCardBody>
                <BasicForm
                  formFields={formFields}
                  onSubmit={handleSubmit((data) => onSubmit(data))}
                  register={register}
                  setValue={setValue}
                  watch={watch}
                ></BasicForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}
export default DailyOffersNew
