import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
} from '@coreui/react-pro'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import DataService from 'src/services/DataService'
import IsLoading from 'src/components/IsLoading'
import { notifyError, notifySucces } from 'src/services/notify'
import IsLoadingOverlay from 'src/components/IsLoadingOverlay'
import { convertStringToBoolean, formatPriceToDisplayDecimals } from 'src/helpers'
import { useStoreState } from 'easy-peasy'
import BasicForm from 'src/components/BasicForm'
import formFieldsDetailsEdit from './OrderFormDetailsEdit'
import EditOrderProductWrapper from 'src/components/EditOrderProductWrapper'
import EditButton from 'src/components/EditButton'
import CancelButton from 'src/components/CancelButton'

const OrderEditDetails = () => {
  const { id } = useParams()
  const [isLoading] = useState(false)
  const isSuper = useStoreState((store) => store.auth.isSuper)
  const [detailsLoading, setDetailsLoading] = useState(false)

  const navigate = useNavigate()

  const {
    data,
    error,
    isLoading: orderDataIsLoading,
    refetch,
    isRefetching,
  } = useQuery(['order', id], DataService.orders.getOrder(id))

  const { register, unregister, watch, handleSubmit, setValue, reset } = useForm({
    values: { ...data },
  })

  useEffect(() => {
    if (data) {
      //reformat price value on data change
      data.products.forEach((product, index) => {
        setValue(`products[${index}].price`, formatPriceToDisplayDecimals(product.price))
      })
      //reformat price value on data initialization
      setValue(`dateOfOrder`, data.dateOfOrder.split('T')[0])
    }
  }, [data, setValue, isRefetching])

  const { mutate: mutateDetils } = useMutation(DataService.orders.updateDetails(id), {
    onMutate: () => {
      setDetailsLoading(true)
    },
    onSettled: () => {
      setDetailsLoading(false)
      reset()
    },
    onSuccess: () => {
      notifySucces('dane zostały zapisane')
      refetch()
    },
    onError: () => notifyError('błąd'),
  })

  const onHandleSubmit = (formData) => {
    mutateDetils(convertStringToBoolean(formData))
  }

  if (orderDataIsLoading) return <IsLoading />
  if (!data) {
    console.log(error)
    return <span>missing data</span>
  }

  if (isSuper)
    return (
      <>
        <CContainer fluid className="h-100">
          {(isLoading || detailsLoading || isRefetching) && <IsLoadingOverlay />}
          <CRow>
            <CCol className="d-flex flex-row justify-content-between pb-4">
              <h2>Edytuj detale zamówienia JK-ORDER-{String(id).padStart(5, '0')}</h2>
              <div>
                <EditButton label="Zapisz" onClick={handleSubmit((data) => onHandleSubmit(data))} />
                <CancelButton label="Anuluj" onClick={() => navigate(`/zamowienie/${id}`)} />
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol lg={3}>
              <CCard>
                <CCardHeader>Dane Zamówienia</CCardHeader>
                <CCardBody>
                  <CRow className="mb-3">
                    <CFormLabel>Klient</CFormLabel>
                    <div className="fs-5 mb-3 fw-semibold">{data.contractor.name}</div>
                    <BasicForm
                      formFields={formFieldsDetailsEdit}
                      // onSubmit={handleSubmit((data) => onSubmitSave(data))}
                      register={register}
                      setValue={setValue}
                      watch={watch}
                      disabled={false}
                      className="mb-3"
                    />
                  </CRow>
                </CCardBody>
              </CCard>
            </CCol>
            <CCol lg={9}>
              <CCard className="bg-transparent">
                <CCardBody className="px-0 py-2">
                  <EditOrderProductWrapper
                    register={register}
                    unregister={unregister}
                    watch={watch}
                    setValue={setValue}
                    disabled={false}
                    editDetails={true}
                  />
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </>
    )
  else return <></>
}

export default OrderEditDetails
