import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CRow,
} from '@coreui/react-pro'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import DataService from 'src/services/DataService'
import LoaderService from 'src/services/LoaderService'

const AddNews = ({ refetch, initData = null }) => {
  console.log(initData)
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      ...initData,
    },
  })

  useEffect(() => {
    if (initData?.expirationDate?.date) {
      setValue('expirationDate', initData.expirationDate.date.split(' ')[0])
    }
  }, [initData, setValue])

  const { mutate: mutateAddNews } = useMutation(DataService.settings.addNews, {
    onSuccess: () => {
      refetch()
    },
    onError: (e) => {
      if (e?.message) {
        console.log('adding news failed')
        console.log(e.message)
      }
    },
  })

  const { mutate: mutateEditNews } = useMutation(DataService.settings.editNews, {
    onSuccess: () => {
      console.log('wiadomość zmieniona')
      refetch()
    },
    onError: (e) => {
      if (e?.message) {
        console.log(e.message)
      }
    },
  })

  const onSubmit = async (data) => {
    LoaderService.show()
    console.log(data)
    if (data?.expirationDate === '' || data?.expirationDate == null) {
      delete data.expirationDate
    }

    console.log(data)
    if (initData !== null) {
      mutateEditNews(data)
    } else {
      mutateAddNews(data)
    }
    LoaderService.hide()
  }

  return (
    <CRow>
      <CForm onSubmit={handleSubmit((data) => onSubmit(data))} className="d-flex flex-column ">
        <CCol xs={12}>
          <CFormLabel className="mb-0" htmlFor="text">
            Wiadomość
          </CFormLabel>
          <CFormTextarea
            rows={3}
            type="text"
            id="text"
            placeholder={''}
            required
            {...register('text')}
            className="mb-1"
          />
        </CCol>
        <CRow className="mb-3">
          <CCol xs={6}>
            <CFormLabel className="mb-0" htmlFor="text">
              Link
            </CFormLabel>
            <CFormInput
              type="text"
              id="uri"
              placeholder={''}
              {...register('uri')}
              className="mb-1"
            />
          </CCol>
          <CCol xs={6}>
            <CFormLabel className="mb-0" htmlFor="text">
              Data wygaśnięcia
            </CFormLabel>
            <CFormInput
              type="date"
              id="expirationDate"
              placeholder={''}
              {...register('expirationDate')}
              className="mb-1"
            />
          </CCol>
        </CRow>
        <CButton type="submit" className="ms-auto fw-semibold px-5 py-1">
          Zapisz
        </CButton>
      </CForm>
    </CRow>
  )
}

export default AddNews
