import React from 'react'
import { CCard, CCardBody, CCardHeader, CCol, CContainer, CRow } from '@coreui/react-pro'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import LoaderService from 'src/services/LoaderService'
import BasicForm from 'src/components/BasicForm'
import formFields from './DailyOfferForm'
import DataService from 'src/services/DataService'
import EditButton from 'src/components/EditButton'
import CancelButton from 'src/components/CancelButton'
import { toast } from 'react-toastify'
import IsLoading from '../../components/IsLoading'

const DailyOffersNew = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const { data, isLoading, isSuccess } = useQuery(
    ['editDailyOffer', id],
    DataService.dailyOffers.getDailyOffer(id)
  )

  const { mutate: editDailyOfferMutation } = useMutation(
    DataService.dailyOffers.editDailyOffer(id),
    {
      onSuccess: (res) => {
        navigate(`/dzienne`)
        toast.success('Oferta dzienna została zaktualizowana')
      },
    }
  )

  const { register, handleSubmit, setValue, watch } = useForm({
    values: data,
  })

  const onSubmit = async (data) => {
    LoaderService.show()
    editDailyOfferMutation({
      ...data,
      product: data.product.id,
    })
    LoaderService.hide()
  }

  return (
    <>
      {isLoading && <IsLoading />}
      {isSuccess && (
        <CContainer fluid className="h-100">
          <CRow>
            <CCol className="d-flex flex-row justify-content-between pb-4">
              <h2>Edycja nowej oferty dziennej nr. {data.id}</h2>
              <div>
                <EditButton label="Zapisz" />
                <CancelButton label="Anuluj" onClick={() => navigate('/dzienne')} />
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CCard>
                <CCardHeader>Dane oferty</CCardHeader>
                <CCardBody>
                  <BasicForm
                    formFields={formFields}
                    onSubmit={handleSubmit((data) => onSubmit(data))}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                  ></BasicForm>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      )}
    </>
  )
}
export default DailyOffersNew
