const formFields = (watch) => [
  [
    {
      id: 'name',
      category: '',
      label: 'IMIĘ',
      placeholder: 'np. Imię',
      type: 'text',
      required: true,
    },
    {
      id: 'surname',
      category: '',
      label: 'NAZWISKO',
      placeholder: 'np. Nazwisko',
      type: 'text',
      required: true,
    },
    {
      id: 'email',
      category: '',
      label: 'EMAIL',
      placeholder: 'np. email@firma.com',
      type: 'email',
      required: false,
    },
    {
      id: 'role',
      category: '',
      label: 'ROLA',
      placeholder: '',
      type: [
        ['ROLE_USER', 'Użytkownik'],
        ['ROLE_ACCOUNT_MANAGER', 'Manager'],
      ],
      required: false,
    },
    {
      id: 'status',
      category: '',
      label: 'STATUS',
      placeholder: 'np. false',
      type: [
        ['true', 'Aktywny'],
        ['false', 'Nieaktywny'],
      ],
      required: true,
    },
  ],
  [
    {
      id: 'password',
      category: '',
      label: 'NOWE HASŁO',
      placeholder: '',
      type: 'password',
      required: false,
    },
    {
      id: 'repeatedPassword',
      category: '',
      label: 'POWTÓRZ NOWE HASŁO',
      placeholder: '',
      type: 'password',
      required: false,
      validate: (val) => watch('password') === val || 'hasła niezgodne',
    },
  ],
]

export default formFields
