import { action } from 'easy-peasy'

const AppStore = {
  sidebarShow: true,
  sidebarUnfoldable: false,
  asideShow: false,
  theme: 'dark',
  loader: false,
  sessionExpiresIn: 0,

  setSessionExpiresIn: action((state, payload) => {
    state.sessionExpiresIn = payload
  }),
  toggleTheme: action((state) => {
    if (state.theme === 'dark') state.theme = 'light'
    else state.theme = 'dark'
  }),
  toggleAsideShow: action((state, payload) => {
    if (payload) state.asideShow = payload
    else state.asideShow = !state.asideShow
  }),
  // toggleSidebarUnfoldable: action((state) => {
  //   state.sidebarUnfoldable = !state.sidebarUnfoldable
  // }),
  toggleSidebarShow: action((state, payload) => {
    if (payload) state.sidebarShow = payload
    else state.sidebarShow = !state.sidebarShow
  }),
  toggleLoader: action((state, payload) => {
    state.loader = payload
  }),
}

export default AppStore
