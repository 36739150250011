import React from 'react'
import { useStoreActions } from 'easy-peasy'

const Logout = () => {
  const logout = useStoreActions((actions) => actions.auth.clearUser)

  return <div onClick={() => logout()}>logout</div>
}

export default Logout
