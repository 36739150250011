import React from 'react'
import { navigationStylingProps } from 'src/assets/custom/tableSorting'
import FillBar from 'src/components/FillBar'
import { formatPriceToDisplayDecimals } from 'src/helpers'
import ShopAvailabilitySwitcher from '../../components/ShopAvailabilitySwitcher'
import { CButton } from '@coreui/react-pro'

const adminColumns = [
  {
    key: 'name',
    label: 'Produkt',
    _style: { minWidth: '60px' },
  },
  {
    key: 'expectedQuantity',
    label: 'Ilość',
    _style: { minWidth: '110px' },
  },
  {
    key: 'purchasePriceByCurrency',
    label: 'Cena zakupu w walucie',
    _style: { minWidth: '50px' },
  },
  {
    key: 'purchasePrice',
    label: 'Cena zakupu',
    _style: { minWidth: '50px' },
  },
  {
    key: 'format',
    label: 'Format',
    _style: { minWidth: '50px' },
  },
  {
    key: 'progress',
    label: 'Otrzymano',
    _style: { minWidth: '150px' },
  },

  {
    key: 'shopAvailability',
    label: 'Dostępność dostawy',
    _style: { maxWidth: '70px' },
  },
  {
    key: 'goto',
    label: 'Szczegóły',
    _style: { minWidth: '50px' },
  },
]
const columns = [
  {
    key: 'name',
    label: 'Produkt',
    _style: { minWidth: '60px' },
  },
  {
    key: 'expectedQuantity',
    label: 'Ilość',
    _style: { minWidth: '110px' },
  },
  {
    key: 'purchasePrice',
    label: 'Cena zakupu',
    _style: { minWidth: '50px' },
  },
  {
    key: 'value',
    label: 'Wartość',
    _style: { minWidth: '50px' },
  },
  {
    key: 'format',
    label: 'Format',
    _style: { minWidth: '50px' },
  },
  {
    key: 'progress',
    label: 'Otrzymano',
    _style: { minWidth: '150px' },
  },
  {
    key: 'goto',
    label: 'Szczegóły',
    _style: { minWidth: '50px' },
  },
]

export const tableProps = (navigate = null, isAdmin, id, currency) => {
  return {
    columns: isAdmin ? adminColumns : columns,
    scopedColumns: {
      name: (data) => <td>{data['product.name']}</td>,
      progress: (data) => (
        <td className="align-middle">
          <FillBar className="" value={data.amount} sum={data.expectedQuantity} />
          {/* <CProgress height={15} className="my-auto">
            <CProgressBar
              {...(data.amount >= data.expectedQuantity ? { color: 'secondary' } : {})}
              {...(data.amount !== data.expectedQuantity ? { variant: 'striped' } : {})}
              // variant={data.amount !== data.expectedQuantity ? 'striped' : ''}
              animated={data.amount !== data.expectedQuantity ? true : false}
              value={calculateProgress(data, 'amount', 'expectedQuantity')}
            >
              {data.amount + '/' + data.expectedQuantity}
            </CProgressBar>
          </CProgress> */}
        </td>
      ),
      purchasePrice: ({ purchasePrice, currency }) => (
        <td>{formatPriceToDisplayDecimals(purchasePrice) + ' ' + (currency || 'EUR')}</td>
      ),
      shopAvailability: ({ availableForSale, retailPrice, id: deliveryProductId }) => (
        <td>
          <ShopAvailabilitySwitcher
            availableForSale={availableForSale}
            retailPrice={retailPrice}
            deliveryProductId={deliveryProductId}
            deliveryId={id}
          />
        </td>
      ),
      value: ({ price, expectedQuantity, currency }) => {
        return (
          <td>
            {formatPriceToDisplayDecimals(price * expectedQuantity) + ' ' + (currency || 'EUR')}
          </td>
        )
      },
      purchasePriceByCurrency: ({ purchasePriceByCurrency }) => {
        return <td>{formatPriceToDisplayDecimals(purchasePriceByCurrency) + ' ' + currency}</td>
      },
      goto: ({ id }) => (
        <td>
          <CButton
            onClick={() => {
              navigate(`/dostawa/${id}/kody/${id}`)
            }}
          >
            Szczegóły
          </CButton>
        </td>
      ),
    },
    clickableRows: isAdmin,
    columnSorter: true,
    pagination: true,
    itemsPerPageSelect: false,
    itemsPerPage: 100,
    tableProps: {
      striped: true,
      hover: true,
    },
    ...navigationStylingProps,
  }
}
