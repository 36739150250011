import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import DataService from 'src/services/DataService'
import BasicForm from 'src/components/BasicForm'
import formFields from './DeliveryForm'
import { convertPriceToWholeNumber, fileToBase64 } from 'src/helpers'
import { notifyError, notifySucces } from 'src/services/notify'
import { CCard, CCardBody, CCardHeader, CCol, CContainer, CRow } from '@coreui/react-pro'
import IsLoadingOverlay from 'src/components/IsLoadingOverlay'
import ProductCodesSummaryWrapper from 'src/components/ProductCodesSummaryWrapper'
import AddProductCodesWrapper from 'src/components/AddProductCodesWrapper'
import AddCodesSummaryWrapper from 'src/components/AddCodesSummaryWrapper'
import EditButton from 'src/components/EditButton'
import CancelButton from 'src/components/CancelButton'

const DeliveryNew = () => {
  let fileListRef = useRef()
  let deliveryIdRef = useRef()
  let productsIdRef = useRef()
  let deliveryProductsIdRef = useRef()
  const summaryDataRef = useRef()
  const [isLoading, setIsLoading] = useState(false)
  const [summaryData, setSummaryData] = useState()
  const [isDisabled, setIsDisabled] = useState(false)

  const { register, unregister, watch, handleSubmit, setValue } = useForm({
    defaultValues: {
      contractor: '',
      invoiceNumber: '',
      dateOfOrder: '',
      status: 'NOWE', // default value, not changeable during new delivery creation
      isPaid: '',
      products: [], // array of products objects with codes and files and productId)

      note: '', //zbędne
    },
  })

  const navigate = useNavigate()

  const parseFiles = async (data) => {
    fileListRef.current = []
    productsIdRef.current = []

    for (const product of data.products) {
      productsIdRef.current.push(product.id)
      if (product.files) {
        const fileListArray = Array.from(product.files)
        for (const [index, file] of fileListArray.entries()) {
          fileListRef.current.push({
            file: {
              base64: await fileToBase64(file),
              type: file.type,
            },
            date: new Date(),
            productId: product.id,
            codesInFile: product.codesInFile[index],
            purchasePrice: product.purchasePrice,
            currency: product.currency,
            name: file.name,
          })
        }
      }
    }
  }

  const countAddedCodes = (data) => {
    console.log(data)
    let codesSum = 0
    let expectedSum = 0
    data?.forEach((product) => {
      expectedSum += product.expected_quantity
      codesSum += Number(product?.codes?.length)
      product?.files?.forEach((file) => {
        codesSum += parseInt(file.count)
      })
    })
    console.log('countAdded return', { sum: codesSum, expected: expectedSum })
    return { sum: codesSum, expected: expectedSum }
  }

  const handleFileSending = () => {
    const fileToSend = fileListRef.current.shift()
    if (fileToSend) {
      let index = ''
      for (let i = 0; i < productsIdRef.current.length; i++) {
        if (productsIdRef.current[i] === fileToSend.productId) {
          index = i
        }
      }

      addDeliveryFileMutation2({
        data: fileToSend,
        deliveryId: deliveryIdRef.current,
        productId: deliveryProductsIdRef.current[index],
      })
    }
  }

  const { mutate: addDeliveryMutation2 } = useMutation(DataService.deliveries.addDeliveryCodes2(), {
    onSuccess: ({ res, deliveryId, sentData }) => {
      summaryDataRef.current = res.data.products
      sentData.forEach((product, index) => {
        summaryDataRef.current[index].expected_quantity = product.expected_quantity
      })

      deliveryIdRef.current = deliveryId

      if (fileListRef.current?.length > 0) {
        deliveryProductsIdRef.current = []
        res.data.products.forEach((product) => deliveryProductsIdRef.current.push(product.id))
        handleFileSending()
      } else {
        // let { sum, expected } = countAddedCodes(summaryDataRef.current)
        let { sum } = countAddedCodes(summaryDataRef.current)
        //not all codes were added
        notifySucces(`Dodano ${sum || 0} kodów`)
        setIsLoading(false)
        setSummaryData(summaryDataRef.current)
        setIsDisabled(true)
      }
    },
    onError: (res) => {
      notifyError('Dodawanie dostawy zakończyło się niepowodzeniem')
      setIsLoading(false)
    },
  })

  const { mutate: addDeliveryFileMutation2 } = useMutation(
    DataService.deliveries.addDeliveryFiles2(),
    {
      onSuccess: ({ res, productId, name, sentCodesCount }) => {
        //add info about sucesfull save
        const index = summaryDataRef.current.findIndex((product) => product.id === productId)
        if (!summaryDataRef.current[index].files) summaryDataRef.current[index].files = []
        summaryDataRef.current[index].files.push({
          name: name,
          status: 'succes',
          count: sentCodesCount,
        })

        if (fileListRef.current.length > 0) {
          handleFileSending()
        } else {
          let { sum, expected } = countAddedCodes(summaryDataRef.current)
          console.log('@expectd: ', expected, ' codes, got: ', sum)
          //not all codes were added
          notifySucces(`Dodano ${sum} kodów`)
          setIsLoading(false)
          setSummaryData(summaryDataRef.current)
          setIsDisabled(true)
        }
      },
      onError: ({ productId, name }) => {
        const index = summaryDataRef.current.findIndex((product) => (product.id = productId))
        if (!summaryDataRef.current[index].files) summaryDataRef.current[index].files = []
        summaryDataRef.current[index].files.push({ name: name, status: 'failed' })

        handleFileSending()
      },
    }
  )

  const onSubmitSave = async (data) => {
    if (!watch().products[0]) {
      notifyError('Dodaj co najmniej 1 produkt!')
      return
    }
    if (data.products.length > 0) {
      setIsLoading(true)
      const formatedData = data
      let hasFiles = false
      formatedData.products.forEach((product) => {
        if (product.files.length > 0) hasFiles = true
        product.purchasePrice = convertPriceToWholeNumber(product.purchasePrice)
      })
      if (!hasFiles) {
        addDeliveryMutation2({ data: formatedData })
        //check if all codes were added
      } else {
        //handle files
        await parseFiles(formatedData)

        formatedData.products.forEach((product) => delete product?.files)
        addDeliveryMutation2({ data: formatedData })
      }
    } else {
      //no codes are added to delivery
      notifyError('Dodaj produkty do dostawy!')
    }
  }

  return (
    <>
      <CContainer fluid className="h-100">
        {isLoading && <IsLoadingOverlay />}
        <CRow>
          <CCol className="d-flex flex-row justify-content-between pb-4">
            <h2>Tworzenie nowej dostawy</h2>
            {!summaryData && (
              <div>
                <EditButton label="Zapisz" />
                <CancelButton label="Anuluj" onClick={() => navigate('/dostawy')} />
              </div>
            )}
          </CCol>
        </CRow>
        <CRow>
          <CCol lg={3}>
            <CCard>
              <CCardHeader>Dane Dostawy</CCardHeader>
              <CCardBody>
                <BasicForm
                  formFields={formFields}
                  onSubmit={handleSubmit((data) => onSubmitSave(data))}
                  register={register}
                  setValue={setValue}
                  watch={watch}
                  disabled={isDisabled}
                ></BasicForm>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol lg={5}>
            <CCard className="bg-transparent">
              <CCardBody className="px-0 ">
                {!summaryData ? (
                  <AddProductCodesWrapper
                    register={register}
                    unregister={unregister}
                    watch={watch}
                    setValue={setValue}
                    disabled={isDisabled}
                  />
                ) : (
                  <AddCodesSummaryWrapper data={summaryData} disabled={isDisabled} />
                )}
              </CCardBody>
            </CCard>
          </CCol>
          <CCol lg={4}>
            <CCard>
              <CCardHeader>Podsumowanie</CCardHeader>
              <CCardBody>
                {watch().products && <ProductCodesSummaryWrapper data={watch().products} />}
              </CCardBody>
            </CCard>
            <CCard>
              <CCardHeader>Uwagi</CCardHeader>
              <CCardBody>
                <span>#Formatuj</span>
                <br />
                <span>
                  czyści formatowanie kodów usuwając &lsquo;,&lsquo; &lsquo;;&lsquo; oraz
                  wielokrotne symbole nowej lini oraz spacje
                </span>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}
export default DeliveryNew
