const formFields = [
  [
    {
      id: 'name',
      category: '',
      label: 'NAZWA PRODUKTU',
      placeholder: 'np. FIFA',
      type: 'text',
      required: true,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
    {
      id: 'productCategory',
      category: '',
      label: 'KATEGORIA PRODUKTU',
      placeholder: '',
      type: 'dynamicDropdown',
      endpoint: 'productCategory',
      nameField: 'name',
      required: true,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
    {
      id: 'platforms',
      category: '',
      label: 'PLATFORMA',
      placeholder: 'np. PC',
      type: 'dynamicDropdownMulti',
      endpoint: 'platforms',
      nameField: 'name',
      required: true,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
    {
      id: 'region',
      category: '',
      label: 'REGION',
      placeholder: 'np. EU',
      type: 'dynamicDropdown',
      endpoint: 'regions',
      nameField: 'name',
      required: true,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
    {
      id: 'charsInCode',
      category: '',
      label: 'ZNAKÓW W KODZIE',
      placeholder: '',
      type: 'number',
      required: true,
      valueAsNumber: true,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
    // {
    //   id: 'codesInPhoto',
    //   category: '',
    //   label: 'KODÓW NA ZDJĘCIU',
    //   placeholder: '',
    //   type: 'number',
    //   required: true,
    //   valueAsNumber: true,
    // },
    {
      id: 'file',
      category: '',
      label: 'OKŁADKA',
      placeholder: '',
      type: 'file',
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
  ],
]

export default formFields
