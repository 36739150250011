import { cilMinus, cilPlus, cilTrash } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CCol, CFormInput, CListGroup, CListGroupItem, CRow } from '@coreui/react-pro'
import React from 'react'
import SimpleBar from 'simplebar-react'

const AddedFileList = ({ data, setValue, pointer, register, watch }) => {
  if (!data.codesInFile && data?.files?.length) {
    setValue(`${pointer}.codesInFile`, Array(data.files.length).fill(data.initialCodesInFile))
  }

  const handleRemove = (id) => {
    const a = Array.from(data.files)
    a.splice(id, 1)
    const modList = new DataTransfer()
    a.forEach((file) => {
      modList.items.add(file)
    })

    const newCodesInFile = data.codesInFile
    newCodesInFile.splice(id, 1)

    setValue(`${pointer}.files`, modList.files)
    setValue(`${pointer}.codesInFile`, newCodesInFile)
  }

  return (
    <div className="overflow-auto pe-0" style={{ maxHeight: '50vh' }}>
      <SimpleBar style={{ height: '100%' }}>
        <CListGroup>
          {data.files?.length > 0 &&
            Array.from(data.files).map((file, id) => (
              <CListGroupItem key={id}>
                <CRow className="px-2">
                  <CCol xs={8} className="px-0 text-primary">
                    {file.name}
                  </CCol>
                  <CCol xs={4} className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <CIcon
                        color="danger"
                        icon={cilMinus}
                        className="text-primary btn p-0 m-0 shadow-none"
                        onClick={() =>
                          setValue(
                            `${pointer}.codesInFile[${id}]`,
                            Math.max(data.codesInFile[id] - 1, 1)
                          )
                        }
                        style={{ width: '15px', height: '15px' }}
                      />
                      <div
                        className=" m-0 mx-1 h5 text-center arrowsDisabled"
                        style={{ width: '30px' }}
                      >
                        {/* {data.codesInFile[id]} */}
                        <CFormInput
                          type="number"
                          id="codesInFile"
                          placeholder={data.codesInFile[id]}
                          {...register(`${pointer}.codesInFile[${id}]`, {
                            valueAsNumber: true,
                          })}
                          // onChange={(e) => {
                          //   setValue(`${pointer}.codesInFile[${id}]`, e.target.value)
                          // }}
                          className="h5 m-0 text-center"
                          min="1"
                          max="99"
                          step={1}
                        />
                      </div>
                      <CIcon
                        color="danger"
                        icon={cilPlus}
                        className="text-primary btn p-0 m-0 shadow-none"
                        onClick={() =>
                          setValue(
                            `${pointer}.codesInFile[${id}]`,
                            Math.min(data.codesInFile[id] + 1, 99)
                          )
                        }
                        style={{ width: '15px', height: '15px' }}
                      />
                    </div>
                    <CIcon
                      color="danger"
                      icon={cilTrash}
                      className="text-danger btn p-0 m-0 shadow-none"
                      onClick={() => handleRemove(id)}
                      style={{ minWidth: '15px', width: '15px', height: '15px' }}
                    />
                  </CCol>
                </CRow>
              </CListGroupItem>
            ))}
        </CListGroup>
      </SimpleBar>
    </div>
  )
}

export default AddedFileList
