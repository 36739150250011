import React from 'react'
import { useForm } from 'react-hook-form'

import {
  CCard,
  CCardHeader,
  CCol,
  CContainer,
  CListGroup,
  CListGroupItem,
  CRow,
} from '@coreui/react-pro'

import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import BasicForm from 'src/components/BasicForm'
import formFields from './PartnerForm'
import DataService from 'src/services/DataService'
import EditButton from 'src/components/EditButton'
import CancelButton from 'src/components/CancelButton'
import IsLoading from 'src/components/IsLoading'
import IsLoadingOverlay from 'src/components/IsLoadingOverlay'

const PartnerEdit = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { data, error, isLoading } = useQuery(['partner', id], DataService.partners.getPartner(id))

  const { register, handleSubmit, watch, setValue } = useForm({
    values: data,
  })

  const { mutate, isLoading: mutationIsLoading } = useMutation(
    DataService.partners.editPartner(id),
    {
      onSuccess: () => {
        navigate(`/kontrahent/${id}`)
      },
      onError: (e) => {
        if (e?.message) {
          console.log(e.message)
        }
      },
    }
  )

  if (isLoading) return <IsLoading />
  if (!data) {
    console.log(error)
    return <span>missing data</span>
  }

  const onSubmit = async (data) => {
    if (data.supervisor.supervisor) data.newSupervisor = data.supervisor.supervisor
    delete data.supervisor
    mutate(data)
  }

  return (
    <>
      <CContainer fluid className="h-100">
        {mutationIsLoading && <IsLoadingOverlay />}
        <CRow>
          <CCol className="d-flex flex-row justify-content-between pb-4">
            <h2>Edycja kontrahenta</h2>
            <div>
              <EditButton label="Zapisz" />
              <CancelButton label="Anuluj" onClick={() => navigate(`/kontrahent/${id}`)} />
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader className="fw-bolder">Dane szczegółowe</CCardHeader>
              <CListGroup flush className="p-2">
                <CListGroupItem className="fs-6">
                  <BasicForm
                    formFields={formFields}
                    onSubmit={handleSubmit((data) => onSubmit(data))}
                    register={register}
                    watch={watch}
                    setValue={setValue}
                  />
                </CListGroupItem>
              </CListGroup>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default PartnerEdit
