import { convertStringToBoolean, parseObjectToUrlQuery } from 'src/helpers'
import axios from '../../axios'
import { notifyError, notifySucces } from '../notify'

const OrdersService = {
  getOrders: (params) => async () => {
    console.log(
      '@GetOrders, filter by',
      `/api/deliveries${parseObjectToUrlQuery(convertStringToBoolean(params))}`
    )
    return await axios
      .get(`/api/orders${parseObjectToUrlQuery(convertStringToBoolean(params))}`)
      .then((res) => {
        console.log('@GetOrders res.data:', res.data)
        // notifySucces()
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  //same as above, separated for clarity
  getPartnerOrders: (params) => async () => {
    console.log(
      '@GetOrders, filter by',
      `/api/orders${parseObjectToUrlQuery(convertStringToBoolean(params))}`
    )
    return await axios
      .get(`/api/orders${parseObjectToUrlQuery(convertStringToBoolean(params))}`)
      .then((res) => {
        console.log('@GetOrder data:', res.data)
        // notifySucces()
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },

  getOrder: (id) => async () => {
    return await axios
      .get(`/api/orders/${id}`)
      .then((res) => {
        console.log('@GetOrder res.data, ', res.data)
        return res.data.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  addOrder: () => async (data) => {
    console.log('@addingOrder data: ', convertStringToBoolean(data))
    return await axios
      .post('/api/orders', convertStringToBoolean(data))
      .then((res) => {
        console.log('@AddOrder res.data ', res.data)
        notifySucces('Nowe zamówienie zostało dodane')
        return {
          res: res.data,
          orderId: res.data.data.id,
        }
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },

  getProductDeliveries: (id) => async () => {
    return await axios
      .get(`/api/delivery-products-for-order/${id}`)
      .then((res) => {
        console.log('@GetProductDeliveries res.data, ', res.data)
        return res.data.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },

  addCodesToOrderProduct: (orderId) => async (data) => {
    console.log('@AddCodesToOrderProduct ids:', orderId, data.id, 'payload: ', data)
    return await axios.post(`/api/orders/${orderId}/codes/${data.id}`, data).then((res) => {
      console.log('@addCodesToOrderProduct res data ', res.data)
      return { res: res.data.data, orderId: orderId }
    })
  },

  isPaid: (id) => async (data) => {
    // console.log('isPaid', { id }, { data })
    return await axios
      .put(`/api/orders/${id}`, data)
      .then((res) => {
        console.log('@isPaid, ', res.data)
        // notifySucces('status płatności zamówienia został zmieniony')
        // return res.data.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },

  isCanceled: (id) => async (data) => {
    return await axios
      .put(`/api/orders/${id}`, data)
      .then((res) => {
        console.log('@isCanceled, ', res.data)
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },

  updateDetails: (id) => async (data) => {
    console.log(id, data)
    return await axios
      .put(`/api/orders/${id}`, data)
      .then((res) => {
        console.log('@updateDetails, ', res.data)
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },

  getPassword: (id) => async (data) => {
    return await axios
      .post(`/api/orders-generate/${id}`, data)
      .then((res) => {
        console.log('@getPassword(/orders-generate), ', res.data)
        return res.data.data
      })
      .catch((e) => {
        throw e
      })
  },

  sendMailWithCodes: (id) => async (data) => {
    console.log('@sendMailWithCodes ', id)
    return await axios
      .post(`/api/orders/send/${id}`, data)
      .then((res) => {
        console.log('@sendMailWithCodes, ', res.data)
        return res.data.data
      })
      .catch((e) => {
        // notifyError(e.response.data.error)
        throw e
      })
  },

  receiveCodes: () => async (data) => {
    // console.log(data)
    return await axios
      .post(`/api/receive-codes/${data.uid}`, { password: data.password }, { responseType: 'blob' })
      .then((res) => {
        console.log('@reciveCodes, ', res)
        return res
      })
      .catch((e) => {
        if ((e.response.status = 401)) {
          throw new Error('Unauthorized')
        }
        throw new Error('Error downloading File')
      })
  },
}

export default OrdersService
