import { parseObjectToUrlQuery } from 'src/helpers'
import axios from '../../axios'
import { notifyError, notifySucces } from '../notify'

const ComplaintsService = {
  getComplaints: (params) => async () => {
    return await axios
      .get(`/api/refunds${parseObjectToUrlQuery(params)}`)
      .then((res) => {
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  getComplaint: (id) => async () => {
    return await axios
      .get(`/api/refunds/${id}`)
      .then((res) => {
        return res.data.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  addComplaint: async (data) => {
    return await axios
      .post('/api/refunds', data)
      .then((res) => {
        notifySucces('Nowa reklamacja została dodana')
        return res.data
      })
      .catch((e) => {
        if (e?.response?.data?.error === 'BACKEND_ERROR_ORDER_NOT_FOUND') {
          notifyError('Zamówienie o wskazanym ID nie istnieje')
          throw e
        } else {
          notifyError(e)
          throw e
        }
      })
  },

  getComplaintNotes: (id, params) => async () => {
    console.log(params)
    return await axios
      .get(`/api/refunds/comments/${id}${parseObjectToUrlQuery(params)}`)
      .then((res) => {
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  addComplaintNote: (id) => async (data) => {
    return await axios
      .post(`/api/refunds/comments/${id}`, data)
      .then((res) => {
        notifySucces('Notatka została zapisana')
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  editComplaint: (id) => async (data) => {
    return await axios
      .put(`/api/refunds/${id}`, data)
      .then((res) => {
        notifySucces('Reklamacja została zmieniona')
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
}

export default ComplaintsService
