import React from 'react'
import {
  CButton,
  CCard,
  CCardHeader,
  CCol,
  CContainer,
  CListGroup,
  CListGroupItem,
  CRow,
} from '@coreui/react-pro'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import LoaderService from 'src/services/LoaderService'
import BasicForm from 'src/components/BasicForm'
import formFields from './UserForm'
import DataService from 'src/services/DataService'
import { convertStringToBoolean } from 'src/helpers'

const UserNew = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      surname: '',
      email: '',
      role: 'ROLE_USER',
      status: false,
      password: '',
      repeatPass: '',
    },
  })

  const fromFieldsEdited = formFields(watch)

  const navigate = useNavigate()

  const { mutate, error } = useMutation(DataService.addUser, {
    onSettled: () => {
      // console.log('settled')
      // console.log(error)
    },
    onSuccess: () => {
      // toastSucces()
      navigate('/uzytkownicy')
    },
    onError: (e) => {
      console.log(error)
      if (e?.message) {
        // console.log('adding failed')
        // console.log(e.message)
      }
      if (!!e.response.data.detail) {
        // const toastFail = () => toast(e.response.data.detail)
        // toastFail()
      }
    },
  })

  const onSubmit = async (data) => {
    LoaderService.show()
    mutate(convertStringToBoolean(data))
    LoaderService.hide()
  }

  return (
    <>
      <CContainer fluid className="h-100">
        <CRow>
          <CCol className="d-flex flex-row justify-content-between pb-4">
            <h2>Tworzenie nowego użytkownika</h2>
            <div>
              <CButton type="submit" color="primary" className="ms-4 fw-semibold px-5 py-1">
                <label htmlFor="submit-form" tabIndex="0">
                  Zapisz
                </label>
              </CButton>
              <CButton
                color="danger"
                variant="outline"
                className="ms-4 fw-semibold px-5 py-1"
                onClick={() => navigate('/uzytkownicy')}
              >
                Anuluj
              </CButton>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>Dane szczegółowe</CCardHeader>
              <CListGroup flush className="p-2">
                <CListGroupItem className="fs-6">
                  <BasicForm
                    formFields={fromFieldsEdited}
                    onSubmit={handleSubmit((data) => onSubmit(data))}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                    errors={errors}
                  ></BasicForm>
                </CListGroupItem>
              </CListGroup>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}
export default UserNew
