import { cilCopy } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import React from 'react'
import { navigationStylingProps } from 'src/assets/custom/tableSorting'
import { copyToClipboard, formatPriceToDisplayDecimals } from 'src/helpers'
import Badge from '../../components/Badge'

const columns = [
  {
    key: 'sourceEan',
    label: 'ID',
    _style: { width: '50px' },
  },
  {
    key: 'sourceName',
    label: 'Produkt',
    _style: { minWidth: '350px' },
  },
  {
    key: 'sourcePrice',
    label: 'Cena zakupu',
  },
  {
    key: 'sourceStatus',
    label: 'Status',
  },
  {
    key: 'sourceSkuToChoice',
    label: 'Warianty',
  },
]

export const externalTableProps = (navigate = null, isAdmin = false, openProductAssigningModal) => {
  const hasAccesColumns = columns.filter((column) => {
    if (column.adminAccess && !isAdmin) return false
    return true
  })

  return {
    columns: hasAccesColumns,
    scopedColumns: {
      name: ({ name }) => (
        <td>
          {name}{' '}
          <CIcon
            className="text-primary"
            icon={cilCopy}
            height={'14px'}
            onClick={(e) => {
              e.stopPropagation()
              copyToClipboard(name)
            }}
          />
        </td>
      ),
      sourceStatus: (product) => (
        <td>
          <h5>
            <Badge module={'externalProducts'} status={product.sourceStatus} />
          </h5>
        </td>
      ),
      sourcePrice: ({ sourcePrice, sourceCurrencyCode }) => (
        <td>{formatPriceToDisplayDecimals(sourcePrice) + ' ' + sourceCurrencyCode}</td>
      ),
      sourceSkuToChoice: ({ sourceSkuToChoice }) => <td>{sourceSkuToChoice.length}</td>,
    },
    onRowClick: (productData) => {
      openProductAssigningModal(productData)
    },
    clickableRows: true,
    columnSorter: false,

    tableProps: {
      striped: true,
      hover: true,
    },
    ...navigationStylingProps,
  }
}
