import React from 'react'
import ProductCodesSummary from './ProductCodesSummary'

const ProductCodesSummaryWrapper = ({ data }) => {
  // console.log(data)
  return (
    <>
      {data.map((product, id) => (
        <ProductCodesSummary key={id} data={product} />
      ))}
    </>
  )
}

export default ProductCodesSummaryWrapper
