const listFields = [
  {
    id: 'name',
    category: '',
    label: 'NAZWA OFERTY',
    placeholder: '-',
  },
  {
    id: 'name',
    category: 'product',
    label: 'PRODUKT',
    placeholder: '-',
  },
  {
    id: 'numberOfCodes',
    category: '',
    label: 'ILOŚĆ KODÓW',
    placeholder: '-',
  },
  {
    id: 'remainingCodes',
    category: '',
    label: 'POZOSTAŁE DOSTĘPNE KODY',
    placeholder: '-',
  },
  {
    id: 'buyingPrice',
    category: '',
    label: 'CENA ZAKUPU',
    placeholder: '-',
    format: 'zzgg',
    suffix: 'EUR',
  },
  {
    id: 'srp',
    category: '',
    label: 'SRP',
    placeholder: '-',
    format: 'zzgg',
    suffix: 'EUR',
  },
  { id: '' },
  {
    id: 'endDate',
    category: '',
    label: 'DATA ZAKOŃCZENIA',
    placeholder: '-',
    format: 'ddmmyyyyhhmm',
  },
  {
    id: 'remainingTime',
    category: '',
    label: 'POZOSTAŁY CZAS',
    placeholder: '-',
    format: 'elapsedTime',
  },
  {
    id: 'deliveryTime',
    category: '',
    label: 'CZAS DOSTAWY',
    placeholder: '-',
  },
  {
    id: 'inStock',
    category: '',
    label: 'W MAGAZYNIE',
    placeholder: '-',
  },
  {
    id: 'codeType',
    category: '',
    label: 'RODZAJ KODU',
    placeholder: '-',
  },
  {
    id: 'note',
    category: '',
    label: 'UWAGA',
    placeholder: '-',
  },
  { id: '' },
  {
    id: 'name',
    category: 'supervisor',
    label: 'OPIEKUN',
    placeholder: '-',
  },
]

export default listFields
