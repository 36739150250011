const listFields = [
  // {
  //   id: 'file',
  //   category: '',
  //   label: '',
  //   placeholder: '',
  // },
  {
    id: 'name',
    category: '',
    label: 'NAZWA PRODUKTU',
    placeholder: '-',
  },
  // {
  //   id: 'name',
  //   category: 'productCategory',
  //   label: 'KATEGORIA PRODUKTU',
  //   placeholder: '-',
  // },
  {
    id: 'platforms',
    category: '',
    label: 'PLATFORMA',
    placeholder: '-',
  },
  {
    id: 'name',
    category: 'region',
    label: 'REGION',
    placeholder: '-',
  },
  {
    id: 'charsInCode',
    category: '',
    label: 'ZNAKÓW W KODZIE',
    placeholder: '-',
  },
  {
    id: 'codesInPhoto',
    category: '',
    subcategory: 'KODÓW NA ZDJĘCIU',
    label: 'KODÓW NA ZDJĘCIU',
    placeholder: '-',
  },
  {
    id: 'availableForSale',
    category: '',
    label: 'DOSTĘPNE PRODUKTU W SKLEPIE',
    component: { type: 'component', name: 'AvailableForSaleSwitch' },
    placeholder: '-',
  },
]

export default listFields
