import { cilMoney } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CWidgetStatsF } from '@coreui/react-pro'
import React from 'react'
import { useQuery } from 'react-query'
import { formatPriceToDisplayDecimals } from 'src/helpers'
import DataService from 'src/services/DataService'

const StoreValue = ({ type }) => {
  const { data, isLoading, isError } = useQuery(
    ['product stats', type],
    DataService.products.getProductsStats({ type: type }),
    { keepPreviousData: true }
  )
  isError && <></>
  isLoading && <></>

  return (
    <div className="flex">
      {data && (
        <CWidgetStatsF
          className="store-value"
          color="none"
          padding={false}
          icon={<CIcon icon={cilMoney} height={24} />}
          title="Wartość produktów kategorii"
          value={
            formatPriceToDisplayDecimals(data.data.total) +
            ' ' +
            (data.data?.currency ? data.data.currency : 'EUR')
          }
        />
      )}
    </div>
  )
}

export default StoreValue
