import React from 'react'
import { navigationStylingProps } from 'src/assets/custom/tableSorting'
import { generateGameStormID } from '../../helpers'
import Badge from '../../components/Badge'

const columns = [
  {
    key: 'code',
    label: 'KOD',
    _style: { minWidth: '60px' },
  },
  {
    key: 'product',
    label: 'PRODUKT',
    _style: { minWidth: '110px' },
  },
  {
    key: 'reason',
    label: 'PRZYCZYNA',
    _style: { minWidth: '150px' },
  },
  {
    key: 'contractor',
    label: 'KLIENT',
    _style: { minWidth: '100px' },
  },
  {
    key: 'status',
    label: 'STATUS',
    _style: { minWidth: '100px' },
  },
  {
    key: 'order',
    label: 'ID ZAMÓWIENIA',
    _style: { minWidth: '100px' },
  },
]

export const tableProps = (navigate = null) => {
  return {
    columns: columns,
    scopedColumns: {
      code: ({ code }) => (
        <td style={{ overflowX: 'hidden', maxWidth: '250px', wordWrap: 'break-word' }}>{code}</td>
      ),
      product: ({ product }) => <td>{product.name}</td>,
      contractor: ({ contractor }) => <td>{contractor.name}</td>,
      status: ({ status }) => (
        <td>
          <Badge status={status} module={'complaint'}>
            {status}
          </Badge>
        </td>
      ),
      order: ({ order }) => (
        <td>
          {/* {console.log(data)} */}
          {generateGameStormID('order', order?.id)}
        </td>
      ),
    },
    onRowClick: (e) => {
      navigate(`/reklamacja/${e.id}`)
    },
    clickableRows: true,
    columnSorter: true,
    tableProps: {
      striped: true,
      hover: true,
      responsive: false,
    },
    ...navigationStylingProps,
  }
}
