import React, { useEffect, useState } from 'react'
import { CSmartTable } from '@coreui/react-pro'
import { useQuery } from 'react-query'
import DataService from '../../services/DataService'
import { navigationStylingProps, paginationProps } from '../../assets/custom/tableSorting'
import Badge from '../../components/Badge'
import { formatData, formatPriceToDisplayDecimals } from 'src/helpers'
import IsLoading from '../../components/IsLoading'
import { generateGameStormID } from 'src/helpers'
import { useNavigate } from 'react-router-dom'
import { toUpper } from 'lodash'

const tableProps = (navigate = null) => {
  return {
    scopedColumns: {
      id: ({ id }) => <td>{generateGameStormID('delivery', id)}</td>,
      date: (data) => <td>{formatData(data.dateOfOrder.date, 'ddmmyyyy')}</td>,
      products: (data) => (
        <td>
          {data?.products[0]?.name ? data.products[0]?.name : '...'}
          {data?.products?.[1]?.name && ', {...}'}
        </td>
      ),
      value: ({ value, currency }) => (
        <td>
          {formatPriceToDisplayDecimals(value) + ' ' + (currency ? toUpper(currency) : 'EUR')}
        </td>
      ),
      status: ({ status }) => (
        <td>
          <Badge status={status} module={'delivery'} />
        </td>
      ),
    },
    onRowClick: (e) => {
      navigate('/dostawa/' + e.id)
    },
    clickableRows: true,
    tableProps: {
      striped: true,
      hover: true,
    },
    ...navigationStylingProps,
  }
}

const PartnerDeliveriesTable = ({ id }) => {
  const [activePage, setActivePage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  const { data, isLoading, isSuccess, refetch } = useQuery(
    ['deliveries', id, activePage, itemsPerPage],
    DataService.deliveries.getPartnerDeliveries({
      contractor: id,
      page: activePage,
      perPage: itemsPerPage,
    }),
    { keepPreviousData: true }
  )
  const navigate = useNavigate()

  useEffect(() => {
    refetch()
  }, [activePage, itemsPerPage, refetch])

  const columns = [
    {
      key: 'id',
      label: 'Numer',
      _style: { minWidth: '60px' },
      sorter: false,
    },
    {
      key: 'invoiceNumber',
      label: 'Numer Faktury',
      _style: { minWidth: '60px' },
      sorter: false,
    },
    {
      key: 'date',
      label: 'Data',
      _style: { minWidth: '150px' },
      sorter: false,
    },
    {
      key: 'products',
      label: 'Produkty',
      _style: { minWidth: '100px' },
      sorter: false,
    },
    {
      key: 'status',
      label: 'Status',
      _style: { minWidth: '100px' },
      sorter: false,
    },
    {
      key: 'value',
      label: 'Wartość',
      _style: { minWidth: '100px' },
      sorter: false,
    },
  ]

  return (
    <div>
      {isLoading && <IsLoading />}
      {isSuccess && data && (
        <CSmartTable
          columns={columns}
          loading={isLoading}
          items={data.data.results}
          itemsPerPage={itemsPerPage}
          itemsPerPageSelect
          pagination={{ external: true }}
          paginationProps={{
            activePage: activePage,
            pages: data.data.pages || 1,
            ...paginationProps,
          }}
          onActivePageChange={(selectedPage) => {
            setActivePage(selectedPage)
          }}
          onItemsPerPageChange={(itemsPerPage) => {
            setActivePage(1)
            setItemsPerPage(itemsPerPage)
          }}
          {...tableProps(navigate)}
        />
      )}
    </div>
  )
}

export default PartnerDeliveriesTable
