import React, { useState } from 'react'
import { useMutation } from 'react-query'
import LoaderService from 'src/services/LoaderService'
import { QRCodeSVG } from 'qrcode.react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCardHeader,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react-pro'
import { ReactComponent as Logo } from 'src/assets/brand/logo.svg'
import Thunder from 'src/assets/brand/th1.svg'
import { useStoreActions } from 'easy-peasy'
import { useForm } from 'react-hook-form'
import { cilLockLocked, cilUser } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import TfaInput from 'src/components/TfaInput'
import IsLoading from 'src/components/IsLoading'

const Login = () => {
  const { register, handleSubmit, getValues, setFocus, reset } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  })
  // const clearUser = useStoreActions((actions) => actions.auth.clearUser)
  const loginUser = useStoreActions((actions) => actions.auth.loginUser)
  const validateTfa = useStoreActions((actions) => actions.auth.validateTfa)
  const [step, setStep] = useState(0) //0 - login, password -> token; 1 - 2fa token -> logedin
  const [tfaRegToken, setTfaRegToken] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const login = useMutation(loginUser, {
    onSuccess: (data) => {
      if (data === false) {
      } else {
        setTfaRegToken(data)
      }
      setStep(1)
      if (data === 'failed') setStep(0)
    },
    onMutate: () => {
      setIsLoading(true)
    },
    onSettled: () => {
      setIsLoading(false)
    },
    onError: (e) => {},
  })
  const loginMutate = login.mutate

  const onInitialSubmit = async ({ username, password }) => {
    LoaderService.show()
    loginMutate({ username, password })

    LoaderService.hide()
  }

  const tfa = useMutation(validateTfa, {
    onSuccess: () => {},
    onError: (e) => {
      reset()
      setStep(0)
    },
    onSettled: () => {
      reset()
      setStep(0)
    },
  })

  const tfaMutate = tfa.mutate

  const onSubmit = async () => {
    LoaderService.show()
    const concatenatedToken = getValues('tfaTokenDigit').join('')
    tfaMutate({ authCode: concatenatedToken })
    LoaderService.hide()
  }
  if (isLoading) {
    return <IsLoading />
  }

  return (
    <>
      <div className="bg-gradient min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="d-flex justify-content-center align-items-center">
            <Logo className="align-self-center fill mb-8" style={{ height: '40px' }} />
          </CRow>
          <CRow className="justify-content-center">
            <CCol lg={6} md={8}>
              <CCardGroup>
                <CCard className="border shadow-none bg-dark mx-auto" style={{ maxWidth: '450px' }}>
                  {step === 0 && (
                    <>
                      <CCardHeader
                        className="h2 py-5 text-center bg-secondary text-white"
                        style={{
                          backgroundImage: `url('${Thunder}')`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center right 20%',
                          backgroundSize: 'fill',
                        }}
                      >
                        Zaloguj się
                      </CCardHeader>
                      <CCardBody className="py-5 px-5">
                        <CForm
                          onSubmit={handleSubmit((data) => {
                            // console.log(data)
                            onInitialSubmit(data)
                          })}
                        >
                          <CInputGroup className="mb-3">
                            <CInputGroupText className="px-3">
                              <CIcon size="lg" icon={cilUser} />
                            </CInputGroupText>
                            <CFormInput
                              // className="fs-5"
                              name="username"
                              type="text"
                              size="lg"
                              placeholder="Username"
                              {...register('username')}
                            />
                          </CInputGroup>
                          <CInputGroup className="pb-4 mb-4 border-bottom">
                            <CInputGroupText className="px-3">
                              <CIcon size="lg" icon={cilLockLocked} />
                            </CInputGroupText>
                            <CFormInput
                              size="lg"
                              name="password"
                              type="password"
                              placeholder="Password"
                              {...register('password')}
                            />
                          </CInputGroup>
                          <CRow>
                            <CCol className="d-flex justify-content-center">
                              <CButton
                                type="submit"
                                // variant="outline"
                                color="primary"
                                size="lg"
                                className="px-6 py-2 flex-grow font-lg fw-bold "
                              >
                                Zaloguj
                              </CButton>
                            </CCol>
                          </CRow>
                        </CForm>
                      </CCardBody>
                    </>
                  )}
                  {step === 1 && (
                    <>
                      <CCardHeader
                        className="h2 py-5 text-center bg-secondary text-white"
                        style={{
                          backgroundImage: `url('${Thunder}')`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center right 20%',
                          backgroundSize: 'fill',
                        }}
                      >
                        Weryfikacja
                      </CCardHeader>
                      <CCardBody className="d-flex flex-column align-items-center py-5 px-5">
                        {tfaRegToken ? (
                          <>
                            <p className=" text-center">
                              Zeskanuj klucz konfiguracyjny w autentykatorze, następnie wprowadź
                              wygenerowany kod poniżej
                            </p>
                            <QRCodeSVG
                              bgColor={'#13192c'}
                              fgColor={'#fcd002'}
                              value={`otpauth://totp/Gamestorm?secret=${tfaRegToken}`}
                              className="mb-4"
                            />
                          </>
                        ) : (
                          ''
                        )}
                        <CForm
                          className="w-100"
                          onSubmit={handleSubmit((data) => {
                            onSubmit(data)
                          })}
                        >
                          <p className="">Podaj drugi składnik autentykacji:</p>
                          <CRow>
                            <CCol className="d-flex flex-row justify-content-center pb-4 mb-4 border-bottom ">
                              <div>
                                <TfaInput
                                  register={register}
                                  setFocus={setFocus}
                                  className="mx-auto"
                                />
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol className="d-flex justify-content-center">
                              <CButton
                                type="submit"
                                color="primary"
                                size="lg"
                                className="px-6 fw-bold"
                              >
                                Zaloguj
                              </CButton>
                            </CCol>
                            {/* <CCol xs={6} className="text-right">
                        <CButton color="link" className="px-0">
                          Forgot password?
                        </CButton>
                      </CCol> */}
                          </CRow>
                        </CForm>
                      </CCardBody>
                    </>
                  )}
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </>
  )
}

export default Login
