export const platformsOld = [
  { id: 1, name: 'Steam' },
  { id: 2, name: 'Xbox Live' },
  { id: 3, name: 'EA App (Origin)' },
  { id: 4, name: 'Ubisoft Connect' },
  { id: 5, name: 'Battle.Net' },
  { id: 6, name: 'PlayStation Network' },
  { id: 7, name: 'Rockstar' },
  { id: 8, name: 'Apple - iTunes' },
  { id: 9, name: 'GOG' },
  { id: 10, name: 'Official Website Microsoft' },
  { id: 11, name: 'Amazon' },
  { id: 12, name: 'Nintendo' },
  { id: 13, name: 'Google Play' },
  { id: 14, name: 'Other' },
  { id: 15, name: 'Gift Cards' },
  { id: 16, name: 'Epic Games' },
  { id: 17, name: 'Software' },
  { id: 18, name: 'PlayStation' },
  { id: 19, name: 'Window Store' },
  { id: 20, name: 'Xbox One' },
  { id: 21, name: 'Xbos Series X|S' },
  { id: 22, name: 'Xbox One/Series X|S' },
  { id: 23, name: 'Xbox One/Series/Windows' },
  { id: 24, name: 'League Of Legends' },
  { id: 25, name: 'Netflix' },
  { id: 26, name: 'Fortnite' },
]
export const platforms = [
  { id: 'STEAM', name: 'Steam' },
  { id: 'XBOX_LIVE', name: 'Xbox Live' },
  { id: 'EA_APP_ORIGIN', name: 'EA App (Origin)' },
  { id: 'UBISOFT_CONNECT', name: 'Ubisoft Connect' },
  { id: 'BATTLE_NET', name: 'Battle.Net' },
  { id: 'PLAYSTATION_NETWORK', name: 'PlayStation Network (PSN)' },
  { id: 'ROCKSTAR', name: 'Rockstar' },
  { id: 'APPLE_ITUNES', name: 'Apple - iTunes' },
  { id: 'GOG', name: 'GOG' },
  { id: 'OFFICIAL_WEBSITE_MICROSOFT', name: 'Official Website Microsoft' },
  { id: 'AMAZON', name: 'Amazon' },
  { id: 'NINTENDO', name: 'Nintendo' },
  { id: 'GOOGLE_PLAY', name: 'Google Play' },
  { id: 'OTHER', name: 'Other' },
  { id: 'GIFT_CARDS', name: 'Gift Cards' },
  { id: 'EPIC_GAMES', name: 'Epic Games' },
  { id: 'SOFTWARE', name: 'Software' },
  { id: 'PLAYSTATION', name: 'PlayStation' },
  { id: 'WINDOWS_STORE', name: 'Window Store' },
  { id: 'XBOX_ONE', name: 'Xbox One' },
  { id: 'XBOX_SERIES_XS', name: 'Xbos Series X|S' },
  { id: 'XBOX_ONE_SERIES_XS', name: 'Xbox One/Series X|S' },
  { id: 'XBOX_ONE_SERIES_WINDOWS', name: 'Xbox One/Series/Windows' },
  { id: 'LEAGUE_OF_LEGENDS', name: 'League Of Legends (LOL)' },
  { id: 'NETFLIX', name: 'Netflix' },
  { id: 'FORTNITE', name: 'Fortnite' },
]
