const formFieldsDetailsEdit = [
  [
    // {
    //   id: 'contractor',
    //   category: '',
    //   label: 'Klient',
    //   placeholder: 'np. Firma',
    //   type: 'dynamicDropdown',
    //   endpoint: 'contractors',
    //   nameField: 'name',
    //   required: true,
    //   disabled: true,
    //   enabled: false,
    // },
    {
      id: 'invoiceNumber',
      category: '',
      label: 'Numer FV',
      placeholder: '',
      type: 'text',
      required: false,
    },
    {
      id: 'dateOfOrder',
      category: '',
      label: 'Data zamówienia',
      placeholder: 'np. FIFA',
      type: 'date',
      required: true,
    },
    {
      id: 'isPaid',
      category: '',
      label: 'Status opłaty',
      placeholder: '---',
      type: [
        [false, 'NIEOPŁACONE'],
        [true, 'OPŁACONE'],
      ],
      required: true,
    },
    {
      id: 'note',
      category: '',
      label: 'Uwagi',
      placeholder: '',
      type: 'textarea',
      required: false,
      rows: 4,
    },
  ],
]

export default formFieldsDetailsEdit
