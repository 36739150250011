import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react-pro'
import React from 'react'
import OrderRecordHandling from './OrderRecordHandling'
import { formatData, formatPriceToDisplayDecimals } from 'src/helpers'

const OrderRecords = ({ register, watch, data, index, setValue, defaultCodesInFile }) => {
  // console.log('ORDER RECORDS data', data)

  const hasCodes = () => {
    let codesSum = 0
    data.forEach((delivery) => {
      codesSum += delivery.amountInTXT
      codesSum += delivery.amountInFiles.length
    })
    if (codesSum === 0) return false
    return true
  }

  return (
    <>
      {hasCodes() ? (
        <CTable>
          <CTableHead>
            <CTableRow key="header">
              <CTableHeaderCell scope="col">Dostępna ilość</CTableHeaderCell>
              <CTableHeaderCell scope="col">Cena zakupu w walucie</CTableHeaderCell>
              <CTableHeaderCell scope="col">Cena zakupu</CTableHeaderCell>
              <CTableHeaderCell scope="col">Format</CTableHeaderCell>
              <CTableHeaderCell scope="col">Data dostawy</CTableHeaderCell>
              <CTableHeaderCell scope="col">Dostawa</CTableHeaderCell>
              <CTableHeaderCell scope="col">Dostawca</CTableHeaderCell>
              <CTableHeaderCell scope="col"></CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {data.map((delivery, id) => (
              <React.Fragment key={id}>
                {delivery.amountInTXT > 0 && (
                  <CTableRow key={id + 'txt'}>
                    <CTableDataCell>{delivery.amountInTXT}</CTableDataCell>
                    <CTableDataCell>
                      {formatPriceToDisplayDecimals(delivery.priceByCurrency) +
                        ' ' +
                        delivery.currency}
                    </CTableDataCell>
                    <CTableDataCell scope="row">
                      {formatPriceToDisplayDecimals(delivery.price)} EUR
                    </CTableDataCell>
                    <CTableDataCell>TXT</CTableDataCell>
                    <CTableDataCell>{formatData(delivery.date)}</CTableDataCell>
                    <CTableDataCell>Dostawa ID: {delivery.deliveryId}</CTableDataCell>
                    <CTableDataCell>{delivery.contractor.name}</CTableDataCell>
                    <CTableDataCell>
                      <OrderRecordHandling
                        index={index}
                        data={delivery}
                        register={register}
                        watch={watch}
                        setValue={setValue}
                      />
                    </CTableDataCell>
                  </CTableRow>
                )}

                {delivery.amountInFiles.length > 0 && delivery.amountInFiles[0]?.amount > 0 && (
                  <CTableRow key={`${id} + FILE`}>
                    <CTableDataCell>
                      {delivery.amountInFiles.reduce((acc, el) => {
                        return acc + Number(el.amount)
                      }, 0)}
                    </CTableDataCell>
                    <CTableDataCell>
                      {formatPriceToDisplayDecimals(delivery.priceByCurrency) +
                        ' ' +
                        delivery.currency}
                    </CTableDataCell>
                    <CTableHeaderCell scope="row">
                      {formatPriceToDisplayDecimals(delivery.price)} EUR
                    </CTableHeaderCell>
                    <CTableDataCell>
                      File
                      {/* ({defaultCodesInFile}) */}
                    </CTableDataCell>
                    <CTableDataCell>{formatData(delivery.date)}</CTableDataCell>
                    <CTableDataCell>Dostawa ID: {delivery.deliveryId}</CTableDataCell>
                    <CTableDataCell>{delivery.contractor.name}</CTableDataCell>
                    <CTableDataCell>
                      <OrderRecordHandling
                        index={index}
                        data={delivery}
                        register={register}
                        deliveryIndex={id}
                        watch={watch}
                        file={true}
                        setValue={setValue}
                      />
                    </CTableDataCell>
                  </CTableRow>
                )}
              </React.Fragment>
            ))}
          </CTableBody>
        </CTable>
      ) : (
        <div className="ps-3 mt-3 text-white">brak dostępnych kodów</div>
      )}
    </>
  )
}

export default OrderRecords
