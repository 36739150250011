import React, { useEffect, useState } from 'react'
import { CImage } from '@coreui/react-pro'
import { useQuery } from 'react-query'
import DataService from 'src/services/DataService'
import Placeholder from 'src/assets/brand/placeholderLogo.svg'
import IsLoadingOverlay from './IsLoadingOverlay'

const GetImage = ({ id, path }) => {
  //   const urlRef = useRef()
  const [urlState, setUrlState] = useState()
  const { data, isError, isLoading } = useQuery(id, () => DataService.getFile(path), {
    enabled: !!path,
    onError: (err) => {
      console.log(err)
    },
    onSuccess: () => {},
  })

  useEffect(() => {
    if (data && !isLoading) {
      data.then((res) => {
        // urlRef.current = res
        setUrlState(res)
      })
    }
    if (!data && !isLoading) {
      setUrlState(`${Placeholder}`)
    }
  }, [data, isLoading])

  // if (isLoading) return <IsLoading />
  if (!urlState) return <IsLoadingOverlay />
  if (isError) return <span>Missing data</span>
  return (
    <CImage fluid src={urlState} style={{ maxHeight: '100%', width: 'auto' }} className="h-100" />
  )
}

export default GetImage
