import React from 'react'
import {
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownItemPlain,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react-pro'
import { useStoreActions, useStoreState } from 'easy-peasy'
import SessionTimer from '../SessionTimer'

// import avatar8 from './../../assets/images/avatars/8.jpg'

const AppHeaderDropdown = () => {
  const user = useStoreState((state) => state.auth.user)
  // const roles = useStoreState((state) => state.auth.roles)
  const logout = useStoreActions((actions) => actions.auth.logoutUser)
  const extendSession = useStoreActions((actions) => actions.auth.refreshToken)

  return (
    <CDropdown variant="nav-item" alignment="end">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        Użytkownik{/* <CAvatar src={avatar8} size="md" /> */}
      </CDropdownToggle>
      <CDropdownMenu className="pt-2">
        {/* <CDropdownHeader className="bg-light dark:bg-white dark:bg-opacity-10 fw-semibold py-2">
          Account
        </CDropdownHeader>
        <CDropdownItem href="#">
          <CIcon icon={cilBell} className="me-2" />
          Updates
          <CBadge color="info-gradient" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilEnvelopeOpen} className="me-2" />
          Messages
          <CBadge color="success-gradient" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilTask} className="me-2" />
          Tasks
          <CBadge color="danger-gradient" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilCommentSquare} className="me-2" />
          Comments
          <CBadge color="warning-gradient" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownHeader className="bg-light dark:bg-white dark:bg-opacity-10 fw-semibold py-2">
          Settings
        </CDropdownHeader>
        <CDropdownItem href="#">
          <CIcon icon={cilUser} className="me-2" />
          Profile
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilSettings} className="me-2" />
          Settings
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilCreditCard} className="me-2" />
          Payments
          <CBadge color="secondary-gradient" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilFile} className="me-2" />
          Projects
          <CBadge color="primary-gradient" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem href="#">
          <CIcon icon={cilLockLocked} className="me-2" />
          Lock Account
        </CDropdownItem> */}
        <CDropdownHeader className="pb-0">Użytkownik:</CDropdownHeader>
        <CDropdownItemPlain className="pt-0">{`${user.name} ${user.surname}`}</CDropdownItemPlain>
        <CDropdownHeader className="pb-0">Rola:</CDropdownHeader>
        <CDropdownItemPlain className="pt-0">{user?.role}</CDropdownItemPlain>
        <CDropdownHeader className="pb-0">Sesja:</CDropdownHeader>
        <CDropdownItemPlain>
          Czas:
          <CBadge color="primary-gradient" className="ms-2">
            <SessionTimer />
          </CBadge>
        </CDropdownItemPlain>
        <CDropdownDivider />
        <CDropdownItem
          href="#"
          onClick={() => {
            console.log('extend')
            extendSession()
          }}
        >
          Przedłuż sesję
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem href="#" onClick={() => logout()}>
          Wyloguj
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
