import React from 'react'
import { navigationStylingProps } from 'src/assets/custom/tableSorting'
import Badge from 'src/components/Badge'
import { formatData, formatPriceToDisplayDecimals } from 'src/helpers'
import { toUpper } from 'lodash'

const columns = [
  {
    key: 'id',
    label: 'Numer',
    _style: { minWidth: '60px' },
  },
  {
    key: 'contractor',
    label: 'Dostawca',
    _style: { minWidth: '110px' },
  },
  {
    key: 'dateOfOrder',
    label: 'Data',
    _style: { minWidth: '150px' },
  },
  {
    key: 'products',
    label: 'Produkt',
    _style: { minWidth: '100px' },
    sorter: false,
  },
  /*  {
    key: '',
    label: 'Kody/Otrzymane',
    _style: { minWidth: '100px' },
  },*/
  {
    key: 'status',
    label: 'Status',
    _style: { minWidth: '100px' },
  },
  {
    key: 'invoiceNumber',
    label: 'Numer FV',
    _style: { minWidth: '100px' },
  },
  {
    key: 'value',
    label: 'Wartość w EUR',
    _style: { minWidth: '100px' },
  },
  {
    key: 'valueByCurrency',
    label: 'Wartość w walucie',
    sorter: false,
  },
]

export const tableProps = (navigate = null) => {
  return {
    columns: columns,
    scopedColumns: {
      contractor: ({ contractor }) => <td>{contractor?.name}</td>,
      dateOfOrder: ({ dateOfOrder }) => <td>{formatData(dateOfOrder.date, 'ddmmyyyy')}</td>,
      products: ({ products }) => (
        <td>
          {products[0]?.name ? products[0].name : '...'}
          {products[1]?.name && ', {...}'}
        </td>
      ),
      status: (delivery) => (
        <td>
          <h5>
            <Badge module={'delivery'} status={delivery.status} />
          </h5>
        </td>
      ),
      value: ({ value }) => <td>{formatPriceToDisplayDecimals(value) + ` EUR`}</td>,
      valueByCurrency: ({ valueByCurrency, currency }) => (
        <td>{formatPriceToDisplayDecimals(valueByCurrency) + ' ' + toUpper(currency)}</td>
      ),
    },
    onRowClick: (e) => {
      navigate(`/dostawa/${e.id}`)
    },
    clickableRows: true,
    columnSorter: true,
    tableProps: {
      striped: true,
      hover: true,
    },
    ...navigationStylingProps,
  }
}
