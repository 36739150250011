import React from 'react'
import { CCard, CCardBody, CCardHeader, CCol, CContainer, CRow } from '@coreui/react-pro'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import LoaderService from 'src/services/LoaderService'
import BasicForm from 'src/components/BasicForm'
import formFields from './PartnerForm'
import DataService from 'src/services/DataService'
import EditButton from '../../components/EditButton'
import CancelButton from '../../components/CancelButton'

const PartnerNew = () => {
  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      contractorDetails: {
        companyName: '',
        properName: '',
        vatNumber: '',
        notes: '',
      },
      contractorAddress: {
        street: '',
        city: '',
        zipCode: '',
        country: '',
      },
      contractorContact: {
        skypeID: '',
        webpage: '',
        orderMail: '',
        invoiceMail: '',
        contactMail: '',
      },
    },
  })

  const navigate = useNavigate()

  const { mutate } = useMutation(DataService.partners.saveParter, {
    onSuccess: () => {
      navigate('/kontrahenci')
    },
    onError: (e) => {
      if (e?.message) {
        console.log('adding failed')
        console.log(e.message)
      }
    },
  })

  const onSubmit = async (data) => {
    LoaderService.show()

    mutate(data)
    LoaderService.hide()
  }

  return (
    <>
      <CContainer fluid className="h-100">
        <CRow>
          <CCol className="d-flex flex-row justify-content-between pb-4">
            <h2>Tworzenie nowego kontrahenta</h2>
            <div>
              <EditButton label="Zapisz" type={'submit'} />
              <CancelButton label="Anuluj" onClick={() => navigate('/kontrahenci')} />
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader className="fw-bolder">Dane szczegółowe</CCardHeader>
              <CCardBody>
                <BasicForm
                  formFields={formFields}
                  onSubmit={handleSubmit((data) => onSubmit(data))}
                  register={register}
                  watch={watch}
                  setValue={setValue}
                ></BasicForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}
export default PartnerNew
