import React from 'react'
import { CButton, CFormInput, CFormSwitch, CInputGroup, CSpinner } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilSave } from '@coreui/icons'
import { useMutation } from 'react-query'
import DataService from '../services/DataService'
import { notifyError, notifySucces } from '../services/notify'
const ShopAvailabilitySwitcher = ({
  availableForSale = false,
  deliveryId,
  retailPrice: oldPrice,
  deliveryProductId,
}) => {
  const [active, setActive] = React.useState(availableForSale)
  const [retailPrice, SetRetailPrice] = React.useState(oldPrice)

  const { mutate, isLoading } = useMutation(
    DataService.deliveries.retailSettings(deliveryId, deliveryProductId),
    {
      onSuccess: (data) => {
        notifySucces('Dane zostały zaktualizowane')
      },
      onError: (e) => {
        notifyError('Wystąpił błąd')
      },
    }
  )

  const sendData = (status) => {
    if (status === false) {
      mutate({
        retailPrice: 0,
        availableForSale: false,
      })
    } else {
      mutate({
        retailPrice: retailPrice,
        availableForSale: active,
      })
    }
  }

  if (isLoading)
    return (
      <div style={{ maxWidth: '150px', height: 30, display: 'block', margin: 'auto' }}>
        <CSpinner color="primary" style={{ display: 'block', margin: 'auto' }} />
      </div>
    )

  return (
    <div className={'d-flex align-items-center gap-2'} style={{ maxWidth: '150px' }}>
      <CFormSwitch
        name={'active'}
        value={active}
        className={'secondary'}
        size="xl"
        id="formSwitchCheckDefaultXL"
        onClick={() => {
          setActive(!active)
          !active === false && sendData(false)
        }}
        defaultChecked={active}
      />
      <CInputGroup>
        <CFormInput
          name={'retailPriceField'}
          disabled={active === false}
          value={retailPrice / 100}
          step={0.01}
          min={0}
          onChange={(e) => {
            SetRetailPrice(e.target.value * 100)
          }}
          type={'number'}
        />
        <CButton color="primary" onClick={() => sendData()} size={'sm'} disabled={active === false}>
          <CIcon icon={cilSave} />
        </CButton>
      </CInputGroup>
    </div>
  )
}

export default ShopAvailabilitySwitcher
