import { CCard, CCardBody, CCardHeader, CCol, CContainer, CRow } from '@coreui/react-pro'
import React from 'react'
import { useQuery } from 'react-query'
import DataService from 'src/services/DataService'
import IsLoading from 'src/components/IsLoading'
import AddNews from 'src/views/settings/AddNews'

const Settings = () => {
  const {
    data: newsData,
    isError: newsIsError,
    error: newsError,
    isLoading: newsIsLoading,
    refetch: newsRefetch,
  } = useQuery(['news'], DataService.settings.getNews())

  if (newsIsLoading) return <IsLoading />
  if (newsIsError) {
    console.log(newsError)
    return <span>błąd</span>
  }

  return (
    <CContainer fluid className="h-100">
      <CRow>
        <CCol className="d-flex flex-row justify-content-between pb-4">
          <h2>{newsData?.contractorDetails?.companyName}</h2>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={12}>
          <CCard>
            <CCardHeader>Dodaj nową wiadomość:</CCardHeader>
            <CCardBody>
              <AddNews refetch={newsRefetch} initData={newsData} />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  )
}

export default Settings
