import React, { useState } from 'react'
import AddProductCodes from './AddProductCodes'
import DynamicDropdownInput from './DynamicDropdownInput'
import { notifyError } from 'src/services/notify'
import { CRow } from '@coreui/react-pro'

const AddProductCodesWrapper = ({
  register,
  watch,
  setValue,
  unregister,
  onVerify,
  disabled,
  edition = false,
}) => {
  const values = watch()
  const [reset, setReset] = useState(0)
  const products = watch().products

  const handleReset = () => {
    setReset((prev) => prev + 1)
  }

  const handleProductSelect = (e) => {
    const selected = products.find((prod) => prod.product?.id === e.value || prod.id === e.value)
    //check if not already selected
    if (!selected) {
      const index = products.length
      //check if already registerd

      register(`products.${index}`)
      setValue(`products.${index}.id`, e.value)
      setValue(`products.${index}.name`, e.label)
      setValue(`products.${index}.codes`, null)
      setValue(`products.${index}.currency`, 'EUR')
      register(`products.${index}.purchasePrice`)
      setValue(`products.${index}.initialCodesInFile`, 1)
    } else {
      notifyError('You are trying to add already added product - choose another one')
    }
    handleReset()
  }

  // const checkIfOldProduct = (product) => {
  //   if (Array.isArray(edition)) {
  //     return edition.some((obj) => obj.id === product.id)
  //   } else {
  //     return false
  //   }
  // }

  return (
    <div id={reset}>
      {values.products &&
        products.map((product, id) => {
          // addin codes to new delivery
          // console.log(product, !!edition)
          if (product !== 'empty') {
            return (
              <AddProductCodes
                register={register}
                unregister={unregister}
                key={id}
                id={product}
                setValue={setValue}
                watch={watch}
                onVerify={onVerify}
                disabled={product.amount > 0}
                reset={handleReset}
                product={product}
                index={id}
              />
            )
            //adding codes to existing delivery
          } else {
            return ''
          }
        })}

      <CRow className="px-5 pt-0 position-relative">
        <DynamicDropdownInput
          key={reset}
          label={'Dodaj produkt'}
          endpoint={'products'}
          onChange={handleProductSelect}
          disabled={disabled}
          required={true}
          initValue={''}
        />
      </CRow>
    </div>
  )
}

export default AddProductCodesWrapper
