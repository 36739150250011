const listFields = [
  {
    id: 'code',
    category: '',
    label: 'Kod',
    placeholder: '-',
  },
  {
    id: 'status',
    category: '',
    label: 'Status',
    placeholder: '-',
    component: {
      type: 'badge',
      param: 'complaint',
    },
  },
  {
    id: 'name',
    category: 'product',
    label: 'Produkt',
    placeholder: '-',
  },
  {
    id: 'reason',
    category: '',
    label: 'Przyczyna',
    placeholder: '-',
  },
  {
    id: 'name',
    category: 'contractor',
    subcategory: 'contractorDetails',
    label: 'Klient',
    placeholder: '-',
  },
  {
    id: 'id',
    category: 'order',
    label: 'ID Zamówienia',
    placeholder: '-',
    component: {
      type: 'gameStormId',
      param: 'order',
    },
  },
  {
    id: 'date',
    category: 'createdAt',
    label: 'Data utworzenia',
    placeholder: '-',
    format: 'ddmmyyyyhhmm',
  },
  {
    id: 'date',
    category: 'closedAt',
    label: 'Data zamknięcia',
    placeholder: '-',
    format: 'ddmmyyyyhhmm',
  },
  {
    id: 'note',
    category: '',
    label: 'Notatka',
    placeholder: '-',
  },
  {},
  {
    id: 'name',
    category: 'supervisor',
    label: 'Opiekun',
    placeholder: '-',
  },
]

export default listFields
