import React, { useState, useEffect, useRef } from 'react'
import { CBadge, CButton, CCol, CRow } from '@coreui/react-pro'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { toast } from 'react-toastify'

const timeDiff = (now, target) => {
  if (typeof target === 'string') target = new Date(target)
  let diff = Math.floor((target - now) / 1000)
  return diff
}

const SessionTimerHandler = () => {
  const expirationTime = useStoreState((state) => state.auth.tokenExpiration)
  const [countdown, setCountdown] = useState(timeDiff(new Date(), expirationTime))
  //   const setExpiration = useStoreActions((action) => action.app.setSessionExpiresIn)
  //   setExpiration(countdown)

  const toastId = useRef(null)

  const extendSession = useStoreActions((actions) => actions.auth.refreshToken)

  useEffect(() => {
    const displayToast = () => {
      toastId.current = toast(<ToastMsg />, {
        autoClose: 10000,
        style: { backgroundColor: '#fcd002' },
      })
    }
    const closeToast = () => {
      toast.dismiss(toastId.current)
    }

    const ToastMsg = () => {
      return (
        <>
          <CCol className="ps-3">
            {countdown > 0 ? (
              <>
                <CRow className="w-100 px-0 mb-2 d-flex flex-row justify-content-between align-items-center">
                  <div className="fs-5 text-black px-0" style={{ maxWidth: '150px' }}>
                    Sesja wygasa za:
                  </div>
                  <CBadge
                    color="secondary"
                    className="ms-2 fs-4 text-secondary pt-2 bg-transparent border border-secondary"
                    style={{ maxWidth: '80px' }}
                  >
                    {countdown}
                    &nbsp;s
                  </CBadge>
                </CRow>
                <CRow className="w-100 px-0">
                  <CButton onClick={extendSession} color="secondary">
                    Przedłuż sesję
                  </CButton>
                </CRow>
              </>
            ) : (
              <CRow className="w-100 px-0 h-100 d-flex flex-row justify-content-center align-items-center">
                Sesja wygasła
              </CRow>
            )}
          </CCol>
        </>
      )
    }

    if (countdown === 120) displayToast()
    if (countdown === 0) closeToast()

    toast.update(toastId.current, {
      render: ToastMsg,
      autoClose: false,
    })
  }, [countdown, extendSession])

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date()
      let diff = Math.floor((expirationTime - now) / 1000)
      if (diff < 0) {
        clearInterval(interval)
        return
      }
      setCountdown(timeDiff(now, expirationTime))
    }, 1000)

    return () => clearInterval(interval)
  }, [expirationTime])

  return null
}

export default SessionTimerHandler
