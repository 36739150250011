import React from 'react'
import { CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/react-pro'
const DropdownButton = ({ label, buttons = [] }) => {
  return (
    <CDropdown>
      <CDropdownToggle
        color="primary"
        className={'btn btn-primary btn-outline fw-semibold my-1 py-1 ms-2 px-2'}
      >
        {label}
      </CDropdownToggle>
      <CDropdownMenu>
        {buttons.map((button, index) => (
          <CDropdownItem key={index} onClick={button.onClick}>
            {button.label}
          </CDropdownItem>
        ))}
      </CDropdownMenu>
    </CDropdown>
  )
}

export default DropdownButton
