import React from 'react'
import { CButtonGroup, CContainer, CFormCheck, CHeader, CHeaderNav } from '@coreui/react-pro'
// import CIcon from '@coreui/icons-react'
// import { cilApplicationsSettings, cilMenu } from '@coreui/icons'

// import { AppBreadcrumb } from './index'

import { AppHeaderDropdown } from './header/index'
import { cilMoon, cilSun } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { useStoreActions, useStoreState } from 'easy-peasy'

// import { logo } from 'src/assets/brand/logo'

const AppHeader = () => {
  const theme = useStoreState((state) => state.app.theme)
  theme === 'light'
    ? document.body.classList.add('light-theme')
    : document.body.classList.remove('light-theme')

  const toggleTheme = useStoreActions((actions) => actions.app.toggleTheme)
  // const dispatch = useDispatch()
  // const sidebarShow = useSelector((state) => state.sidebarShow)
  // const asideShow = useSelector((state) => state.asideShow)

  // const sidebarShow = useStoreActions((action) => action.app.toggleSidebarShow)

  return (
    <CHeader
      position="sticky"
      className="shadow-none"
      style={
        theme === 'dark'
          ? {
              borderWidth: '0px 0px 1px 0px',
              borderStyle: 'solid',
              // borderImage: 'linear-gradient(to right, #13192C, #2E3035) 1',
              borderImage: 'linear-gradient(to right, #13192C, #2E3035) 1',
            }
          : {
              borderWidth: '0px 0px 1px 0px',
              borderStyle: 'solid',
              // borderImage: 'linear-gradient(to right, #13192C, #2E3035) 1',
              borderImage: 'linear-gradient(to right, #C2C9D9, #ffffff) 1',
            }
      }
    >
      <CContainer fluid className="d-flex justify-content-end">
        {/* <CHeaderToggler
          className="ps-1"
          // onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
          onClick={() => sidebarShow()}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler> */}
        {/* <CHeaderBrand className="mx-auto d-md-none" to="/">
          <CIcon icon={logo} height={48} alt="Logo" />
        </CHeaderBrand> */}
        {/* <CHeaderNav className="d-none d-md-flex me-auto">
          <CNavItem>
            <CNavLink to="/dashboard" component={NavLink}>
              Dashboard
            </CNavLink>
          </CNavItem>
        </CHeaderNav> */}

        <CHeaderNav className="ms-auto me-4">
          <CButtonGroup aria-label="Theme switch">
            <CFormCheck
              type="radio"
              button={{ color: theme === 'dark' ? 'primary' : 'secondary', variant: 'ghost' }}
              name="theme-switch"
              id="btn-light-theme"
              autoComplete="off"
              label={<CIcon icon={cilSun} />}
              // checked={theme === 'default'}
              onChange={() => {
                toggleTheme('light')
                // dispatch({ type: 'set', theme: 'light' })
              }}
            />
            <CFormCheck
              type="radio"
              button={{ color: theme === 'dark' ? 'primary' : 'secondary', variant: 'ghost' }}
              name="theme-switch"
              id="btn-dark-theme"
              autoComplete="off"
              label={<CIcon icon={cilMoon} />}
              // checked={theme === 'dark'}
              onChange={() => {
                toggleTheme('dark')
                // dispatch({ type: 'set', theme: 'dark' })
              }}
            />
          </CButtonGroup>
        </CHeaderNav>

        {/* user avatar navigation */}
        <CHeaderNav className="ms-3 me-4">
          <AppHeaderDropdown />
        </CHeaderNav>

        {/* right side menu toggler */}
        {/* <CHeaderToggler
          className="px-md-0 me-md-3"
          onClick={() => dispatch({ type: 'set', asideShow: !asideShow })}
        >
          <CIcon icon={cilApplicationsSettings} size="lg" />
        </CHeaderToggler> */}
      </CContainer>
      {/* <CHeaderDivider /> */}
      {/* <CContainer fluid>
        <AppBreadcrumb />
      </CContainer> */}
    </CHeader>
  )
}

export default AppHeader
