import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import DataService from 'src/services/DataService'
import { useNavigate, useParams } from 'react-router-dom'
import BasicList from 'src/components/BasicList'

import listFields from '../partner/PartnerList'
import CancelButton from 'src/components/CancelButton'
import EditButton from 'src/components/EditButton'
import PartnerDeliveriesTable from './PartnerDeliveriesTable'

import PartnerOrdersTable from './PartnerOrdersTable'
import { formatPriceToDisplayDecimals } from 'src/helpers'
import IsLoading from 'src/components/IsLoading'

const Partner = () => {
  const { id } = useParams()

  const { data, isError, error, isLoading } = useQuery(
    ['partner', id],
    DataService.partners.getPartner(id)
  )

  const { mutate } = useMutation(DataService.partners.deletePartner(id), {
    onSuccess: () => {
      navigate(`/kontrahenci`)
    },
    onError: (e) => {
      if (e?.message) {
        console.log(e.message)
      }
    },
  })

  const navigate = useNavigate()

  const [activeKey, setActiveKey] = useState(1)

  const [openModal, setOpenModal] = useState(false)

  if (isLoading) return <IsLoading />
  if (isError) return <span>bląd</span>
  if (data.message)
    return (
      <span>
        {data.message}
        {console.log(error)}
      </span>
    )

  return (
    <>
      <CContainer fluid className="h-100">
        <CRow>
          <CCol className="d-flex flex-row justify-content-between pb-4">
            <h2>{data?.contractorDetails?.companyName}</h2>
            <div>
              <EditButton label="Edytuj" onClick={() => navigate(`/kontrahent/${id}/edit`)} />
              <CancelButton label="Usuń" onClick={() => setOpenModal((prev) => !prev)} />
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol lg={5} xl={4}>
            <CCard className="mb-3">
              <CCardHeader>Dane szczegółowe</CCardHeader>
              <BasicList listFields={listFields} data={data} />
            </CCard>
          </CCol>
          <CCol lg={7} xl={8}>
            <CRow className="mb-5" xs={{ gutterY: 3 }}>
              <CCol sm={6}>
                <CCard
                  className="pt-2 px-3 h-100 thunderBg flex-column justify-content-between"
                  style={{ maxHeight: '100px' }}
                >
                  {activeKey === 1 ? (
                    <>
                      <span className="fs-6">Liczba wszystkich dostaw</span>
                      <span className="fs-1">
                        {data?.allDeliveriesAmount ? data?.allDeliveriesAmount : 0}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="fs-6">Liczba wszystkich zamówień</span>
                      <span className="fs-1">
                        {data?.allOrdersAmount ? data?.allOrdersAmount : 0}
                      </span>
                    </>
                  )}
                </CCard>
              </CCol>
              <CCol sm={6}>
                <CCard
                  className="pt-2 px-3 h-100 thunderBg flex-column justify-content-between"
                  style={{ maxHeight: '100px' }}
                >
                  {activeKey === 1 ? (
                    <>
                      <span className="fs-6">Wartość wszystkich dostaw</span>
                      <span className="fs-1">
                        {data?.valueOfAllDeliveries
                          ? formatPriceToDisplayDecimals(data?.valueOfAllDeliveries)
                          : 0}
                        {data.currency ? ' ' + data.currency : ' EUR'}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="fs-6">Wartość wszystkich zamówień</span>
                      <span className="fs-1">
                        {data?.valueOfAllOrders
                          ? formatPriceToDisplayDecimals(data?.valueOfAllOrders)
                          : 0}
                        {data.currency ? ' ' + data.currency : ' EUR'}
                      </span>
                    </>
                  )}
                </CCard>
              </CCol>
            </CRow>
            <CCard>
              <CCardHeader>
                <CNav variant="tabs" className="card-header-tabs">
                  <CNavItem>
                    <CNavLink
                      href="#"
                      active={activeKey === 1}
                      onClick={() => setActiveKey(1)}
                      className="fw-semibold"
                    >
                      Dostawy
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink
                      href="#"
                      active={activeKey === 2}
                      onClick={() => setActiveKey(2)}
                      className="fw-semibold"
                    >
                      Zamówienia
                    </CNavLink>
                  </CNavItem>
                </CNav>
              </CCardHeader>
              <CCardBody>
                <CTabContent>
                  <CTabPane role="tabpanel" aria-labelledby="orders" visible={activeKey === 1}>
                    <PartnerDeliveriesTable id={id} />
                  </CTabPane>
                  <CTabPane role="tabpanel" aria-labelledby="zamówienia" visible={activeKey === 2}>
                    <PartnerOrdersTable id={id} />
                  </CTabPane>
                </CTabContent>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
      <CModal visible={openModal} onClose={() => setOpenModal(false)}>
        <CModalBody className="pt-4">
          <span className="h5 mb-3">Czy chcesz usunąć tego Kontrahenta?</span>
          <br />
          <br />
          <span className="opacity-75">
            Pamiętaj, usunięcie kontrahenta nie będzie możliwe, jeżeli są do niego przypisane jakieś
            zasoby
          </span>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="danger"
            onClick={() => {
              mutate()
              setOpenModal(false)
            }}
            className="ms-4 fw-semibold px-3 py-1"
          >
            Usuń Kontrahenta
          </CButton>
          <CButton
            onClick={() => setOpenModal(false)}
            color="primary"
            variant="outline"
            className="ms-4 fw-semibold px-3 py-1"
          >
            Anuluj
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

export default Partner
