import React from 'react'

const FillBar = ({ value, sum, className = null }) => {
  return (
    <div className={className + ' rounded-3 custom-fill-bar'} style={{ height: '15px' }}>
      <div
        className={
          value >= sum
            ? 'bg-success position-absolute start-0 '
            : 'bg-secondary position-absolute start-0 animated'
        }
        style={{ height: '100%', width: `${(value / sum) * 100}%`, minWidth: '2px' }}
      ></div>
      <div className="text-center z-1 position-absolute">{value + '/' + sum}</div>
    </div>
  )
}

export default FillBar
