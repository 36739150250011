const listFields = [
  {
    id: 'name',
    category: 'contractor',
    label: 'Klient',
    placeholder: '-',
  },
  {
    id: 'status',
    label: 'Status',
    placeholder: '-',
    component: {
      type: 'badge',
      param: 'order',
    },
  },
  {
    id: 'invoiceNumber',
    label: 'Numer faktury',
    placeholder: '-',
  },
  {
    id: 'dateOfOrder',
    label: 'Data zamówienia',
    format: 'ddmmyyyy',
    placeholder: '-',
  },
  {
    id: 'value',
    label: 'Wartość zamówienia',
    format: 'zzgg',
    placeholder: '-',
    suffix: 'EUR',
  },
  {
    id: 'valueByCurrency',
    label: 'Wartość zamówienia w walucie',
    placeholder: '-',
    format: 'zzgg',
  },
  {
    id: 'currency',
    label: 'Waluta',
    placeholder: '-',
  },
  {
    id: 'name',
    category: 'author',
    label: 'Autor',
    placeholder: '-',
  },
  {
    id: 'isPaid',
    format: 'bool',
    label: 'Opłacone',
    placeholder: '-',
    component: {
      type: 'badge',
      param: 'isPaid',
    },
  },
  {
    id: 'note',
    label: 'Uwagi',
    placeholder: '-',
  },
  {},
  {
    id: 'name',
    category: 'supervisor',
    label: 'OPIEKUN',
    placeholder: '-',
  },
]

export default listFields
