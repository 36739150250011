import React from 'react'
import { CCard, CCardBody, CCardHeader, CCol, CContainer, CRow } from '@coreui/react-pro'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import LoaderService from 'src/services/LoaderService'
import BasicForm from 'src/components/BasicForm'
import DataService from 'src/services/DataService'
import CancelButton from 'src/components/CancelButton'
import EditButton from 'src/components/EditButton'
import { formFieldsNew } from './ComplaintForm'

const ComplaintNew = () => {
  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      code: '',
      status: 'OTWARTE',
      product: '',
      reason: '',
      contractor: '',
      orderId: '',
      note: '',
    },
  })

  const navigate = useNavigate()

  const { mutate } = useMutation(DataService.complaints.addComplaint, {
    onSuccess: () => {
      navigate('/reklamacje')
    },
    onError: (e) => {
      if (e?.message) {
        console.log('adding failed')
        console.log(e.message)
      }
    },
  })

  const onSubmit = async (data) => {
    LoaderService.show()

    mutate(data)
    LoaderService.hide()
  }

  return (
    <>
      <CContainer fluid className="h-100">
        <CRow>
          <CCol className="d-flex flex-row justify-content-between pb-4">
            <h2>Tworzenie nowej reklamacji</h2>
            <div>
              <EditButton label="Zapisz" />
              <CancelButton label="Anuluj" onClick={() => navigate('/reklamacje')} />
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>Dane reklamacji</CCardHeader>
              <CCardBody>
                <BasicForm
                  formFields={formFieldsNew}
                  onSubmit={handleSubmit((data) => onSubmit(data))}
                  register={register}
                  setValue={setValue}
                  watch={watch}
                  new={true}
                ></BasicForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}
export default ComplaintNew
