import { cilX } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CFormLabel } from '@coreui/react-pro'
import { debounce } from 'lodash'
import React, { useCallback } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { useQuery } from 'react-query'
// import AsyncSelect from 'react-select'
import Select from 'react-select'
import DataService from 'src/services/DataService'

const DynamicDropdownInput = ({
  label,
  id,
  endpoint = 'products',
  onChange,
  onClear,
  nameField = 'name',
  initValue,
  placeholder = 'Wybierz opcję',
  disabled = false,
  required,
  clear = false,
}) => {
  const endpoints = {
    products: DataService.products.getProductsList,
    contractors: DataService.partners.getPartnersList,
    regions: DataService.getRegions, // local data
    platforms: DataService.getPlatforms, //local data
    reserveStatuses: DataService.getReserveStatuses, //local data
    users: DataService.getUsersList,
    productCategory: DataService.getProductCategoryList,
  }
  const [, setInputText] = useState()
  const [searchText, setSearchText] = useState()
  const [initialValue, setInitialValue] = useState()
  const selectRef = useRef()
  const setSearchTextDeleyed = useRef(
    debounce((searchText) => setSearchText(searchText), 500)
  ).current

  const reformatData = useCallback(
    (data) => {
      if (!data?.data?.results) return
      const keys = nameField.split('.')

      let getLabel = (record) => {
        let label = record
        for (let i = 0; i < keys.length; i++) {
          label = label[keys[i]]
        }
        return label
      }

      return data.data.results.map((record) => {
        return { value: record.id, label: getLabel(record), data: record }
      })
    },
    [nameField]
  )

  // console.log('@loading data for:', endpoint)
  const query = useQuery(endpoint, endpoints[endpoint]({ name: searchText }))
  const { data, isLoading, refetch } = query
  const handleInputChange = (inputText) => {
    setInputText(inputText)
    setSearchTextDeleyed(inputText)
  }

  const styles = {
    container: () => ({
      borderRadius: '3px',
      position: 'relative',
    }),
    control: (baseStyles, state) => {
      //   console.log(baseStyles)
      return {
        ...baseStyles,
        borderWidth: '1px',
        borderStyle: 'solid',
        boxShadow: 'none',
        backgroundColor: 'rgb(56,60,71)',
        borderColor: state.isFocused ? '#fcd002' : 'transparent',
        '&:hover': {
          borderColor: '',
        },
      }
    },
    singleValue: () => ({
      gridArea: '1 / 1 / 2 / 3',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginLeft: 2,
      marginRight: 2,
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: 'rgb(56,60,71)',
    }),
    option: (_, state) => ({
      width: '100%',
      padding: '8px 10px',
      backgroundColor: state.isFocused ? '#fcd002' : '',
      color: state.isFocused ? 'black' : '',
    }),
    dropdownIndicator: (baseStyles) => ({
      ...baseStyles,
      color: '#fcd002',
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      color: '#fcd002',
    }),
  }
  useEffect(() => {
    if (data) {
      setInitialValue(reformatData(data).find((el) => el.value === initValue))
      if (initValue?.id) setInitialValue(reformatData(data).find((el) => el.value === initValue.id))
    }
  }, [data, initValue, reformatData])

  useEffect(() => {
    refetch()
  }, [searchText, refetch])

  if (data)
    return (
      <>
        <CFormLabel className="mb-3 mt-0 px-0" htmlFor={id}>
          {label}
        </CFormLabel>
        <div className="d-flex px-0 align-items-center mb-3">
          <Select
            ref={selectRef}
            key={initialValue}
            required={required}
            noOptionsMessage={() => 'Brak wyników'}
            placeholder={placeholder}
            onInputChange={handleInputChange}
            onChange={(event) => {
              onChange(event, id)
            }}
            isLoading={isLoading}
            defaultOptions
            cacheOptions
            options={reformatData(data)}
            className="react-select-container px-0 py-0  flex-fill"
            classNamePrefix="react-select"
            styles={styles}
            defaultValue={initialValue} //option
            isDisabled={disabled}

            // isClearable={true}
          />
          {clear ? (
            <CIcon
              icon={cilX}
              color="danger"
              className="text-primary btn p-0 m-0 ms-2 shadow-none"
              onClick={() => {
                onClear(id)
                selectRef.current.clearValue()
              }}
              style={{ width: '20px', height: '20px' }}
            />
          ) : (
            ''
          )}
        </div>
      </>
    )
}

export default DynamicDropdownInput
