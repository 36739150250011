import { useStoreState } from 'easy-peasy'
import { notifySucces } from './services/notify'

export const fileToBase64 = async (file) => {
  const fr = new FileReader()
  return new Promise((resolve, reject) => {
    try {
      fr.onload = function () {
        const base64 = fr.result
        const parsedBase64 = base64.substr(base64.indexOf(',') + 1)
        resolve(parsedBase64)
      }
      fr.readAsDataURL(file)
    } catch (e) {
      reject(e)
    }
  })
}

export const downloadFileFromBlob = async (data, fileName, type = '') => {
  try {
    const blob = new Blob([data], { type: type })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(url)
    document.body.removeChild(link)
  } catch (error) {
    console.log(error)
  }
}

export const parseObjectToUrlQuery = (object, fields = null) => {
  const defaultPerPage = 200
  const defaultPage = 1
  const defaultSortBy = 'id'
  const defaultSortDir = 'desc'
  let query = '?'
  if (!object?.sortBy) query += `sortBy=${defaultSortBy}&`
  if (!object?.sortDir) query += `sortDir=${defaultSortDir}&`
  if (object && object?.sortDir !== 'asc' && object?.sortDir !== 'desc') delete object.sortDir
  if (!object?.page) query += `page=${defaultPage}&`
  if (!object?.perPage) query += `perPage=${defaultPerPage}&`
  if (!object) {
    return query
  }
  // console.log(Object.entries(object))
  query += Object.entries(object)
    .map((a) => {
      if (String(a[1]) === '') return ''
      else {
        return a.join('=') + '&'
      }
    })
    .join('')

  if (fields != null) query += fields.map((field) => `&${field}`)
  return query
}

export const formatData = (data, format) => {
  if (null === data) {
    return '-'
  }
  if ('unlimited' === data) {
    return '-'
  }
  if (Array.isArray(data)) return data.join(' ')
  const date = new Date(data)
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear().toString()
  const hour = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  switch (format) {
    case 'ddmmyyyy':
      return `${day}.${month}.${year}`

    case 'ddmmyyyyhhmm':
      return `${day}.${month}.${year} ${hour}:${minutes}`
    case 'zzgg':
      return data / 100
    case 'elapsedTime':
      let rest = 0
      let d = (data / 86400000).toFixed(0)
      rest = data % 86400000
      let h = (rest / 3600000).toFixed(0)
      rest = rest % 3600000
      let m = (rest / 60000).toFixed(0)
      rest = rest % 60000
      let s = (rest / 1000).toFixed(0)
      return `${d}D ${h}h ${m}m ${s}s`
    default:
      return data
  }
}

export const downloadFile = (data, fileName, type = '') => {
  const url = window.URL.createObjectURL(new Blob([data], { type: type }))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const downloadCsvFile = (data, fileName) => {
  const BOM = '\uFEFF'
  const url = window.URL.createObjectURL(new Blob([BOM + data], { type: 'text/csv;charset=utf-8' }))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)

  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const flattenDataStructure = (data) => {
  let flattened = []

  const flatten = (object) => {
    const flattenedObject = {}
    Object.entries(object).forEach(([key, value]) => {
      if (typeof value === 'object' && value !== null) {
        const nestedObj = flatten(value)
        Object.entries(nestedObj).forEach(([nestedKey, nestedValue]) => {
          flattenedObject[`${key}.${nestedKey}`] = nestedValue
        })
      } else {
        flattenedObject[key] = value
      }
    })
    return flattenedObject
  }

  for (let i = 0; i < data.length; i++) {
    flattened.push(flatten(data[i]))
  }
  return flattened
}

export const convertStringToBoolean = (obj) => {
  if (typeof obj === 'object' && obj !== null) {
    for (let key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        obj[key] = convertStringToBoolean(obj[key])
      } else if (typeof obj[key] === 'string') {
        if (obj[key].toLowerCase() === 'true') {
          obj[key] = true
        } else if (obj[key].toLowerCase() === 'false') {
          obj[key] = false
        }
      }
    }
  }
  return obj
}

export function arrayToFileList(array) {
  const files = []
  array.forEach((file, index) => {
    files.push(file)
  })

  const FileListWrapper = function (fileArray) {
    this.length = fileArray.length
    for (let i = 0; i < fileArray.length; i++) {
      this[i] = fileArray[i]
    }
  }

  FileListWrapper.prototype = Object.create(FileList.prototype)
  return new FileListWrapper(files)
}

export const calculateProgress = (product, valueKey, expectedKey) => {
  let expected = product[expectedKey]
  let value = product[valueKey]
  // console.log(products)
  // products.forEach((product) => {
  //   expected += product.expected
  //   value += product.amount
  // })
  return (value * 100) / expected
}

export const generateGameStormID = (type, id) => {
  let prefix = 'JK-'
  if (type === 'delivery') {
    prefix = prefix + 'DELIVERY-'
  } else if (type === 'order') {
    prefix = prefix + 'ORDER-'
  }
  return prefix + String(id).padStart(5, '0')
}

export const formatPriceToDisplayDecimals = (price) => {
  return parseInt(price) / 100
}

export const convertPriceToWholeNumber = (price) => {
  return parseInt((price * 100).toFixed())
}

export const generateRandomCodes = (qunatity, length) => {
  let codes = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < qunatity; i++) {
    let code = ''
    for (let j = 0; j < length; j++) {
      const randomIndex = Math.floor(Math.random() * characters.length)
      code += characters.charAt(randomIndex)
    }
    codes = codes + code + '  '
  }
  return codes
}

export const useHasAccess = (accessRoles) => {
  const isAdmin = useStoreState((store) => store.auth.isAdmin)
  const userRole = useStoreState((store) => store.auth.role)

  if (isAdmin === true) return true
  // if (accessRoles === undefined) return true

  let hasAccess = false
  accessRoles?.forEach((accessRole) => {
    if (accessRole === userRole) hasAccess = true
  })
  return hasAccess
}

export const useIsOwner = (supervisorID) => {
  const isAdmin = useStoreState((store) => store.auth.isAdmin)
  const userId = useStoreState((store) => store.auth.user.id)

  if (isAdmin === true) return true
  if (supervisorID === userId) return true
  return false
}

export const countCodesInOrder = (data) => {
  let codesSum = 0
  data.products.forEach((product) => {
    codesSum += product.codes
  })

  return codesSum
}

export const copyToClipboard = async (string) => {
  try {
    await navigator.clipboard.writeText(string)
    notifySucces('Skopiowano do schowka')
  } catch (err) {
    console.log(err)
  }
}
