export const regions = [
  { id: 1, name: 'Argentina (AR)' },
  { id: 2, name: 'Asia' },
  { id: 3, name: 'Australia (AU)' },
  { id: 4, name: 'Bahrain (BI)' },
  { id: 5, name: 'Belgium (BE)' },
  { id: 6, name: 'Brazil (BR)' },
  { id: 7, name: 'Canada (CA)' },
  { id: 8, name: 'Czech Republic (CZ)' },
  { id: 9, name: 'Denmark (DK)' },
  { id: 10, name: 'EMEA' },
  { id: 11, name: 'Europe' },
  { id: 12, name: 'Finland (FI)' },
  { id: 13, name: 'France (FR)' },
  { id: 14, name: 'Germany (DE)' },
  { id: 15, name: 'Global (WW)' },
  { id: 16, name: 'Hong Kong (HK)' },
  { id: 17, name: 'Hungary (HU)' },
  { id: 18, name: 'India (IN)' },
  { id: 19, name: 'Ireland (IE)' },
  { id: 20, name: 'Italy (IT)' },
  { id: 21, name: 'Japan (JP)' },
  { id: 22, name: 'Kuwait (KW)' },
  { id: 23, name: 'Latin America (LATAM)' },
  { id: 24, name: 'Latvia (LV)' },
  { id: 25, name: 'Lebanon (LB)' },
  { id: 26, name: 'Luxembourg (LU)' },
  { id: 27, name: 'Middle East' },
  { id: 28, name: 'Netherlands (NL)' },
  { id: 29, name: 'New Zealand (NZ)' },
  { id: 30, name: 'North America (NA)' },
  { id: 31, name: 'Poland (PL)' },
  { id: 32, name: 'Portugal (PT)' },
  { id: 33, name: 'Quatar (QA)' },
  { id: 34, name: 'Romania (RO)' },
  { id: 35, name: 'RoW' },
  { id: 36, name: 'Russia (RU)' },
  { id: 37, name: 'Saudi Arabia (SA)' },
  { id: 38, name: 'Slovakia (SK)' },
  { id: 39, name: 'Slovenia (SI)' },
  { id: 40, name: 'Spain (ES)' },
  { id: 41, name: 'Sweden (SE)' },
  { id: 42, name: 'Switzerland (CH)' },
  { id: 43, name: 'Turkey (TR)' },
  { id: 44, name: 'Ukraine (UA)' },
  { id: 45, name: 'United Arab Emirates (AE)' },
  { id: 46, name: 'United Kingdom (UK)' },
  { id: 47, name: 'United States (US)' },
]
export const regionsOld = [
  { id: 'ARGENTINA', name: 'Argentina' },
  { id: 'ASIA', name: 'Asia' },
  { id: 'AUSTRALIA', name: 'Australia' },
  { id: 'BAHRAIN', name: 'Bahrain' },
  { id: 'BELGIUM', name: 'Belgium' },
  { id: 'BRAZIL', name: 'Brazil' },
  { id: 'CANADA', name: 'Canada' },
  { id: 'CZECH_REPUBLIV', name: 'Czech Republic' },
  { id: 'DENMARK', name: 'Denmark' },
  { id: 'EMEA', name: 'EMEA' },
  { id: 'EUROPE', name: 'Europe' },
  { id: 'FINLAND', name: 'Finland' },
  { id: 'FRANCE', name: 'France' },
  { id: 'GERMANY', name: 'Germany' },
  { id: 'GLOBAL', name: 'Global' },
  { id: 'HONG_KONG', name: 'Hong Kong' },
  { id: 'HUNGARY', name: 'Hungary' },
  { id: 'INDIA', name: 'India' },
  { id: 'IRELAND', name: 'Ireland' },
  { id: 'ITALY', name: 'Italy' },
  { id: 'JAPAN', name: 'Japan' },
  { id: 'KUWAIT', name: 'Kuwait' },
  { id: 'LATIN_AMERICA', name: 'Latin America' },
  { id: 'LATVIA', name: 'Latvia' },
  { id: 'LEBANON', name: 'Lebanon' },
  { id: 'LUXEMBOURG', name: 'Luxembourg' },
  { id: 'MIDDLE_EAST', name: 'Middle East' },
  { id: 'NETHERLANDS', name: 'Netherlands' },
  { id: 'NEW_ZEALAND', name: 'New Zealand' },
  { id: 'NORTH_AMERICA', name: 'North America' },
  { id: 'POLAND', name: 'Poland' },
  { id: 'PORTUGAL', name: 'Portugal' },
  { id: 'QUATAR', name: 'Quatar' },
  { id: 'ROMANIA', name: 'Romania' },
  { id: 'ROW', name: 'RoW' },
  { id: 'RUSSIA', name: 'Russia' },
  { id: 'SAUDI_ARABIA', name: 'Saudi Arabia' },
  { id: 'SLOVAKIA', name: 'Slovakia' },
  { id: 'SLOVENIA', name: 'Slovenia' },
  { id: 'SPAIN', name: 'Spain' },
  { id: 'SWEDEN', name: 'Sweden' },
  { id: 'SWITZERLAND', name: 'Switzerland' },
  { id: 'TURKEY', name: 'Turkey' },
  { id: 'UKRAINE', name: 'Ukraine' },
  { id: 'UNITED_ARAB_EMIRATES', name: 'United Arab Emirates' },
  { id: 'UNITED_KINGDOM', name: 'United Kingdom' },
  { id: 'UNITED_STATES', name: 'United States' },
  { id: 'LATAM', name: 'LATAM' },
]

export const regions2 = [
  { id: 'Argentina', name: 'Argentina' },
  { id: 'Asia', name: 'Asia' },
  { id: 'Australia', name: 'Australia' },
  { id: 'Bahrain', name: 'Bahrain' },
  { id: 'Belgium', name: 'Belgium' },
  { id: 'Brazil', name: 'Brazil' },
  { id: 'Canada', name: 'Canada' },
  { id: 'Czech Republic', name: 'Czech Republic' },
  { id: 'Denmark', name: 'Denmark' },
  { id: 'Emea', name: 'EMEA' },
  { id: 'Europe', name: 'Europe' },
  { id: 'Finland', name: 'Finland' },
  { id: 'France', name: 'France' },
  { id: 'Germany', name: 'Germany' },
  { id: 'Global', name: 'Global' },
  { id: 'Hong Kong', name: 'Hong Kong' },
  { id: 'Hungary', name: 'Hungary' },
  { id: 'India', name: 'India' },
  { id: 'Ireland', name: 'Ireland' },
  { id: 'Italy', name: 'Italy' },
  { id: 'Japan', name: 'Japan' },
  { id: 'Kuwait', name: 'Kuwait' },
  { id: 'Latin America', name: 'Latin America' },
  { id: 'Latvia', name: 'Latvia' },
  { id: 'Lebanon', name: 'Lebanon' },
  { id: 'Luxembourg', name: 'Luxembourg' },
  { id: 'Middle East', name: 'Middle East' },
  { id: 'Netherlands', name: 'Netherlands' },
  { id: 'New Zealand', name: 'New Zealand' },
  { id: 'North America', name: 'North America' },
  { id: 'Poland', name: 'Poland' },
  { id: 'Portugal', name: 'Portugal' },
  { id: 'Quatar', name: 'Quatar' },
  { id: 'Romania', name: 'Romania' },
  { id: 'RoW', name: 'RoW' },
  { id: 'Russia', name: 'Russia' },
  { id: 'Saudi Arabia', name: 'Saudi Arabia' },
  { id: 'Slovakia', name: 'Slovakia' },
  { id: 'Slovenia', name: 'Slovenia' },
  { id: 'Spain', name: 'Spain' },
  { id: 'Seden', name: 'Sweden' },
  { id: 'Switzerland', name: 'Switzerland' },
  { id: 'Turkey', name: 'Turkey' },
  { id: 'Ukraine', name: 'Ukraine' },
  { id: 'United Arab Emirates', name: 'United Arab Emirates' },
  { id: 'United Kingdom', name: 'United Kingdom' },
  { id: 'United States', name: 'United States' },
]
