import React from 'react'
import { useMutation } from 'react-query'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCardHeader,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react-pro'
import { ReactComponent as Logo } from 'src/assets/brand/logo.svg'
import Thunder from 'src/assets/brand/th1.svg'
import { useForm } from 'react-hook-form'
import { cilLockLocked } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import { useParams } from 'react-router-dom'
import { notifyError } from 'src/services/notify'
import DataService from 'src/services/DataService'
import { downloadFileFromBlob } from 'src/helpers'

const Download = () => {
  const { uid } = useParams()
  // console.log({ uid })

  const { register, handleSubmit } = useForm({
    defaultValues: {
      uid: uid,
      password: '',
    },
  })

  const { mutate: receiveCodesMutation } = useMutation(DataService.orders.receiveCodes(), {
    onSuccess: (res) => {
      const name =
        'JK-ORDER-' + res?.headers['content-disposition'].match(/\d+/g)[0].padStart(5, '0') //for example: "attachment;filename="JK-ORDER-78.zip""
      downloadFileFromBlob(res.data, `${name}.zip`)
    },
    onError: (e) => {
      notifyError(e)
    },
  })

  const onSubmit = async (data) => {
    receiveCodesMutation(data)
  }
  //   if (isLoading) {
  //     return <IsLoading />
  //   }

  return (
    <>
      <div className="bg-gradient min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="d-flex justify-content-center align-items-center">
            <Logo className="align-self-center fill mb-8" style={{ height: '40px' }} />
          </CRow>
          <CRow className="justify-content-center">
            <CCol lg={6} md={8}>
              <CCardGroup>
                <CCard className="border shadow-none bg-dark mx-auto" style={{ maxWidth: '450px' }}>
                  <CCardHeader
                    className="h2 py-5 text-center bg-secondary text-white"
                    style={{
                      backgroundImage: `url('${Thunder}')`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center right 20%',
                      backgroundSize: 'fill',
                    }}
                  >
                    Download
                  </CCardHeader>
                  <CCardBody className="py-5 px-5">
                    <CForm
                      onSubmit={handleSubmit((data) => {
                        onSubmit(data)
                      })}
                    >
                      <CInputGroup className="pb-4 mb-4 border-bottom">
                        <CInputGroupText className="px-3">
                          <CIcon size="lg" icon={cilLockLocked} />
                        </CInputGroupText>
                        <CFormInput
                          size="lg"
                          name="password"
                          type="password"
                          placeholder="Password"
                          {...register('password')}
                        />
                      </CInputGroup>
                      <CRow>
                        <CCol className="d-flex justify-content-center">
                          <CButton
                            type="submit"
                            // variant="outline"
                            color="primary"
                            size="lg"
                            className="px-6 py-2 flex-grow font-lg fw-bold "
                          >
                            Confirm
                          </CButton>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </>
  )
}

export default Download
