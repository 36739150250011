import { CButton, CForm, CFormInput, CFormLabel, CFormTextarea, CRow } from '@coreui/react-pro'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { fileToBase64 } from 'src/helpers'
import DataService from 'src/services/DataService'
import LoaderService from 'src/services/LoaderService'
import { toast } from 'react-toastify'

const AddAttachmentRecord = ({ productId, code, refetch, setLastPage, status }) => {
  const { register, handleSubmit, setValue, reset, resetField } = useForm({
    defaultValues: {
      text: '',
      // file: '',
    },
  })

  const { id } = useParams()

  const { mutate } = useMutation(DataService.complaints.addComplaintNote(id), {
    onSuccess: () => {
      console.log('notatka zapisana')
      setValue('text', null)
      refetch().then(() => setLastPage())
      reset()
    },
    onError: (e) => {
      if (e?.message) {
        // console.log('adding note failed')
        console.log(e.message)
      }
    },
  })

  const onSubmit = async (data) => {
    LoaderService.show()

    // console.log(data)
    let newData = { ...data }
    if (!data.file[0]) delete newData.file
    if (data.file[0]) {
      newData.file = {
        base64: await fileToBase64(newData.file[0]),
        type: data.file[0].type,
      }
      newData.date = new Date()
    }
    mutate(newData)
    LoaderService.hide()
  }

  const validateFileSize = (data) => {
    if (data.target.files[0].size >= 524288) {
      toast.warning('Maksymalny rozmiar pliku to 0,5MB')
      resetField('file')
    }
  }

  return (
    <CRow>
      <CForm onSubmit={handleSubmit((data) => onSubmit(data))} className="d-flex flex-column ">
        <CFormLabel className="mb-0" htmlFor="text">
          notatka
        </CFormLabel>
        <CFormTextarea
          rows={3}
          type="text"
          id="text"
          placeholder={''}
          required
          {...register('text')}
          className="mb-1"
        />
        <span className="text-danger">
          {/* {errors && <ErrorMessage errors={errors} name={`${field.category}.${field.id}`} />} */}
        </span>
        <CFormLabel className="mb-0" htmlFor="file">
          załącznik
        </CFormLabel>
        <CFormInput
          type="file"
          id="file"
          placeholder={''}
          {...register('file')}
          className="mb-4 border-bottom"
          onChange={validateFileSize}
        />
        <span className="text-danger">
          {/* {errors && <ErrorMessage errors={errors} name={`${field.category}.${field.id}`} />} */}
        </span>
        {status !== 'ZAMKNIĘTE' && (
          <CButton type="submit" className="ms-auto fw-semibold px-5 py-1">
            Zapisz
          </CButton>
        )}
      </CForm>
    </CRow>
  )
}

export default AddAttachmentRecord
