import React from 'react'
import { CCloseButton, CSidebar, CSidebarHeader } from '@coreui/react-pro'
import { useStoreActions, useStoreState } from 'easy-peasy'

const AppAside = () => {
  // const dispatch = useDispatch()
  const asideShow = useStoreState((state) => state.app.asideShow)
  const toggleAsideShow = useStoreActions((actions) => actions.app.toggleAsideShow)

  return (
    <CSidebar
      colorScheme="light"
      size="lg"
      overlaid
      placement="end"
      visible={asideShow}
      // onVisibleChange={(val)=>toggleAsideShow(val)}
    >
      <CSidebarHeader className="bg-transparent">
        <CCloseButton className="float-end" onClick={() => toggleAsideShow(false)} />
      </CSidebarHeader>
    </CSidebar>
  )
}

export default React.memo(AppAside)
