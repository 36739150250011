import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CRow,
  CSmartTable,
  CTabContent,
} from '@coreui/react-pro'
import { useQuery } from 'react-query'
import { tableProps } from './ComplaintsTable'
import { paginationProps } from 'src/assets/custom/tableSorting'

import { useNavigate } from 'react-router-dom'
import DataService from 'src/services/DataService'
import { useForm } from 'react-hook-form'
import DynamicDropdownInput from 'src/components/DynamicDropdownInput'
import EditButton from 'src/components/EditButton'
import { cilMagnifyingGlass } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import IsLoading from 'src/components/IsLoading'
import IsLoadingOverlay from 'src/components/IsLoadingOverlay'

const Complaints = () => {
  const navigate = useNavigate()
  const [activePage, setActivePage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [sortBy, setSortBy] = useState('order')
  const [sortDir, setSortDir] = useState('desc')
  const [filter, setFilter] = useState()
  const [prevVals, setPrevVals] = useState()
  const [reset, setReset] = useState(0)

  const { data, error, isLoading, refetch, isRefetching } = useQuery(
    ['complaints', filter, activePage, itemsPerPage, sortBy, sortDir],
    DataService.complaints.getComplaints({
      ...filter,
      page: activePage,
      perPage: itemsPerPage,
      sortBy,
      sortDir,
    }),
    { keepPreviousData: true }
  )

  const { watch, register, setValue } = useForm({
    defaultValues: {
      status: '',
      contractor: '',
      code: '',
    },
  })

  const currVals = watch()

  useEffect(() => {
    console.log(currVals)
    if (JSON.stringify(currVals) !== JSON.stringify(prevVals)) {
      setPrevVals(currVals)
      setFilter(currVals)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currVals.status, currVals.contractor, prevVals])

  useEffect(() => {
    refetch()
  }, [filter, refetch])

  useEffect(() => {
    refetch()
  }, [activePage, itemsPerPage, sortBy, sortDir, refetch])

  if (isLoading) {
    return <IsLoading />
  }

  if (!data) {
    console.log(error)
    return <span>missing data</span>
  }

  const handleDropdownSelect = (e, id) => {
    if (e?.value) setValue(id, e.value)
  }
  const handleDropdownClear = (id) => {
    console.log(id)
    setValue(id, null)
    setReset((prev) => prev + 1)
    console.log(watch())
  }

  return (
    <>
      <CContainer fluid>
        <CRow>
          <CCol className="d-flex justify-content-between pb-1">
            <h2>Reklamacje</h2>
            <div>
              <EditButton
                label={'+ Dodaj reklamacje'}
                onClick={() => {
                  navigate('/reklamacja/new')
                }}
                lg
              />
            </div>
          </CCol>
        </CRow>
        <CRow xs={{ gutterX: 3 }}>
          <CCol lg={3} className="align-self-start">
            <CCard className="mt-3 overflow-visible px-3 fill h-100">
              <CForm>
                <CRow className="ps-2">
                  <CFormLabel className="mb-3 mt-3 px-0" htmlFor="status">
                    STATUS:
                  </CFormLabel>
                </CRow>
                <CRow className="px-2">
                  <CCol xs={6}>
                    <CRow>
                      <CFormCheck
                        button={{ color: 'primary', variant: 'outline' }}
                        {...register('status')}
                        inline
                        type="radio"
                        name="status"
                        id="status1"
                        value="OTWARTE"
                        label="Otwarte"
                        onClick={function () {
                          if (watch().status === 'OTWARTE') setValue('status', '')
                        }}
                        // defaultChecked
                      />
                    </CRow>
                  </CCol>
                  <CCol xs={6}>
                    <CRow>
                      <CFormCheck
                        button={{ color: 'primary', variant: 'outline' }}
                        {...register('status')}
                        inline
                        type="radio"
                        name="status"
                        id="status2"
                        value="W TRAKCIE"
                        label="W trakcie"
                        onClick={function () {
                          if (watch().status === 'W TRAKCIE') setValue('status', '')
                        }}
                      />
                    </CRow>
                  </CCol>
                </CRow>

                <CRow className="px-2 pb-3">
                  <CCol xs={6}>
                    <CRow>
                      <CFormCheck
                        button={{ color: 'primary', variant: 'outline' }}
                        {...register('status')}
                        inline
                        type="radio"
                        name="status"
                        id="status3"
                        value="ROZWIĄZANE"
                        label="Rozwiązane"
                        onClick={function () {
                          if (watch().status === 'ROZWIĄZANE') setValue('status', '')
                        }}
                      />
                    </CRow>
                  </CCol>
                  <CCol>
                    <CRow>
                      <CFormCheck
                        button={{ color: 'primary', variant: 'outline' }}
                        className="label-white"
                        {...register('status')}
                        inline
                        type="radio"
                        name="status"
                        id="status4"
                        value="ZAMKNIĘTE"
                        label="Zamknięte"
                        onClick={function () {
                          if (watch().status === 'ZAMKNIĘTE') setValue('status', '')
                        }}
                      />
                    </CRow>
                  </CCol>
                </CRow>

                <CRow className="px-2">
                  <DynamicDropdownInput
                    key={reset}
                    label={'KLIENT:'}
                    id={`contractor`}
                    endpoint={'contractors'}
                    nameField={'name'}
                    onChange={handleDropdownSelect}
                    clear={true}
                    onClear={handleDropdownClear}
                  />
                </CRow>
                <CRow className="px-2"></CRow>
                <CRow className="ps-2">
                  <CFormLabel className="mb-3 px-0" htmlFor="kod">
                    KOD:
                  </CFormLabel>
                </CRow>
                <CRow className="px-2 mb-3 d-flex">
                  <div className="d-flex px-0 align-items-center mb-3">
                    <CFormInput
                      type="text"
                      name="code"
                      {...register('code')}
                      className="flex-fill"
                      onKeyPress={(event) => (event.key === 'Enter' ? setFilter(currVals) : null)}
                    ></CFormInput>

                    <CIcon
                      icon={cilMagnifyingGlass}
                      color="danger"
                      className="text-primary btn p-0 m-0 ms-2 shadow-none"
                      onClick={() => {
                        setFilter(currVals)
                      }}
                      style={{ width: '20px', height: '20px' }}
                    />
                  </div>
                </CRow>
              </CForm>
            </CCard>
          </CCol>
          <CCol lg={9} className="">
            <CCard className="mt-3">
              <CCardBody>
                <CTabContent>
                  {isRefetching && <IsLoadingOverlay />}
                  {data.data && (
                    <CSmartTable
                      loading={isLoading}
                      items={data.data.results}
                      itemsPerPageSelect
                      itemsPerPage={itemsPerPage}
                      pagination={{ external: true }}
                      paginationProps={{
                        activePage: activePage,
                        pages: data.data.pages || 1,
                        ...paginationProps,
                      }}
                      onActivePageChange={setActivePage}
                      onItemsPerPageChange={(value) => {
                        setActivePage(1)
                        setItemsPerPage(value)
                      }}
                      onSorterChange={(value) => {
                        console.log(value)
                        setSortBy(value.column)
                        setSortDir(value.state)
                      }}
                      {...tableProps(navigate)}
                    />
                  )}
                </CTabContent>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default Complaints
