import store from '../store'

const LoaderService = {
  show: () => {
    store.getActions().app.toggleLoader(true)
  },
  hide: () => {
    store.getActions().app.toggleLoader(false)
  },
}

export default LoaderService
