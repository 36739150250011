import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CRow,
  CSmartTable,
} from '@coreui/react-pro'
import React from 'react'
import { useQuery } from 'react-query'
import { tableProps } from './UsersTable'
import { useNavigate } from 'react-router-dom'
import DataService from 'src/services/DataService'
import IsLoading from 'src/components/IsLoading'

const Users = () => {
  const navigate = useNavigate()

  const { data, error, isLoading } = useQuery('users', DataService.getUsers)

  if (isLoading) return <IsLoading />
  if (error) {
    console.log(error)
    return <span>missing data</span>
  }

  return (
    <>
      <CContainer fluid>
        <CRow>
          <CCol className="d-flex justify-content-between pb-4">
            <h2>Użytkownicy</h2>
            <CButton
              color="primary"
              className="ms-4 fw-semibold px-5 py-1"
              onClick={() => navigate(`/uzytkownicy/new`)}
            >
              + Dodaj użytkownika
            </CButton>
          </CCol>
        </CRow>
        <CCard>
          <CCardHeader>Użytkownicy</CCardHeader>
          <CCardBody>
            <CSmartTable items={data.data.results} loading={isLoading} {...tableProps(navigate)} />
          </CCardBody>
        </CCard>
      </CContainer>
    </>
  )
}

export default Users
