import { CButton, CModal, CModalBody, CModalFooter } from '@coreui/react-pro'
import React from 'react'
import CancelButton from 'src/components/CancelButton'
import BasicList from '../BasicList'
import { useMutation } from 'react-query'
import DataService from '../../services/DataService'
import { useForm } from 'react-hook-form'
import ProductAssigningModalForm from './ProductAssigningModalForm'
import { listFields } from './modalFieldList'

const EditProductAssigningModal = ({ productData, setAssignProduct, refetchData }) => {
  const { mutate } = useMutation(DataService.externalApi.kalixo.editRelation(productData?.id), {
    onSuccess: () => {
      setAssignProduct(null)
      refetchData()
      reset()
    },
  })

  const { watch, handleSubmit, register, setValue, reset } = useForm({
    defaultValues: {
      sourceMarginType: '',
      sourceMarginValue: '',
      enabled: true,
    },
  })

  const sendData = (data) => {
    data.sourceMarginType = data.sourceMarginType ? 'percent' : 'value'

    mutate({
      enabled: data.enabled,
      sourceMarginType: data.sourceMarginType,
      sourceMarginValue: data.sourceMarginValue * 100,
    })
  }
  return (
    <>
      <CModal visible={productData !== null}>
        <form action={'submit'} onSubmit={handleSubmit(sendData)}>
          <CModalBody className="pt-4">
            <div className="mb-4">
              <h4>Edytuj powiązanie produktu {productData?.sourceName}</h4>
            </div>
            <BasicList listFields={listFields} data={productData} />
            {productData?.sourceStatus === 'active' && (
              <ProductAssigningModalForm
                register={register}
                watch={watch}
                setValue={setValue}
                editionMode
                data={productData}
              />
            )}
          </CModalBody>
          <CModalFooter>
            {productData?.sourceStatus === 'active' && (
              <CButton
                label={'Powiąż produkty ze sobą'}
                type="submit"
                className=" fw-semibold my-1 py-1 ms-4 px-3"
              >
                Aktualizuj produkt
              </CButton>
            )}
            <CancelButton onClick={() => setAssignProduct(null)} />
          </CModalFooter>
        </form>
      </CModal>
    </>
  )
}

export default EditProductAssigningModal
