import { CAlertLink, CCol, CRow } from '@coreui/react-pro'
import React from 'react'
import { useQuery } from 'react-query'
import DataService from 'src/services/DataService'
import IsLoadingOverlay from './IsLoadingOverlay'
import CIcon from '@coreui/icons-react'
import { cilDataTransferDown } from '@coreui/icons'

const DisplayNews = () => {
  const { data, isLoading, isError } = useQuery(['news'], DataService.settings.getNews())

  if (isError) return 'błąd widgetu wiadomości'
  // eslint-disable-next-line
  if (data === null) return

  if (
    !isLoading &&
    data?.expirationDate?.date &&
    new Date(data?.expirationDate.date) < new Date()
  ) {
    return
  }

  return (
    <CRow sx={12} className="mb-5">
      <CCol sx={12}>
        <div className="news-notification">
          {isLoading ? (
            <IsLoadingOverlay />
          ) : (
            <CRow>
              <CCol xs={10} className="d-flex align-items-center justify-content-start fw-semibold">
                <span>{data?.text}</span>
              </CCol>
              {data?.uri && (
                <CCol xs={2} className="d-flex align-items-center justify-content-end">
                  <CAlertLink
                    target="_blank"
                    href={data?.uri}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <CIcon icon={cilDataTransferDown} />
                  </CAlertLink>
                </CCol>
              )}
            </CRow>
          )}
        </div>
      </CCol>
    </CRow>
  )
}

export default DisplayNews
