import React, { useRef, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { CCard, CCardBody, CCardHeader, CCol, CContainer, CRow } from '@coreui/react-pro'

import listFields from './OrderList'

import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import DataService from 'src/services/DataService'
import IsLoading from 'src/components/IsLoading'
import EditButton from 'src/components/EditButton'
import CancelButton from 'src/components/CancelButton'
import AddOrderProductWrapper from 'src/components/AddOrderProductWrapper'
import BasicList from 'src/components/BasicList'
import { notifyError, notifySucces } from 'src/services/notify'
import IsLoadingOverlay from 'src/components/IsLoadingOverlay'
import { formatPriceToDisplayDecimals } from 'src/helpers'

const OrderEdit = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [partsCompleted, setPartsCompleted] = useState(0)
  const partsSumRef = useRef(0)

  const {
    data,
    error,
    isLoading: orderDataIsLoading,
  } = useQuery(['order', id], DataService.orders.getOrder(id))

  const { register, unregister, watch, handleSubmit, setValue } = useForm({
    values: { ...data },
  })

  const { mutate: addCodesToOrderProductMutation } = useMutation(
    DataService.orders.addCodesToOrderProduct(id),
    {
      onSuccess: ({ res }) => {
        notifySucces(`Dodawanie kodów dla produktu ${res.name} zakończyło się sukcesem`)
        setPartsCompleted((prev) => (prev += 1))
      },
      onError: (res) => {
        notifyError(`Dodawanie kodów dla produktu ${res.name} zakończyło się niepowodzeniem`)
        console.log('Creating delivery falied ', res)
        setPartsCompleted((prev) => (prev += 1))
      },
    }
  )

  useEffect(() => {
    if (data) {
      //reformat price value on data initialization
      data.products.forEach((product, index) => {
        setValue(`products[${index}].price`, formatPriceToDisplayDecimals(product.price))
      })
    }
  }, [data, setValue])

  useEffect(() => {
    //count if all parts of adding codes parts were succesfull
    console.log('partsCompleted:partsSum -> ', partsCompleted, partsSumRef.current)
    if (partsCompleted > 0 && partsCompleted >= partsSumRef.current) {
      setIsLoading(false)
      navigate(`/zamowienie/${id}`)
    }
  }, [partsCompleted, id, navigate])

  if (orderDataIsLoading) return <IsLoading />
  if (!data) {
    console.log(error)
    return <span>missing data</span>
  }

  const countSum = (product) => {
    let sum = 0
    if (product?.addingCodes) {
      const addingCodes = product.addingCodes
      for (const code of Object.keys(addingCodes)) {
        if (addingCodes[code].amountInTXT) sum += Number(addingCodes[code].amountInTXT)
        if (addingCodes[code].sumOfSelectedCodesInFiles)
          sum += Number(addingCodes[code].sumOfSelectedCodesInFiles)
      }
    }
    return sum
  }

  const onSubmitSave = async (data) => {
    setIsLoading(true)

    // console.log('@Data before transformation AddCodesToOrderProduct', data)
    //flaten structure of added codes from deliveries
    const formatedData = {}
    formatedData.products = []

    //for comparison if all parts were added succesfully
    partsSumRef.current = 0

    for (let product of data.products) {
      if (product?.addingCodes?.length > 0) {
        partsSumRef.current += 1
        formatedData.products.push({
          id: product.id,
          deliveryProducts: [
            ...product.addingCodes.filter(
              (el) => el.amountInTXT > 0 || el.sumOfSelectedCodesInFiles > 0
            ),
          ],
          expectedQuantity: product.expectedQuantity,
          addedCodesSum: countSum(product),
          alreadyAddedCodes: product.codes,
        })
      }
    }

    // console.log('@Data after transformation', formatedData)

    let shouldExit = false
    formatedData.products.forEach((product) => {
      product.deliveryProducts.forEach((el) => {
        el.deliveryProductId = el.id
        if (!el.codesInTXT) el.codesInTXT = 0
      })
      if (product.addedCodesSum > product.expectedQuantity) {
        notifyError(`Błąd! Dodano więcej kodów niż oczekiwano (${product.name})`)
        shouldExit = true
        return
      }
    })

    if (shouldExit) {
      setIsLoading(false)
      return
    }
    //trigger mutation for each product individualy
    formatedData.products.forEach((product) => {
      if (product.addedCodesSum > 0) {
        console.log(product)
        product?.deliveryProducts.forEach((el) => (el.amountInTXT = parseInt(el.amountInTXT)))
        addCodesToOrderProductMutation(product)
      }
    })
    if (partsSumRef.current === 0) {
      setIsLoading(false)
      notifyError('Brak kodów do dodania')
    }
  }

  return (
    <>
      <CContainer fluid className="h-100">
        {isLoading && <IsLoadingOverlay />}
        <CRow>
          <CCol className="d-flex flex-row justify-content-between pb-4">
            <h2>Przypisz kody do zamówienia JK-ORDER-{String(id).padStart(5, '0')}</h2>

            <div>
              <EditButton label="Zapisz" onClick={handleSubmit((data) => onSubmitSave(data))} />
              <CancelButton label="Anuluj" onClick={() => navigate(`/zamowienie/${id}`)} />
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol lg={3}>
            <CCard>
              <CCardHeader>Dane Zamówienia</CCardHeader>
              <CCardBody>
                <CRow className="mb-3">
                  <BasicList listFields={listFields} data={data} />
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol lg={9}>
            <CCard className="bg-transparent">
              <CCardBody className="px-0 py-2">
                {/* {console.log(watch())} */}
                <AddOrderProductWrapper
                  register={register}
                  unregister={unregister}
                  watch={watch}
                  setValue={setValue}
                  disabled={true}
                />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default OrderEdit
