const formFields = () => {
  return [
    [
      {
        id: 'code',
        label: 'KOD',
        category: '',
        placeholder: '',
        type: 'text',
        required: true,
      },
    ],
  ]
}

export default formFields
