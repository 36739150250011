import {
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CRow,
} from '@coreui/react-pro'
import { ErrorMessage } from '@hookform/error-message'
import React from 'react'
import DynamicDropdownInput from './DynamicDropdownInput'
import { useHasAccess } from 'src/helpers'
import DynamicDropdownInputMulti from './DynamicDropdownInputMulti'

const BasicForm = ({
  formFields,
  onSubmit,
  register,
  errors,
  setValue,
  watch,
  disabled = false,
}) => {
  let breakpoints = []
  if (formFields.length === 1) breakpoints = [12, 12, 12]
  if (formFields.length === 2) breakpoints = [6, 6, 12]
  if (formFields.length === 3) breakpoints = [4, 6, 12]

  const handleDropdownSelect = (e, id) => {
    console.log(e, id, 'value', e.value)
    if (e?.value) setValue(id, e.value)
    else
      setValue(
        id,
        e.map((el) => el.value)
      )
  }

  const hasAccess = []
  for (let i = 0; i < formFields.length; i++) {
    hasAccess[i] = []
    for (let j = 0; j < formFields[i].length; j++) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const accessResult = useHasAccess(formFields[i][j].access)
      hasAccess[i][j] = accessResult
    }
  }

  return (
    <CForm onSubmit={onSubmit}>
      <CRow>
        {formFields.map((column, columnId) => (
          <CCol key={columnId} lg={breakpoints[0]} sm={breakpoints[1]} xs={breakpoints[2]}>
            {column.map((field, id) => {
              return (
                hasAccess[columnId][id] && (
                  <div key={id}>
                    {field.id ? (
                      <>
                        {Array.isArray(field.type) && Array.isArray(field.type[0]) ? (
                          <>
                            <CFormLabel className="mb-3 mt-0" htmlFor={field.id}>
                              {field.label}
                            </CFormLabel>
                            <CFormSelect
                              id={field.id}
                              required={field.required}
                              {...register(`${field.category}.${field.id}`, {
                                validate: field.validate,
                                valueAsNumber: field.valueAsNumber,
                              })}
                              disabled={disabled}
                              className="mb-3"
                            >
                              {/* <option>wybierz...</option> */}
                              {field.type.map((option) => (
                                <option key={option[0]} value={option[0]}>
                                  {option[1]}
                                </option>
                              ))}
                            </CFormSelect>
                          </>
                        ) : Array.isArray(field.type) ? (
                          <>
                            <CFormLabel className="mb-3 mt-0" htmlFor={field.id}>
                              {field.label}
                            </CFormLabel>
                            <CFormSelect
                              id={field.id}
                              required={field.required}
                              {...register(`${field.category}.${field.id}`, {
                                validate: field.validate,
                                valueAsNumber: field.valueAsNumber,
                              })}
                              className="mb-3"
                              disabled={disabled}
                            >
                              {field.type.map((option, id) => (
                                <option key={id} value={option}>
                                  {option}
                                </option>
                              ))}
                            </CFormSelect>
                          </>
                        ) : field.type === 'dynamicDropdown' ? (
                          <>
                            {/* {console.log(watch[`${field.category}${field.id}`])} */}
                            <DynamicDropdownInput
                              multiple={true}
                              label={field.label}
                              id={`${field.category}.${field.id}`}
                              endpoint={field.endpoint}
                              nameField={field.nameField}
                              onChange={handleDropdownSelect}
                              initValue={
                                field.initValue
                                  ? watch(field.initValue.join('.'))
                                  : watch()[`${field.category}${field.id}`]
                              }
                              disabled={disabled}
                              required={field.required}
                            />
                          </>
                        ) : field.type === 'dynamicDropdownMulti' ? (
                          <>
                            <DynamicDropdownInputMulti
                              multiple={true}
                              label={field.label}
                              id={`${field.category}.${field.id}`}
                              endpoint={field.endpoint}
                              nameField={field.nameField}
                              onChange={handleDropdownSelect}
                              initValue={
                                field.initValue
                                  ? watch(field.initValue.join('.'))
                                  : watch(`${field.id}`)
                              }
                              disabled={disabled}
                              required={field.required}
                            />
                          </>
                        ) : field.type === 'textarea' ? (
                          <>
                            <CFormLabel className="mb-3 mt-0" htmlFor={field.id}>
                              {field.label}
                            </CFormLabel>
                            <CFormTextarea
                              id={field.id}
                              placeholder={field.placeholder}
                              required={field.required}
                              {...register(`${field.category}.${field.id}`, {
                                validate: field.validate,
                              })}
                              className="mb-3 fs-5"
                              rows={field.rows}
                              disabled={disabled}
                            />
                          </>
                        ) : field.type === 'select' ? (
                          <>
                            <CFormCheck
                              id={field.id}
                              label={field.label}
                              className="mb-3 fs-5"
                              {...register(`${field.category}.${field.id}`)}
                            />
                          </>
                        ) : (
                          <>
                            <CFormLabel className="mb-3 mt-0" htmlFor={field.id}>
                              {field.label}
                            </CFormLabel>
                            <CFormInput
                              type={field.type}
                              id={field.id}
                              placeholder={field.placeholder}
                              required={field.required}
                              {...register(`${field.category}.${field.id}`, {
                                validate: field.validate,
                                valueAsNumber: field.valueAsNumber,
                              })}
                              className="mb-3 fs-5"
                              disabled={disabled}
                              min={0}
                              max={field.max}
                              step={field.step ? field.step : ''}
                            />
                          </>
                        )}
                        {field.description && (
                          <div
                            className="text-small mb-4"
                            style={{ marginTop: '-13px', color: 'darkgray' }}
                          >
                            {field.description}
                          </div>
                        )}
                        <span className="text-danger">
                          {errors && (
                            <ErrorMessage errors={errors} name={`${field.category}.${field.id}`} />
                          )}
                        </span>

                        {/* <CFormFeedback>{errors[`${field.category}.${field.id}`].message}</CFormFeedback> */}
                      </>
                    ) : (
                      <span className="fw-semibold">{field.subtitle ? field.subtitle : ''} </span>
                    )}
                  </div>
                )
              )
            })}
          </CCol>
        ))}
      </CRow>
      <CFormInput
        type="submit"
        id="submit-form"
        className="d-none hidden"
        disabled={disabled}
      ></CFormInput>
    </CForm>
  )
}
export default BasicForm
