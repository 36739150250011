const formFields = [
  [
    {
      id: 'contractor',
      category: '',
      label: 'Dostawca',
      placeholder: 'np. Firma',
      type: 'dynamicDropdown',
      endpoint: 'contractors',
      nameField: 'name',
      required: true,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
    {
      id: 'invoiceNumber',
      category: '',
      label: 'Numer faktury',
      placeholder: 'np. FV123',
      type: 'text',
      required: true,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
    {
      id: 'currency',
      category: '',
      label: 'Waluta',
      placeholder: 'EUR',
      type: [
        ['EUR', 'EUR'],
        ['PLN', 'PLN'],
        ['USD', 'USD'],
        ['GBP', 'GBP'],
      ],
      required: true,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
    {
      id: 'dateOfOrder',
      category: '',
      label: 'Data zamówienia',
      placeholder: 'np. FIFA',
      type: 'date',
      required: true,
      access: ['ROLE_ACCOUNT_MANAGER'],
      // valueAsNumber: true,
    },
    // {
    //   id: 'status',
    //   category: '',
    //   label: 'Status',
    //   placeholder: '---',
    //   type: ['NOWE', 'OTRZYMANE', 'ANULOWANE'],
    //   required: true,
    // },
    {
      id: 'isPaid',
      category: '',
      label: 'Status opłaty',
      placeholder: '---',
      type: [
        ['false', 'NIEOPŁACONE'],
        ['true', 'OPŁACONE'],
      ],
      required: true,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
  ],
]

export default formFields
