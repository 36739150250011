import { cilCopy } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CRow } from '@coreui/react-pro'
import React from 'react'
import { navigationStylingProps } from 'src/assets/custom/tableSorting'
import GetImage from 'src/components/GetImage'
import { copyToClipboard } from 'src/helpers'

const columns = [
  {
    key: 'id',
    label: 'ID',
    _style: { width: '50px' },
  },
  {
    key: 'file',
    label: 'Ikona produktu',
    _style: { width: '110px' },
    sorter: false,
  },
  {
    key: 'name',
    label: 'Produkt',
    _style: { minWidth: '350px' },
  },
  {
    key: 'amount',
    label: 'Liczba na stanie',
  },
  {
    key: 'meanPrice',
    label: 'Uśr. cena zakupu',
  },
  {
    key: 'total',
    label: 'Wartość produktu',
    adminAccess: true,
  },
]

export const tableProps = (navigate = null, isAdmin = false) => {
  const hasAccesColumns = columns.filter((column) => {
    if (column.adminAccess && !isAdmin) return false
    return true
  })

  return {
    columns: hasAccesColumns,
    sorterValue: { column: 'amount', state: 'desc' },
    scopedColumns: {
      name: ({ name }) => (
        <td>
          {name}{' '}
          <CIcon
            className="text-primary"
            icon={cilCopy}
            height={'14px'}
            onClick={(e) => {
              e.stopPropagation()
              copyToClipboard(name)
            }}
          />
        </td>
      ),
      // companyName: ({ contractorDetails: { companyName } }) => <td>{companyName}</td>,
      // properName: ({ contractorDetails: { properName } }) => <td>{properName}</td>,
      file: ({ file, id }) => (
        <td className="p-1">
          <CRow>
            <div className="mx-auto w-auto" style={{ height: '80px' }}>
              <GetImage id={['product', id, 'cover']} path={file} />
            </div>
          </CRow>
        </td>
      ),
      meanPrice: (product) => {
        return <td>{(product.meanPrice / 100).toFixed(2)} EUR</td>
      },
      total: ({ total, currency }) => {
        return <td>{(total / 100).toFixed(2) + ' EUR'}</td>
      },
    },
    onRowClick: (e) => {
      navigate(`/produkt/${e.id}`)
    },
    clickableRows: true,
    columnSorter: true,

    tableProps: {
      striped: true,
      hover: true,
    },
    ...navigationStylingProps,
  }
}
