import axios from '../../axios'
import { notifyError, notifySucces } from '../notify'

const ExternalApiService = {
  kalixo: {
    getProducts: () => async () => {
      return await axios
        .get(`/api/external-products/preview`)
        .then((res) => {
          return res.data
        })
        .catch((e) => {
          notifyError(e)
          throw e
        })
    },
    relate: () => async (data) => {
      return await axios
        .post(`/api/external-products/${data.ownProduct}/relate`, data.sourceProduct)
        .then((res) => {
          notifySucces('Produkt został przypisany')
          return res.data
        })
        .catch((e) => {
          notifyError(e.response.data.error)
          throw e
        })
    },
    editRelation: (id) => async (data) => {
      return await axios
        .post(`/api/external-products/${id}/settings`, data)
        .then((res) => {
          notifySucces('Produkt został zaktualizowany')
          return res.data
        })
        .catch((e) => {
          notifyError(e)
          throw e
        })
    },
  },
}

export default ExternalApiService
