import React from 'react'

const CancelButton = ({ onClick = null, label = 'Anuluj', className }) => {
  return (
    <label
      tabIndex="0"
      className={'btn btn-outline-danger fw-semibold px-5 pt-1 pb-1 ms-2 ' + className}
      onClick={onClick}
    >
      {label}
    </label>
  )
}

export default CancelButton
