import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormLabel,
  CListGroup,
  CListGroupItem,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import React from 'react'

import { cilCheckCircle, cilXCircle } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

const AddCodesSummary = ({ id, data }) => {
  return (
    <>
      {data && (
        <>
          <CRow className="px-3 pt-1 h5 mb-3 ">
            {/* <DynamicDropdownInput
          label={`Produkt ID nr ${id}`}
          id={`products.${id}.product`}
          endpoint={'products'}
          disabled={true}
        /> */}
            <span>
              {console.log(data)}
              {data.name}
            </span>
          </CRow>

          <CCard className="mb-4">
            <CCardHeader>
              <CNav variant="tabs" className="card-header-tabs">
                <CNavItem>
                  <CNavLink href="#" active={true}>
                    Podsumowanie Produktu
                  </CNavLink>
                </CNavItem>
              </CNav>
            </CCardHeader>
            <CCardBody>
              <CTabContent>
                <CTabPane role="tabpanel" aria-labelledby="orders" visible={true}>
                  <CFormLabel className="mb-2 mt-2">Dodane kody tekstowe:</CFormLabel>
                  <CListGroup className="mb-4 ps-3">
                    {!data.codes && 'brak'}
                    {data?.codes &&
                      data?.codes?.length > 0 &&
                      data.codes.map((code, id) => (
                        <CListGroupItem
                          key={id}
                          className={code.error ? 'border border-danger' : ''}
                        >
                          <CRow className="px-2">
                            <CCol xs={6} className="px-0">
                              {code.code}
                            </CCol>
                            <CCol xs={1} className="px-0">
                              {code?.lines}
                            </CCol>
                            <CCol xs={1} className="px-0">
                              {!code.error ? (
                                <CIcon icon={cilCheckCircle} className="text-success" />
                              ) : (
                                <CIcon icon={cilXCircle} className="text-danger" />
                              )}
                            </CCol>
                            <CCol xs={4} className="px-0 overflow-hidden">
                              <CBadge color="danger">{code?.error}</CBadge>
                            </CCol>
                          </CRow>
                        </CListGroupItem>
                      ))}
                  </CListGroup>
                  <CFormLabel className="mb-2 mt-2">Dodane plik graficzne:</CFormLabel>
                  <CListGroup className="mb-3 ps-3">
                    {!data.files && 'brak'}
                    {data?.files &&
                      data?.files?.length > 0 &&
                      data.files.map((file, id) => (
                        <CListGroupItem key={id}>
                          <CRow className="px-2">
                            <CCol xs={11} className="px-0">
                              {file.name}
                            </CCol>
                            <CCol xs={1} className="px-0">
                              {file.status === 'succes' ? (
                                <CIcon
                                  color="success"
                                  icon={cilCheckCircle}
                                  className="text-success"
                                />
                              ) : (
                                <CIcon color="danger" icon={cilXCircle} className="text-danger" />
                              )}
                            </CCol>
                          </CRow>
                        </CListGroupItem>
                      ))}
                  </CListGroup>
                </CTabPane>
              </CTabContent>
            </CCardBody>
          </CCard>
        </>
      )}
    </>
  )
}

export default AddCodesSummary
