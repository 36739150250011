const listFields = [
  {
    id: 'companyName',
    category: 'contractorDetails',
    label: 'NAZWA FIRMY',
    placeholder: '-',
  },
  {
    id: 'properName',
    category: 'contractorDetails',
    label: 'NAZWA WŁASNA',
    placeholder: '-',
  },
  {
    id: 'vatNumber',
    category: 'contractorDetails',
    label: 'NUMER VAT',
    placeholder: '-',
  },
  { id: '' },
  {
    id: 'street',
    category: 'contractorAddress',
    label: 'ULICA',
    placeholder: '-',
  },
  {
    id: 'city',
    category: 'contractorAddress',
    label: 'MIASTO',
    placeholder: '-',
  },
  {
    id: 'zipCode',
    category: 'contractorAddress',
    label: 'KOD POCZTOWY',
    placeholder: '-',
  },
  {
    id: 'country',
    category: 'contractorAddress',
    label: 'KRAJ',
    placeholder: '-',
  },
  { id: '' },
  {
    id: 'skypeId',
    category: 'contractorContact',
    label: 'SKYPE ID',
    placeholder: '-',
  },
  {
    id: 'webPage',
    category: 'contractorContact',
    label: 'STRONA WWW',
    placeholder: '-',
  },
  {
    id: 'orderMail',
    category: 'contractorContact',
    label: 'EMAIL DO ZAMÓWIEŃ',
    placeholder: '-',
  },
  {
    id: 'invoiceMail',
    category: 'contractorContact',
    label: 'EMAIL DO FV',
    placeholder: '-',
  },
  {
    id: 'contactMail',
    category: 'contractorContact',
    label: 'EMAIL DO KONTAKTU',
    placeholder: '-',
  },
  {
    id: 'name',
    category: 'supervisor',
    label: 'OPIEKUN',
    placeholder: '-',
  },
  {
    id: 'note',
    category: 'contractorDetails',
    label: 'NOTATKA',
    placeholder: '-',
  },
]

export default listFields
