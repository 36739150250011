import React, { useEffect, useState } from 'react'
import {
  CButton,
  CCard,
  CCol,
  CContainer,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CRow,
  CSmartTable,
} from '@coreui/react-pro'
import { useQuery, useQueryClient } from 'react-query'
import { tableProps } from './DeliveriesTable'
import { useNavigate } from 'react-router-dom'
import DataService from 'src/services/DataService'
import { useForm } from 'react-hook-form'
import DynamicDropdownInput from 'src/components/DynamicDropdownInput'
import { useRef } from 'react'
import IsLoading from 'src/components/IsLoading'
import { paginationProps } from 'src/assets/custom/tableSorting'
import IsLoadingOverlay from 'src/components/IsLoadingOverlay'
// import { flattenDataStructure } from 'src/helpers'

const Deliveries = () => {
  const navigate = useNavigate()
  const [filter, setFilter] = useState()
  const [reset, setReset] = useState()

  const [activePage, setActivePage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [sortBy, setSortBy] = useState()
  const [sortDir, setSortDir] = useState()

  const [prevVals, setPrevVals] = useState()

  const {
    data,
    // error,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery(
    ['deliveries', activePage, itemsPerPage, sortBy, sortDir],
    DataService.deliveries.getDeliveries({
      ...filter,
      page: activePage,
      perPage: itemsPerPage,
      sortBy,
      sortDir,
    }),
    { keepPreviousData: true }
  )

  const { watch, register, setValue } = useForm({
    defaultValues: {
      status: '',
      isPaid: null,
      contractor: '',
      user: '',
      valueMin: '',
      valueMax: '',
      dateFrom: '',
      dateTo: '',
    },
  })

  const currVals = watch()

  useEffect(() => {
    if (JSON.stringify(currVals) !== JSON.stringify(prevVals)) {
      console.log('filter changed', currVals, prevVals)
      setPrevVals({ ...currVals })
      setFilter(currVals)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currVals, prevVals])

  const timeoutRef = useRef()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (isRefetching) queryClient.cancelQueries({ queryKey: 'deliveries' })

    if (timeoutRef.current) clearTimeout(timeoutRef.current)

    timeoutRef.current = setTimeout(() => {
      refetch()
    }, 500)

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
    // eslint-disable-next-line
  }, [filter])

  useEffect(() => {
    refetch()
  }, [activePage, itemsPerPage, sortBy, sortDir, refetch])

  useEffect(() => {
    if (data?.data.pages < activePage) setActivePage(1)
  }, [data, activePage])

  if (isLoading) {
    return <IsLoading />
  }
  if (!data) {
    return 'błąd'
  }

  const handleDropdownClear = (id) => {
    console.log('clearing dropdown')
    console.log(id)
    setValue(id, null, { shouldTouch: true })
    setReset((prev) => prev + 1)
    console.log(watch())
  }

  const handleDropdownSelect = (e, id) => {
    if (e?.value) setValue(id, e.value)
  }

  return (
    <>
      <CContainer fluid>
        <CRow>
          <CCol className="d-flex justify-content-between pb-1">
            <h2>Dostawy</h2>
            <CButton
              onClick={() => {
                navigate('/dostawa/new')
              }}
              color="primary"
              className="fw-semibold px-4"
            >
              + Dodaj dostawę
            </CButton>
          </CCol>
        </CRow>
        <CRow xs={{ gutterX: 3 }}>
          <CCol lg={3} className="align-self-start">
            <CCard className="mt-3 overflow-hidden px-3 fill h-100">
              <CForm>
                <CRow className="mb-2">
                  <CFormLabel className="mt-3" htmlFor="status">
                    Status dostawy:
                  </CFormLabel>
                </CRow>
                <CRow className="px-2">
                  <CCol xs={6}>
                    <CRow>
                      <CFormCheck
                        button={{ color: 'primary', variant: 'outline' }}
                        {...register('status')}
                        inline
                        type="radio"
                        name="status"
                        id="status1"
                        value="NOWE"
                        label="Nowe"
                        // defaultChecked
                      />
                    </CRow>
                  </CCol>
                  <CCol xs={6}>
                    <CRow>
                      <CFormCheck
                        button={{ color: 'primary', variant: 'outline' }}
                        {...register('status')}
                        inline
                        type="radio"
                        name="status"
                        id="status2"
                        value="OTRZYMANE"
                        label="Otrzymane"
                      />
                    </CRow>
                  </CCol>
                </CRow>
                <CRow className="px-2 mb-3">
                  <CCol xs={6}>
                    <CRow>
                      <CFormCheck
                        button={{ color: 'primary', variant: 'outline' }}
                        {...register('status')}
                        inline
                        type="radio"
                        name="status"
                        id="status3"
                        value="ANULOWANE"
                        label="Anulowane"
                      />
                    </CRow>
                  </CCol>
                  <CCol>
                    <CRow>
                      <CFormCheck
                        button={{ color: 'primary', variant: 'outline' }}
                        {...register('status')}
                        inline
                        type="radio"
                        name="status"
                        id="status4"
                        value=""
                        label="Wszystkie"
                      />
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <CFormLabel className="mb-3" htmlFor="paymentStatus">
                    Status płatności:
                  </CFormLabel>
                </CRow>
                <CRow className="px-2 mb-3">
                  <CCol xl={4} className="">
                    <CRow>
                      <CFormCheck
                        className=""
                        button={{ color: 'primary', variant: 'outline' }}
                        {...register('isPaid')}
                        inline
                        type="radio"
                        name="isPaid"
                        id="isPaid1"
                        value=""
                        label="Wszystkie"
                        // defaultChecked
                      />
                    </CRow>
                  </CCol>
                  <CCol xl={4} className="">
                    <CRow>
                      <CFormCheck
                        className="px-0"
                        button={{ color: 'primary', variant: 'outline' }}
                        {...register('isPaid')}
                        inline
                        type="radio"
                        name="isPaid"
                        id="isPaid2"
                        value="false"
                        label="Nieopłacone"
                      />
                    </CRow>
                  </CCol>
                  <CCol xl={4}>
                    <CRow>
                      <CFormCheck
                        className="px-0"
                        button={{ color: 'primary', variant: 'outline', className: 'p-0' }}
                        {...register('isPaid')}
                        inline
                        type="radio"
                        name="isPaid"
                        id="isPaid3"
                        value="true"
                        label="Opłacone"
                      />
                    </CRow>
                  </CCol>
                </CRow>
                {/* <CRow className="px-2">
                  <CFormLabel className="mt-3" htmlFor="partner">
                    Dostawca:
                  </CFormLabel>
                </CRow> */}
                <CRow className="px-2">
                  <DynamicDropdownInput
                    key={reset}
                    label={'Dostawca:'}
                    id={'contractor'}
                    endpoint={'contractors'}
                    nameField={'name'}
                    onChange={handleDropdownSelect}
                    clear={true}
                    onClear={handleDropdownClear}
                  />
                </CRow>

                <CRow className="px-2">
                  <DynamicDropdownInput
                    key={reset}
                    label={'Autor:'}
                    id={'user'}
                    endpoint={'users'}
                    nameField={'name'}
                    onChange={handleDropdownSelect}
                    clear={true}
                    onClear={handleDropdownClear}
                  />
                </CRow>
                <CRow className="ps-2">
                  <CFormLabel className="mb-3 px-0" htmlFor="valueMin">
                    Wartość:
                  </CFormLabel>
                </CRow>
                <CRow className="px-0" xs={{ gutterX: 3 }}>
                  <CCol xs={6} className="px-1">
                    <CFormInput
                      type="number"
                      name="valueMin"
                      {...register('valueMin')}
                      min={0}
                    ></CFormInput>
                  </CCol>
                  <CCol xs={6} className="px-1">
                    <CFormInput
                      type="number"
                      name="valueMax"
                      {...register('valueMax')}
                      min={0}
                    ></CFormInput>
                  </CCol>
                </CRow>
                <CRow className="ps-2">
                  <CFormLabel className="mb-3 mt-3 px-0" htmlFor="kod">
                    Data (od-do):
                  </CFormLabel>
                </CRow>
                <CRow className="px-2 mb-3">
                  <CCol xs={6} className="px-1">
                    <CFormInput type="date" name="dateFrom" {...register('dateFrom')}></CFormInput>
                  </CCol>
                  <CCol xs={6} className="px-1">
                    <CFormInput type="date" name="dateTo" {...register('dateTo')}></CFormInput>
                  </CCol>
                </CRow>
                <CRow className="ps-2">
                  <CFormLabel className="mb-3 mt-3 px-0" htmlFor="kod">
                    Numer FV:
                  </CFormLabel>
                </CRow>
                <CRow className="px-2 mb-3">
                  <CFormInput
                    type="text"
                    name="invoiceNumber"
                    {...register('invoiceNumber')}
                  ></CFormInput>
                </CRow>
              </CForm>
            </CCard>
          </CCol>
          <CCol lg={9}>
            <CCard className="bg-transparent">
              {isRefetching && <IsLoadingOverlay />}
              <CSmartTable
                loading={isLoading}
                items={data?.data?.results}
                itemsPerPageSelect
                itemsPerPage={itemsPerPage}
                pagination={{ external: true }}
                paginationProps={{
                  activePage: activePage,
                  pages: data.data.pages || 1,
                  ...paginationProps,
                }}
                onActivePageChange={setActivePage}
                onItemsPerPageChange={(value) => {
                  setActivePage(1)
                  setItemsPerPage(value)
                }}
                onSorterChange={(value) => {
                  console.log(value)
                  setSortBy(value.column)
                  setSortDir(value.state)
                }}
                {...tableProps(navigate)}
              />
            </CCard>
            {/* )} */}
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default Deliveries
