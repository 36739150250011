import { CModal, CModalBody, CModalFooter } from '@coreui/react-pro'
import React from 'react'
import { useForm } from 'react-hook-form'
import BasicForm from 'src/components/BasicForm'
import formFields from './modifyReserveFlashOfferForm'
import IsLoadingOverlay from 'src/components/IsLoadingOverlay'
import { useMutation } from 'react-query'
import DataService from 'src/services/DataService'
import { notifyError, notifySucces } from 'src/services/notify'
import EditButton from 'src/components/EditButton'
import CancelButton from 'src/components/CancelButton'
import { useStoreState } from 'easy-peasy'

const ModifyReserveFlashOfferModal = ({ visible, setVisible, initData, refetch, maxToReserve }) => {
  const { register, handleSubmit, watch, setValue } = useForm({
    values: initData,
  })

  const isAdmin = useStoreState((state) => state.auth.isAdmin)
  const { status } = watch()

  const {
    mutate: reserveFlashOfferMutation,
    isLoading,
    error,
    reset,
  } = useMutation(DataService.flashOffers.editReserveFlashOffer(), {
    onSuccess: () => {
      setVisible(false)
      notifySucces('Rezerwacja została zapisana')
      reset()
      refetch()
    },
    onError: (e) => {
      console.log(error)
      notifyError(e)
    },
  })

  const onSubmit = async (data) => {
    reserveFlashOfferMutation({
      data: {
        ...data,
        contractor: data.contractor.id,
      },
      reserveId: data.id,
    })
  }

  return (
    <>
      <CModal
        visible={visible}
        //   onClose={() => setVisible(false)}
      >
        {isLoading && <IsLoadingOverlay />}
        <CModalBody className="pt-4">
          <div className="h5 mb-4">Edycja rezerwacji oferty:</div>
          {/* {console.log('modify form fields: ', formFields(maxToReserve))} */}
          <BasicForm
            formFields={formFields(status === 'POTWIERDZONE' ? maxToReserve : 2147483647, isAdmin)}
            onSubmit={handleSubmit((data) => onSubmit(data))}
            register={register}
            watch={watch}
            setValue={setValue}
          />
        </CModalBody>
        <CModalFooter>
          <EditButton label={'Edytuj Rezerwację'} />
          <CancelButton onClick={() => setVisible(false)} />
        </CModalFooter>
      </CModal>
    </>
  )
}

export default ModifyReserveFlashOfferModal
