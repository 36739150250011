import React from 'react'

const ProductCodesSummary = ({ data }) => {
  // console.log(data)
  if (data?.id !== 'empty')
    return (
      <div className="mb-4">
        <div className="mb-2">
          <span className="h5 mb-3 pb-4">{data?.product?.name || data?.name}</span>
        </div>
        <div className="d-flex justify-content-between">
          <span>Cena kodu</span>
          <span className="fw-bolder">
            {data?.purchasePrice ? data.purchasePrice.toFixed(2) : '-'}
          </span>
        </div>
        <div className="d-flex justify-content-between">
          <span>Oczekiwana liczba kodów</span>
          <span className="fw-bolder">{data?.expectedQuantity ? data.expectedQuantity : '-'}</span>
        </div>
        <div className="d-flex justify-content-between">
          <span>Dodana liczba kodów</span>
          <span className="fw-bolder">
            {data?.amount
              ? data.amount
              : 0 +
                  (data?.codes ? data.codes.split('\n').length : 0) +
                  (data?.codesInFile?.length > 0
                    ? data?.codesInFile?.reduce((acc, val) => acc + val)
                    : 0) || '-'}
          </span>
        </div>

        <div className="mb-3 mt-3">
          <div>
            <span className="h6">Kody tekstowe:</span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Liczba kodów</span>
            <span className="fw-bolder">{data?.codes?.split('\n').length || '-'}</span>
          </div>
          {/* <div className="d-flex justify-content-between">
            <span>Cena kodu</span>
            <span className="fw-bolder">
              {data?.codes?.split('\n').length && data.purchasePrice
                ? data.purchasePrice.toFixed(2)
                : '-'}
            </span>
          </div> */}
          {/* <div className="d-flex justify-content-between">
            <span>Wartość</span>
            <span className="fw-bolder">
              {data?.codes?.split('\n').length && data.purchasePrice
                ? (data.purchasePrice * data?.codes?.split('\n').length).toFixed(2)
                : '-'}
            </span>
          </div> */}
        </div>
        <div className="mb-3">
          <div>
            <span className="h6">Kody graficzne:</span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Liczba załączników</span>
            <span className="fw-bolder">{data?.files?.length || '-'}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Suma kodów w załącznikach</span>
            <span className="fw-bolder">
              {data?.codesInFile?.length > 0
                ? data?.codesInFile?.reduce((acc, val) => acc + val)
                : '-'}
            </span>
          </div>
          {/* <div className="d-flex justify-content-between">
            <span>Cena kodu</span>
            <span className="fw-bolder">
              {data.purchasePrice && data?.files?.length ? data.purchasePrice.toFixed(2) : '-'}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Wartość</span>
            <span className="fw-bolder">
              {data?.files?.length && data?.purchasePrice
                ? (
                    data?.purchasePrice * data?.codesInFile?.reduce((acc, val) => acc + val)
                  ).toFixed(2)
                : '-'}
            </span>
          </div> */}
        </div>
      </div>
    )
}

export default ProductCodesSummary
