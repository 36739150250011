import React from 'react'
import { useStoreActions } from 'easy-peasy'
import { toast } from 'react-toastify'

const ErrorMsg = ({ e }) => {
  const logout = useStoreActions((actions) => actions.auth.logoutUser)

  if (typeof e === 'string') return e

  return (
    <div>
      {!!e?.response ? (
        <>
          Request failed, code: {e?.response?.status}
          <br />
          {e?.response?.statusText && (
            <>
              {e?.response?.statusText}
              <br />
            </>
          )}
          {e?.response?.data?.detail && (
            <>
              {e?.response?.data?.detail}
              <br />
            </>
          )}
          {e?.response?.data?.message && (
            <>
              {e.response.data.message} <br />
            </>
          )}
          {e?.response?.status === 401 ? logout : ''}
        </>
      ) : (
        <>{e?.message}</>
      )}
    </div>
  )
}

const SuccessMsg = (msg) => {
  const { e } = msg
  if (typeof e === 'string') return e
  return (
    <div>
      Request failed, status code: {e.response.status} <br />
      {e.response.data.detail && e.response.data.detail}
    </div>
  )
}

export const notifyError = (e) => {
  const notify = () => toast.error(<ErrorMsg e={e} />)
  notify()
}

export const notifySucces = (res) => {
  const notify = (id) => {
    if (!toast.isActive(id))
      toast.success(<SuccessMsg e={res} toastId={id} />, {
        toastId: id,
      })
  }
  notify(res)
}
