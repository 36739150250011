import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormLabel,
  CListGroup,
  CListGroupItem,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import React from 'react'

import { cilCheckCircle, cilXCircle } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

const AddCodesSummary2 = ({ data }) => {
  console.log(data)

  return (
    <>
      {data && (
        <>
          <CRow className="px-3 pt-1 h5 mb-3 ">
            <span></span>
          </CRow>

          <CCard className="mb-4">
            <CCardHeader>
              <CNav variant="tabs" className="card-header-tabs">
                <CNavItem>
                  <CNavLink href="#" active={true}>
                    Podsumowanie Produktu
                  </CNavLink>
                </CNavItem>
              </CNav>
            </CCardHeader>
            <CCardBody>
              <CTabContent>
                <CTabPane role="tabpanel" aria-labelledby="orders" visible={true}>
                  <CFormLabel className="mb-2 mt-2">Kody tekstowe:</CFormLabel>
                  <CListGroup className="mb-4 ps-3">
                    {!data || (data.length === 0 && 'brak')}
                    {data.map((el, id) => (
                      <>
                        {!el.codesInFile && (
                          <CListGroupItem
                            key={id}
                            className={el.error ? 'border border-danger' : ''}
                          >
                            <CRow className="px-2">
                              <CCol xs={5} className="px-0">
                                {el.code}
                              </CCol>
                              <CCol xs={1} className="px-0">
                                {el?.lines}
                              </CCol>
                              <CCol xs={1} className="px-0">
                                {!el.error ? (
                                  <CIcon icon={cilCheckCircle} className="text-success" />
                                ) : (
                                  <CIcon icon={cilXCircle} className="text-danger" />
                                )}
                              </CCol>
                              <CCol xs={5} className="px-0 overflow-hidden">
                                <CBadge color="danger">{el?.error}</CBadge>
                              </CCol>
                            </CRow>
                          </CListGroupItem>
                        )}
                      </>
                    ))}
                  </CListGroup>
                  <CFormLabel className="mb-2 mt-2">Pliki graficzne:</CFormLabel>
                  <CListGroup className="mb-3 ps-3">
                    {!data || (data.length === 0 && 'brak')}
                    {data.map((file, id) => (
                      <>
                        {/* {file.codesInFile && (
                          <CListGroupItem key={id}>
                            <CRow className="px-2">
                              <CCol xs={11} className="px-0">
                                {file.name}
                              </CCol>
                              <CCol xs={1} className="px-0">
                                {file.status === 'succes' ? (
                                  <CIcon
                                    color="success"
                                    icon={cilCheckCircle}
                                    className="text-success"
                                  />
                                ) : (
                                  <CIcon color="danger" icon={cilXCircle} className="text-danger" />
                                )}
                              </CCol>
                            </CRow>
                          </CListGroupItem>
                        )} */}
                      </>
                    ))}
                  </CListGroup>
                </CTabPane>
              </CTabContent>
            </CCardBody>
          </CCard>
        </>
      )}
    </>
  )
}

export default AddCodesSummary2
