import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import DataService from 'src/services/DataService'
import ValidatedCodesList from './ValidatedCodesList'
import AddedFileList from './AddedFileList'

import CIcon from '@coreui/icons-react'
import { cilTrash } from '@coreui/icons'

const AddProductCodes = ({
  register,
  watch,
  setValue,
  reset,
  product,
  index, //as in products array
  disabled,
}) => {
  const [step, setStep] = useState(0)
  const [activeKey, setActiveKey] = useState(0)
  const deliveryProduct = watch().products[index]

  const [verifiedCodes, setVerifiedCodes] = useState()

  // eslint-disable-next-line no-unused-vars
  const [_, setDeliveryContainerId] = useState()

  const handleProductRemove = () => {
    watch().products.splice(index, 1)
    reset()
  }

  const handleCodesInputChange = (e) => {
    setValue(`products.${index}.codes`, e.target.value)
  }

  const { mutate: codesVerificationMutation } = useMutation(
    DataService.deliveries.verifyDeliveryCodes2(),
    {
      onSuccess: (res) => {
        console.log('@verification succeded: ', res)
        if (Array.isArray(res.data)) {
          setVerifiedCodes(() => res.data)
          setStep(1)
        } else console.log('verification data is not array')
      },
    }
  )
  const handleCodesVerification = () => {
    codesVerificationMutation({
      productId: product?.product?.id || product.id,
      data: {
        codes: product.codes,
        currency: 'EUR',
      },
    })
  }

  const handleCodesFormat = () => {
    let codesString = product.codes
    codesString = codesString.replace(/,|;/g, '\n')
    codesString = codesString.replace(/\s{2,}/g, '\n')
    codesString = codesString.replace(/[\n\r]+/g, '\n')
    codesString = codesString.replace(/[\n]+$/g, '')
    //emptyline
    codesString = codesString.replace(/^\s/gm, '')

    setValue(`products.${index}.codes`, codesString)
  }

  if (!product) return ''

  return (
    <>
      <CRow className="px-3 pt-1 h5 mb-3 position-relative">
        <span>
          {/* {console.log(product)} */}
          {product.name || product.product.name}
        </span>

        {!disabled && (
          <div
            className="position-absolute top-0 end-0 me-5 btn shadow-none"
            onClick={handleProductRemove}
            style={{ width: '40px', height: '40px' }}
          >
            <CIcon
              icon={cilTrash}
              className="text-danger"
              style={{ width: '20px', height: '20px' }}
            />
          </div>
        )}
      </CRow>
      <CCard className="mb-5">
        <CCardHeader>
          <CNav variant="tabs" className="card-header-tabs">
            <CNavItem>
              <CNavLink active={activeKey === 0} onClick={() => setActiveKey(0)}>
                Tekstowe
              </CNavLink>
            </CNavItem>

            <CNavItem>
              <CNavLink active={activeKey === 1} onClick={() => setActiveKey(1)}>
                Pliki graficzne
              </CNavLink>
            </CNavItem>
          </CNav>
        </CCardHeader>
        <CCardBody>
          <CTabContent>
            <CRow className="px-0 mt-0 mb-3">
              <CCol xs={6}>
                <CFormLabel className="px-0">Cena zakupu</CFormLabel>
                <CFormInput
                  type={'number'}
                  id={'purchasePrice'}
                  placeholder={'Podaj cenę'}
                  step={0.01}
                  min={0}
                  required={true}
                  disabled={disabled}
                  {...register(`products.${index}.purchasePrice`, {
                    required: true,
                    valueAsNumber: true,
                  })}
                  className="mb-1 fs-5"
                ></CFormInput>
              </CCol>
              <CCol xs={6}>
                <CFormLabel className="px-0">Ilość</CFormLabel>
                <CFormInput
                  type={'number'}
                  id={'expectedQuantity'}
                  placeholder={'Ilość'}
                  min={0}
                  required={true}
                  disabled={disabled}
                  {...register(`products.${index}.expectedQuantity`, {
                    required: true,
                    valueAsNumber: true,
                  })}
                  className="mb-1 fs-5"
                ></CFormInput>
              </CCol>
            </CRow>
            <CRow className="mb-2">
              <CCol xs={6}>
                <CFormCheck
                  label="Dostępne w sklepie"
                  id={'availableForSale'}
                  disabled={disabled}
                  // placeholder={false}
                  {...register(`products.${index}.availableForSale`)}
                  className="mb-1 fs-5"
                ></CFormCheck>
                {!!deliveryProduct.availableForSale && (
                  <>
                    <CFormLabel className="px-0">Prowizja (%)</CFormLabel>
                    <CFormInput
                      type={'number'}
                      id={'sellerMargin'}
                      placeholder={'0'}
                      step={0.1}
                      min={0}
                      required={!!deliveryProduct.availableForSale}
                      disabled={disabled}
                      {...register(`products.${index}.sellerMargin`, {
                        valueAsNumber: true,
                      })}
                      className="mb-1 fs-5"
                    ></CFormInput>
                  </>
                )}
              </CCol>
            </CRow>
            <CTabPane
              role="tabpanel"
              className="mb-2"
              aria-labelledby="orders"
              visible={activeKey === 0}
            >
              {step === 0 ? (
                <>
                  <CFormLabel className="mb-2 mt-2" htmlFor={`products.${index}.codes`}>
                    Wprowadź kody tekstowe
                    <br />
                    <small className="text-white-50">
                      Każdy kod musi znajdować się w oddzielnej linijce.
                    </small>
                  </CFormLabel>
                  <CFormTextarea
                    value={product.codes || ''}
                    spellCheck="false"
                    rows={10}
                    name={`products.${index}.codes`}
                    id={`products.${index}.codes`}
                    className="mb-1"
                    onChange={(e) => handleCodesInputChange(e)}
                  />
                  <CRow className="pt-2">
                    <CCol>
                      <CRow className="px-2">
                        <CButton
                          color="primary"
                          onClick={() => setDeliveryContainerId(handleCodesFormat)}
                        >
                          Formatuj
                        </CButton>
                      </CRow>
                    </CCol>
                    <CCol>
                      <CRow className="px-2">
                        <CButton color="primary" onClick={handleCodesVerification}>
                          Sprawdź
                        </CButton>
                      </CRow>
                    </CCol>
                  </CRow>
                </>
              ) : (
                <>
                  <CRow className="mb-4">
                    {verifiedCodes && <ValidatedCodesList data={verifiedCodes} />}
                  </CRow>
                  <CRow className="px-3 mt-3">
                    <CButton
                      color="primary"
                      variant="outline"
                      onClick={() => {
                        setStep(0)
                      }}
                    >
                      Wróć do edycji
                    </CButton>
                  </CRow>
                </>
              )}
            </CTabPane>
            <CTabPane
              role="tabpanel"
              className="mb-2"
              aria-labelledby="orders"
              visible={activeKey === 1}
            >
              <CRow className="mb-4">
                <AddedFileList
                  data={product}
                  setValue={setValue}
                  pointer={`products[${index}]`}
                  register={register}
                  watch={watch}
                />
              </CRow>
              {product.files?.length > 0 ? (
                <CRow className="px-3">
                  <CButton
                    onClick={() => {
                      setValue(`products.${index}.files`, '')
                      setValue(`products.${index}.codesInFile`, null)
                    }}
                    className="btn btn-outline-danger bg-transparent fw-semibold px-5 pt-1 pb-1"
                  >
                    wyczyść
                  </CButton>
                </CRow>
              ) : (
                <CRow className="px-3">
                  <CFormLabel className="mb-2 px-0" htmlFor="file">
                    Wskaż załączniki graficzne zawierające kody
                  </CFormLabel>
                  <CFormInput
                    type="file"
                    id="files"
                    placeholder={''}
                    {...register(`products.${index}.files`)}
                    className="mb-4 border-bottom"
                    multiple
                  />
                </CRow>
              )}
            </CTabPane>
          </CTabContent>
        </CCardBody>
      </CCard>
    </>
  )
}

export default AddProductCodes
