import React from 'react'
import { useStoreRehydrated, useStoreState } from 'easy-peasy'
import { Route, Routes, Navigate } from 'react-router-dom'
import Login from './pages/login/Login'
import { DefaultLayout as Layout } from './layout/DefaultLayout'
import Logout from './views/logout/Logout'
// import Dashboard from './views/dashboard/Dashboard'
import Tmp from './views/Tmp'
import Users from './views/users/Users'
import Partners from './views/partners/Partners'
import PartnerNew from './views/partner/PartnerNew'
import Partner from './views/partner/Partner'
import PartnerEdit from './views/partner/PartnerEdit'
import UserNew from './views/users/UserNew'
import UserEdit from './views/users/UserEdit'
import Complaints from './views/complaints/Complaints'
import ComplaintNew from './views/complaint/ComplaintNew'
import Complaint from './views/complaint/Complaint'
// import ComplaintEdit from './views/complaint/ComplaintEdit'
import ProductEdit from './views/product/ProductEdit'
import Product from './views/product/Product'
import ProductNew from './views/product/ProductNew'
import Products from './views/products/Products'
import Deliveries from './views/deliveries/Deliveries'
import DeliveryNew from './views/delivery/DeliveryNew'
import Delivery from './views/delivery/Delivery'
import DeliveryEdit2 from './views/delivery/DeliveryEdit2'
import Orders from './views/orders/Orders'
import Order from './views/order/Order'
import OrderEdit from './views/order/OrderEdit'
import OrderNew from './views/order/OrderNew'
import FlashOffers from './views/flashOffers/FlashOffers'
import FlashOfferNew from './views/flashOffer/FlashOfferNew'
import FlashOffer from './views/flashOffer/FlashOffer'
import FlashOfferEdit from './views/flashOffer/FlashOfferEdit'
import DailyOffers from './views/dailyOffers/DailyOffers'
import DailyOfferNew from './views/dailyOffers/DailyOfferNew'
import DailyOfferEdit from './views/dailyOffers/DailyOfferEdit'
import IsLoading from './components/IsLoading'
import Settings from './views/settings/Settings'
import Download from './pages/Download'
import DeliveryProductCodes from './views/deliveryProductCodes/DeliveryProductCodes'
import OrderEditDetails from './views/order/OrderEditDetails'
import ExternalProducts from './views/productsExternal/ExternalProducts'

// const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

const Routing = function () {
  const isRehydrated = useStoreRehydrated()
  const isAuthenticated = useStoreState((state) => state.auth.isAuthenticated)
  const isAdmin = useStoreState((store) => store.auth.isAdmin)
  if (!isRehydrated) return <IsLoading />

  return (
    <React.Suspense fallback={<IsLoading />}>
      <Routes>
        {/* children are passed by <Outlet/> component down the path tree */}
        {isAuthenticated ? (
          <>
            <Route element={<Layout />}>
              {/* <Route index element={<Navigate to="pulpit" replace />} />
            <Route path="*" element={<Navigate to="pulpit" replace />} /> */}
              <Route index element={<Navigate to="produkty" replace />} />
              <Route path="*" element={<Navigate to="produkty" replace />} />
              {/* <Route path="pulpit" element={<Dashboard />} /> */}
              <Route path="kontrahenci" element={<Partners />} />
              <Route path="kontrahent">
                <Route path="new" element={<PartnerNew />} />
                <Route path=":id" element={<Partner />} />
                <Route path=":id/edit" element={<PartnerEdit />} />
                <Route index element={<Partners />} />
              </Route>
              <Route path="produkty" element={<Products />} />
              <Route path="zewnetrzne-produkty" element={<ExternalProducts />} />
              <Route path="produkt">
                <Route path="new" element={<ProductNew />} />
                <Route path=":id" element={<Product />} />
                <Route path=":id/edit" element={<ProductEdit />} />
                <Route index element={<Products />} />
              </Route>
              {isAdmin && <Route path="kody" element={<Tmp />} />}
              <Route path="reklamacje" element={<Complaints />} />
              <Route path="reklamacja">
                <Route path="new" element={<ComplaintNew />} />
                <Route path=":id" element={<Complaint />} />
                {/*              <Route path=":id/edit" element={<ComplaintEdit />} />*/}
                <Route index element={<Complaints />} />
              </Route>
              <Route path="zamowienia" element={<Orders />} />
              <Route path="zamowienie">
                <Route path="new" element={<OrderNew />} />
                <Route path=":id" element={<Order />} />
                <Route path=":id/edit" element={<OrderEdit />} />
                <Route path=":id/edit-details" element={<OrderEditDetails />} />
                <Route index element={<Orders />} />
              </Route>
              <Route path="dostawy" element={<Deliveries />} />
              <Route path="dostawa">
                <Route path="new" element={<DeliveryNew />} />
                <Route path=":id" element={<Delivery />} />
                {isAdmin && <Route path=":id/kody/:id2" element={<DeliveryProductCodes />} />}
                <Route path=":id/edit" element={<DeliveryEdit2 />} />
                <Route index element={<Deliveries />} />
              </Route>
              <Route path="flash">
                <Route path="new" element={<FlashOfferNew />} />
                <Route path=":id" element={<FlashOffer />} />
                <Route path=":id/edit" element={<FlashOfferEdit />} />
                <Route index element={<FlashOffers />} />
              </Route>
              {isAdmin && (
                <Route path="dzienne">
                  <Route path="new" element={<DailyOfferNew />} />
                  <Route path=":id/edit" element={<DailyOfferEdit />} />
                  <Route index element={<DailyOffers />} />
                </Route>
              )}
              {/* <Route path="wts" element={<Tmp />} />
              <Route path="wtb" element={<Tmp />} /> */}
              {isAdmin && (
                <Route path="uzytkownicy">
                  <Route path="new" element={<UserNew />} />
                  {/* <Route path=":id" element={<User />} /> */}
                  <Route path=":id/edit" element={<UserEdit />} />
                  <Route index element={<Users />} />
                </Route>
              )}
              {isAdmin && <Route path="ustawienia" element={<Settings />} />}

              <Route path="logout" element={<Logout />} />
            </Route>
            <Route path="download/:uid" element={<Download />} />
          </>
        ) : (
          <>
            <Route path="*" element={<Navigate to="login" replace />} />
            <Route path="login" element={<Login />} />
            <Route path="download/:uid" element={<Download />} />
          </>
        )}
      </Routes>
    </React.Suspense>
  )
}

export default Routing
