import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CRow,
  CSmartTable,
} from '@coreui/react-pro'
import React from 'react'
import { useMutation, useQuery } from 'react-query'
import DataService from 'src/services/DataService'
import { useNavigate, useParams } from 'react-router-dom'
import BasicList from 'src/components/BasicList'
import listFields from './DeliveryList'
import { tableProps } from './DeliveryProductTable'
import { flattenDataStructure } from 'src/helpers'
import EditButton from 'src/components/EditButton'
import HistoryList from '../../components/HistoryList'
import { toast } from 'react-toastify'
import IsLoading from 'src/components/IsLoading'
import CancelButton from 'src/components/CancelButton'
import { useStoreState } from 'easy-peasy'

const Delivery = () => {
  const { id } = useParams()
  const isAdmin = useStoreState((store) => store.auth.isAdmin)

  const { data, isError, error, isLoading, isFetching, refetch } = useQuery(
    ['delivery', id],
    DataService.deliveries.getDelivery(id)
  )

  const { mutate: editDelivery } = useMutation(DataService.deliveries.editDelivery(id), {
    onSuccess: () => {
      toast.success('Zmiana została zapisana')
      refetch()
    },
    onError: (e) => {
      if (e?.message) {
        console.log(e.message)
        // toast.error(e.message)
      }
    },
  })

  const navigate = useNavigate()

  const handleMarkAsPaid = () => {
    editDelivery({ is_Paid: true })
  }
  const handleMarkAsNotPaid = () => {
    editDelivery({ is_Paid: false })
  }

  const handleMarkAsCanceled = () => {
    editDelivery({ status: 'ANULOWANE' })
  }
  const hasCodes = () => {
    for (const product of data?.products || []) {
      if (product.amount > 0) return true
    }
    return false
  }

  if (isLoading) return <IsLoading />

  if (isError) {
    console.log(error)
    return <span>bląd</span>
  }

  return (
    <>
      <CContainer fluid className="h-100">
        <CRow>
          <CCol className="d-flex flex-row justify-content-between pb-4">
            <h2>{`Dostawa JK-DELIVERY-${String(data?.id).padStart(5, '0')}`}</h2>
            {!isLoading && (
              <div>
                {data.status !== 'ANULOWANE' &&
                  data.status !== 'OTRZYMANE' &&
                  hasCodes() === false && (
                    <CancelButton onClick={handleMarkAsCanceled} label={'Oznacz jako Anulowane'} />
                  )}
                {!data.isPaid && (
                  <EditButton onClick={handleMarkAsPaid} label={'Oznacz jako zapłacona'} />
                )}
                {data.isPaid && (
                  <EditButton onClick={handleMarkAsNotPaid} label={'Oznacz jako niezapłacona'} />
                )}
                {data.status !== 'ANULOWANE' &&
                  data.status !== 'OTRZYMANE' &&
                  data.source === 'system' && (
                    <EditButton
                      onClick={() => {
                        navigate(`/dostawa/${data.id}/edit`)
                      }}
                      label={'Dodaj kody / produkty'}
                    />
                  )}
              </div>
            )}
          </CCol>
        </CRow>
        <CRow>
          <CCol xs={3}>
            <CCard>
              <CCardHeader>Dane Dostawy</CCardHeader>
              {!isLoading && <BasicList listFields={listFields} data={data} />}
            </CCard>
            <CCard>
              <CCardHeader>Historia</CCardHeader>
              <CCardBody>
                <HistoryList data={data.history} />
              </CCardBody>
            </CCard>
          </CCol>
          <CCol xs={9}>
            <CCard>
              <CCardHeader>Produkty</CCardHeader>
              <CCardBody>
                <CSmartTable
                  items={flattenDataStructure(data?.products)}
                  loading={isFetching}
                  {...tableProps(navigate, isAdmin, id, data.currency)}
                />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default Delivery
