import React from 'react'
import { formatData } from '../helpers'

const HistoryList = ({ data }) => {
  const sortedData = data?.sort((a, b) => b.id - a.id)
  return (
    <div>
      {sortedData?.length && sortedData.length > 0 ? (
        sortedData.map((h, i) => (
          <div key={i} className={'mt-2'}>
            <p className="fw-medium m-0 p-0">{formatData(h.createdAt, 'ddmmyyyyhhmm')}</p>
            <p className="m-0 p-0">{h?.text.split(' - ')[0]}</p>
            <p>
              <i>{h?.text.split(' - ')[1]}</i>
            </p>
          </div>
        ))
      ) : (
        <div>Nie ma jeszcze historii</div>
      )}
    </div>
  )
}

export default HistoryList
