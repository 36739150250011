import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react-pro'
import React from 'react'
import { formatPriceToDisplayDecimals } from 'src/helpers'

const OrderProductDeliveriesDetails = ({ data }) => {
  return (
    <CTable className="mb-0">
      <CTableHead>
        <CTableRow>
          <CTableHeaderCell scope="col">Ilość</CTableHeaderCell>
          <CTableHeaderCell scope="col">Cena zakupu w walucie (szt.)</CTableHeaderCell>
          <CTableHeaderCell scope="col">Cena zakupu (szt.)</CTableHeaderCell>
          <CTableHeaderCell scope="col">Format</CTableHeaderCell>
          <CTableHeaderCell scope="col">Dostawca</CTableHeaderCell>
          <CTableHeaderCell scope="col">Dostawa</CTableHeaderCell>
        </CTableRow>
      </CTableHead>
      <CTableBody>
        {data.map((delivery, id) => {
          return (
            <CTableRow key={id + 'TXT'}>
              <CTableDataCell>{delivery.codes}</CTableDataCell>
              <CTableDataCell>
                {formatPriceToDisplayDecimals(delivery.valueByCurrency / delivery.codes) +
                  ' ' +
                  delivery.currency}
              </CTableDataCell>
              <CTableDataCell>
                {formatPriceToDisplayDecimals(delivery.value / delivery.codes)}
              </CTableDataCell>
              <CTableDataCell>{delivery.format}</CTableDataCell>
              <CTableDataCell>{delivery.contractor.name}</CTableDataCell>
              <CTableDataCell>Dostawa ID: {delivery.id}</CTableDataCell>
            </CTableRow>
          )
        })}
      </CTableBody>
    </CTable>
  )
}

export default OrderProductDeliveriesDetails
