const formFields = (maxToReserve, isAdmin = false) => {
  return [
    [
      {
        id: 'contractor',
        category: '',
        label: 'KLIENT',
        type: 'dynamicDropdown',
        endpoint: 'contractors',
        nameField: 'name',
        placeholder: 'Klient',
        required: true,
        valueAsNumber: true,
      },
      {
        id: 'amountOfCodes',
        category: '',
        label: 'ILOŚĆ KODÓW',
        placeholder: '',
        type: 'number',
        required: true,
        valueAsNumber: true,
        min: 0,
        max: maxToReserve,
      },
      {
        id: 'sellingPrice',
        category: '',
        label: 'CENA',
        placeholder: '',
        type: 'number',
        required: true,
        valueAsNumber: true,
        step: 0.01,
      },
      isAdmin && {
        id: 'status',
        category: '',
        label: 'STATUS',
        placeholder: '',
        type: [
          ['OCZEKUJĄCE', 'Oczekujące'],
          ['POTWIERDZONE', 'Potwierdzone'],
          ['ANULOWANE', 'Anulowane'],
        ],
        required: true,
      },
    ],
  ]
}

export default formFields
