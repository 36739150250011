import React, { useState } from 'react'
import IsLoading from './IsLoading'
import EditOrderProduct from './EditOrderProduct'

const EditOrderProductWrapper = ({ register, watch, setValue, unregister, disabled }) => {
  const products = watch().products
  const [reset, setReset] = useState(0)

  const handleReset = () => {
    setReset((prev) => prev + 1)
  }

  if (!products) return <IsLoading />

  return (
    <div id={reset}>
      {products.length > 0 &&
        products.map((product, id) => {
          return (
            <EditOrderProduct
              product={product}
              index={id}
              register={register}
              unregister={unregister}
              key={id}
              setValue={setValue}
              watch={watch}
              disabled={disabled}
              reset={handleReset}
            />
          )
        })}
    </div>
  )
}

export default EditOrderProductWrapper
