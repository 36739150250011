const formFields = [
  [
    { subtitle: 'Podstawowe dane' },
    {
      id: 'companyName',
      category: 'contractorDetails',
      label: 'Nazwa firmy',
      placeholder: 'np. Firma',
      type: 'text',
      required: true,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
    {
      id: 'properName',
      category: 'contractorDetails',
      label: 'Nazwa własna',
      placeholder: 'np. Firma Sp z o.o.',
      type: 'text',
      required: true,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
    {
      id: 'vatNumber',
      category: 'contractorDetails',
      label: 'Numer VAT',
      placeholder: 'np. PL1234567890',
      type: 'text',
      required: true,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
    {
      id: 'supervisor',
      category: 'supervisor',
      label: 'Opiekun',
      type: 'dynamicDropdown',
      endpoint: 'users',
      nameField: 'fullName',
      placeholder: 'Opiekun',
      initValue: ['supervisor', 'id'],
      required: true,
      valueAsNumber: true,
      access: ['ROLE_ADMIN', 'ROLE_ACCOUNT_MANAGER'],
    },
    {
      id: 'note',
      category: 'contractorDetails',
      label: 'Notatki',
      placeholder: 'notatka',
      type: 'textarea',
      required: false,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
  ],
  [
    { subtitle: 'Adres kontrahenta' },
    {
      id: 'street',
      category: 'contractorAddress',
      label: 'Ulica',
      placeholder: 'np. Ulica 12',
      type: 'text',
      required: true,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
    {
      id: 'city',
      category: 'contractorAddress',
      label: 'Miasto',
      placeholder: 'np. Miasto',
      type: 'text',
      required: true,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
    {
      id: 'zipCode',
      category: 'contractorAddress',
      label: 'Kod pocztowy',
      placeholder: 'np. 00-000',
      type: 'text',
      required: false,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
    {
      id: 'country',
      category: 'contractorAddress',
      label: 'Kraj',
      placeholder: 'np. Kraj',
      type: 'text',
      required: true,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
  ],
  [
    { subtitle: 'Dane kontaktowe' },
    {
      id: 'skypeId',
      category: 'contractorContact',
      label: 'Skype ID',
      placeholder: 'np. 123dfg',
      type: 'text',
      required: false,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
    {
      id: 'webPage',
      category: 'contractorContact',
      label: 'Strona WWW',
      placeholder: 'np. https://wwww.firma.com',
      type: 'text',
      required: false,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
    {
      id: 'orderMail',
      category: 'contractorContact',
      label: 'Email do zamówień',
      placeholder: 'np. zamowienia@firma.com',
      type: 'email',
      required: true,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
    {
      id: 'invoiceMail',
      category: 'contractorContact',
      label: 'Email do FV',
      placeholder: 'np. fv@firma.com',
      type: 'email',
      required: true,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
    {
      id: 'contactMail',
      category: 'contractorContact',
      label: 'Email do kontaktu',
      placeholder: 'np. kontakt@firma.com',
      type: 'email',
      required: true,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
  ],
]

export default formFields
