import { CModal, CModalBody, CModalFooter } from '@coreui/react-pro'
import React from 'react'
import { useForm } from 'react-hook-form'
import BasicForm from 'src/components/BasicForm'
import formFields from './ReserveFlashOfferForm'
import IsLoadingOverlay from 'src/components/IsLoadingOverlay'
import { useMutation } from 'react-query'
import DataService from 'src/services/DataService'
import { notifyError, notifySucces } from 'src/services/notify'
import EditButton from 'src/components/EditButton'
import CancelButton from 'src/components/CancelButton'
import { useParams } from 'react-router-dom'
import { convertPriceToWholeNumber } from 'src/helpers'

const ReserveFlashOfferModal = ({ visible, setVisible, refetch, maxToReserve }) => {
  const { register, handleSubmit, watch, setValue, reset } = useForm()

  const { id } = useParams()

  const {
    mutate: reserveFlashOfferMutation,
    isLoading,
    error,
  } = useMutation(DataService.flashOffers.reserveFlashOffer(id), {
    onSuccess: () => {
      setVisible(false)
      notifySucces('Rezerwacja została zapisana')
      refetch()
      reset()
    },
    onError: (e) => {
      console.log(error)
      notifyError(e)
    },
  })

  const onSubmit = async (data) => {
    data.sellingPrice = convertPriceToWholeNumber(data.sellingPrice)
    reserveFlashOfferMutation({ ...data, status: 'OCZEKUJĄCE' })
  }

  return (
    <>
      <CModal
        visible={visible}
        //   onClose={() => setVisible(false)}
      >
        {isLoading && <IsLoadingOverlay />}
        <CModalBody className="pt-4">
          <div className="h5 mb-4">Rezerwacja oferty:</div>
          {/* {console.log(formFields(maxToReserve))} */}
          <BasicForm
            formFields={formFields(maxToReserve)}
            onSubmit={handleSubmit((data) => onSubmit(data))}
            register={register}
            watch={watch}
            setValue={setValue}
          />
        </CModalBody>
        <CModalFooter>
          <EditButton label={'Rezerwuj'} />
          <CancelButton onClick={() => setVisible(false)} />
        </CModalFooter>
      </CModal>
    </>
  )
}

export default ReserveFlashOfferModal
