import { cilCheckCircle, cilWarning } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CBadge, CCol, CListGroup, CListGroupItem, CRow } from '@coreui/react-pro'
import React from 'react'
import SimpleBar from 'simplebar-react'

const ValidatedCodesList = ({ data }) => {
  //TODO sortowanie wyników aby błędne były na górze

  return (
    <div className="overflow-auto pe-0" style={{ maxHeight: '50vh' }}>
      <SimpleBar style={{ height: '100%' }} className="me-0">
        <CListGroup className="me-3">
          {data &&
            data?.map((code, id) => (
              <CListGroupItem key={id} className={code.error ? 'border border-danger mb-1' : ''}>
                <CRow className="px-2">
                  <CCol xs={6} className="px-0">
                    {code.code}
                  </CCol>
                  <CCol xs={1} className="px-0">
                    {code?.length}
                  </CCol>
                  <CCol xs={1} className="px-0">
                    {!code.error ? (
                      <CIcon icon={cilCheckCircle} className="text-success" />
                    ) : (
                      <CIcon icon={cilWarning} className="text-danger" />
                    )}
                  </CCol>
                  <CCol xs={4} className="px-0 overflow-hidden">
                    <CBadge color="danger" style={{ textWrap: 'wrap' }}>
                      {code?.error}
                    </CBadge>
                  </CCol>
                </CRow>
              </CListGroupItem>
            ))}
        </CListGroup>
      </SimpleBar>
    </div>
  )
}

export default ValidatedCodesList
