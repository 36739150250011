import React from 'react'
import { ReactComponent as Down } from 'src/assets/custom/down.svg'
import { ReactComponent as Up } from 'src/assets/custom/up.svg'
import { ReactComponent as ChevLeft } from 'src/assets/custom/chevron-left.svg'
import { ReactComponent as ChevDoubleLeft } from 'src/assets/custom/chevron-double-left.svg'
import { ReactComponent as ChevRight } from 'src/assets/custom/chevron-right.svg'
import { ReactComponent as ChevDoubleRight } from 'src/assets/custom/chevron-double-right.svg'

const sortingIcon = (
  <div
    className="d-flex flex-column"
    // style={{ opacity: '1' }}
  >
    <Up
      className="navigation-icons"
      height={8}
      width={16}
      // className="sorting-marker opacity-100 text-primary"
      style={{ opacity: '1' }}
    />
    <Down height={8} width={16} className="navigation-icons" />
  </div>
)

const sortingIconAscending = (
  <div className="d-flex flex-column sorting">
    <Up height={16} width={16} className="navigation-icons" />
  </div>
)

const sortingIconDescending = (
  <div className="d-flex flex-column sorting">
    <Down height={16} width={16} className="navigation-icons" />
  </div>
)

export const paginationProps = {
  firstButton: <ChevDoubleLeft className="py-1 h-100 navigation-icons" />,
  previousButton: <ChevLeft className="py-1 h-100 navigation-icons" />,
  nextButton: <ChevRight className="py-1 h-100 navigation-icons" />,
  lastButton: <ChevDoubleRight className="py-1 h-100 navigation-icons" />,
}

export const navigationStylingProps = {
  // paginationProps,
  sortingIcon,
  sortingIconAscending,
  sortingIconDescending,
}
