import React from 'react'
import { CButton, CSmartTable } from '@coreui/react-pro'
import { navigationStylingProps } from '../../assets/custom/tableSorting'
import { formatPriceToDisplayDecimals } from 'src/helpers'
import Badge from '../../components/Badge'

export const tableProps = (action) => {
  return {
    scopedColumns: {
      sourceMarginType: ({ sourceMarginType }) => (
        <td>{sourceMarginType === 'value' ? 'Wartościowa' : 'Procentowa'} </td>
      ),
      sourceStatus: ({ sourceStatus }) => (
        <td>
          <h5>
            <Badge module={'externalProducts'} status={sourceStatus} />
          </h5>
        </td>
      ),
      format: () => <td>TXT</td>,
      enabled: ({ enabled }) => (
        <td>
          <Badge module={'shopAvailability'} status={enabled} />
        </td>
      ),
      sourceMarginValue: ({ sourceMarginValue, sourceMarginType, sourceCurrency }) => (
        //todo: W jakiej walucie podawana jest marża?
        <td>
          {sourceMarginType === 'value'
            ? formatPriceToDisplayDecimals(sourceMarginValue)
            : `${sourceMarginValue}%`}
        </td>
      ),
      sourcePrice: ({ sourcePrice }) => <td>{formatPriceToDisplayDecimals(sourcePrice)}</td>,
      salesPrice: ({ sourcePrice, sourceMarginValue }) => (
        <td>{formatPriceToDisplayDecimals(sourcePrice + sourceMarginValue)}</td>
      ),
      sourcePriceByCurrency: ({ sourcePriceByCurrency, sourceCurrency }) => (
        <td>{formatPriceToDisplayDecimals(sourcePriceByCurrency) + ' ' + sourceCurrency}</td>
      ),
      actions: (row) => (
        <td>
          <CButton onClick={() => action(row)}>Szczegóły</CButton>
        </td>
      ),
    },
    clickableRows: false,
    tableProps: {
      striped: true,
      hover: false,
    },
    ...navigationStylingProps,
  }
}

const ExternalProductAssignedTable = ({ data, action }) => {
  const columns = [
    {
      key: 'source',
      label: 'Źródło',
    },
    {
      key: 'sourceStatus',
      label: 'Status Kalixo',
    },
    {
      key: 'enabled',
      label: 'Dostępność w sklepie',
    },
    {
      key: 'format',
      label: 'Format',
    },
    {
      key: 'sourceMarginType',
      label: 'Typ marży',
    },
    {
      key: 'sourceCurrency',
      label: 'Waluta',
    },
    {
      key: 'sourceMarginValue',
      label: 'Marża',
    },

    {
      key: 'sourcePrice',
      label: 'Cena zakupu',
    },
    {
      key: 'sourcePriceByCurrency',
      label: 'Cena zakupu w walucie',
    },
    {
      key: 'salesPrice',
      label: 'Cena sprzedaży',
    },
    {
      key: 'sourceSku',
      label: 'SKU',
    },
    {
      key: 'actions',
      label: 'Szczegóły',
    },
  ]

  return (
    <>
      <div>
        <CSmartTable
          columns={columns}
          items={data}
          itemsPerPage={99999999999999999}
          {...tableProps(action)}
        />
      </div>
    </>
  )
}

export default ExternalProductAssignedTable
