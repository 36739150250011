import axios from 'src/axios'
import { notifyError, notifySucces } from '../notify'
import { parseObjectToUrlQuery } from 'src/helpers'

const PartnersService = {
  getPartners:
    (params = null) =>
    async () => {
      console.log('@GetPartnes, filter by', `/api/contractors${parseObjectToUrlQuery(params)}`)
      return await axios
        .get(`/api/contractors${parseObjectToUrlQuery(params)}`)
        .then((res) => {
          console.log(res.data)
          return res.data
        })
        .catch((e) => {
          notifyError(e)
          throw e
        })
    },
  getPartnersList:
    (params = null) =>
    async () => {
      // const fields = ['id', 'name']
      console.log(`@GetPartnersList /api/contractors/list${parseObjectToUrlQuery(params)}`)
      return await axios
        .get(`/api/contractors/list${parseObjectToUrlQuery(params)}`)
        .then((res) => {
          console.log('@PartnersList res.data: ', res.data)
          return res.data
        })
        .catch((e) => {
          notifyError(e)
          throw e
        })
    },
  getPartner: (id) => async () => {
    return await axios
      .get(`/api/contractors/${id}`)
      .then((res) => {
        console.log('@GetPartner data:', res.data)
        return res.data.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  saveParter: async (data) => {
    console.log('@SavePartner sending:', data)
    return await axios
      .post('/api/contractors', data)
      .then((res) => {
        notifySucces('Nowy Kontrachent został dodany')
        console.log('@SavePartner res:', res.data)
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  editPartner: (id) => async (data) => {
    console.log(data)
    return await axios
      .put(`/api/contractors/${id}`, data)
      .then((res) => {
        notifySucces('Zmiany zostały zapisane')
        console.log(res.data)
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },

  deletePartner: (id) => async () => {
    return await axios
      .delete(`/api/contractors/${id}`)
      .then((res) => {
        notifySucces('Kontrahent usunięty')
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
}

export default PartnersService
