import React, { useState } from 'react'
import { CSmartTable } from '@coreui/react-pro'
import DataService from '../../services/DataService'
import { navigationStylingProps } from '../../assets/custom/tableSorting'
import { useQuery } from 'react-query'
import IsLoading from '../../components/IsLoading'
import Badge from '../../components/Badge'
import ReserveFlashOfferModal from './ReserveFlashOfferModal'
import ModifyReserveFlashOfferModal from './ModifyReserveFlashOfferModal'
import { useEffect } from 'react'
import { formatPriceToDisplayDecimals } from 'src/helpers'

export const tableProps = ({ setModifyReserveModalVisible, getData }) => {
  return {
    scopedColumns: {
      endDate: ({ endDate }) => <td>{new Date(endDate.date).toLocaleDateString()}</td>,
      contractor: ({ contractor }) => <td>{contractor.name}</td>,

      status: ({ status }) => (
        <td>
          <Badge status={status} module={'offer'} />
        </td>
      ),
      sellingPrice: ({ sellingPrice }) => <td>{formatPriceToDisplayDecimals(sellingPrice)} EUR</td>,
      createdAt: ({ createdAt }) => <td>{createdAt}</td>,
      supervisor: ({ supervisor }) => <td>{supervisor.name + ' ' + supervisor.surname}</td>,
    },
    onRowClick: (e) => {
      setModifyReserveModalVisible(true)
      const data = { ...e }
      data.sellingPrice = formatPriceToDisplayDecimals(e.sellingPrice)
      getData(data)
    },
    clickableRows: true,
    columnSorter: true,
    pagination: true,
    itemsPerPageSelect: true,
    itemsPerPage: 10,
    tableProps: {
      striped: true,
      hover: true,
    },
    ...navigationStylingProps,
  }
}

const FlashOffersReservationTable = ({
  id,
  visible,
  setVisible,
  setReservationData,
  maxToReserve,
}) => {
  const [modifyReserveModalVisible, setModifyReserveModalVisible] = useState(false)
  const [modifyReserveModalData, setModifyReserveModalData] = useState(false)
  const { data, isLoading, isSuccess, refetch } = useQuery(
    ['flashOffer', id, 'Reservations'],
    DataService.flashOffers.getFlashOfferReservations(id)
  )

  useEffect(() => {
    if (data?.results) setReservationData([...data.results])
  }, [data, setReservationData])

  const columns = [
    {
      key: 'id',
      label: 'ID',
      _style: { minWidth: '60px' },
    },
    {
      key: 'contractor',
      label: 'Klient',
      _style: { minWidth: '150px' },
    },
    {
      key: 'amountOfCodes',
      label: 'Ilość kodów',
      _style: { minWidth: '100px' },
    },
    {
      key: 'sellingPrice',
      label: 'Cena sprzedaży',
      _style: { minWidth: '100px' },
    },
    {
      key: 'createdAt',
      label: 'Data rezerwacji',
      _style: { minWidth: '100px' },
    },
    {
      key: 'supervisor',
      label: 'Rezerwujący',
      _style: { minWidth: '100px' },
    },
    {
      key: 'status',
      label: 'Status',
      _style: { minWidth: '100px' },
    },
  ]

  return (
    <>
      <div>
        {isLoading && <IsLoading />}
        {isSuccess && (
          <CSmartTable
            columns={columns}
            items={data.results.sort((a, b) => b.status.localeCompare(a.status))}
            loading={isLoading}
            pagination
            {...tableProps({
              setModifyReserveModalVisible: setModifyReserveModalVisible,
              getData: setModifyReserveModalData,
            })}
          />
        )}
      </div>
      {/* {console.log('maxToReserve: ', maxToReserve)} */}
      <ReserveFlashOfferModal
        visible={visible}
        setVisible={setVisible}
        refetch={refetch}
        maxToReserve={maxToReserve}
      />
      <ModifyReserveFlashOfferModal
        visible={modifyReserveModalVisible}
        setVisible={setModifyReserveModalVisible}
        initData={modifyReserveModalData}
        refetch={refetch}
        maxToReserve={
          modifyReserveModalData.status !== 'POTWIERDZONE'
            ? maxToReserve
            : maxToReserve + modifyReserveModalData.amountOfCodes
        }
      />
    </>
  )
}

export default FlashOffersReservationTable
