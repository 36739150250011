import { CButton, CModal, CModalBody, CModalFooter } from '@coreui/react-pro'
import React from 'react'
import CancelButton from 'src/components/CancelButton'
import BasicList from '../BasicList'
import { useMutation } from 'react-query'
import DataService from '../../services/DataService'
import { useForm } from 'react-hook-form'
import ProductAssigningModalForm from './ProductAssigningModalForm'
import { listFields } from './modalFieldList'

const ProductAssigningModal = ({ productData, setAssignProduct }) => {
  const { mutate } = useMutation(DataService.externalApi.kalixo.relate(), {
    onSuccess: () => {
      setAssignProduct(null)
      reset()
    },
  })

  const { watch, handleSubmit, register, setValue, reset } = useForm({
    defaultValues: {
      source: 'kalixo',
      sourceMarginType: '',
      sourceMarginValue: '',
      sourceSku: '',
      mainProduct: '',
    },
  })

  const sendData = (data) => {
    data.sourceMarginType = data.sourceMarginType ? 'percent' : 'value'

    mutate({
      ownProduct: data.mainProduct,
      sourceProduct: {
        sourceEan: productData.sourceEan,
        source: data.source,
        sourceMarginType: data.sourceMarginType,
        sourceMarginValue: parseInt(data.sourceMarginValue * 100, 10),
        sourceSku: productData.sourceSkuToChoice[0],
      },
    })
  }
  return (
    <>
      <CModal visible={productData !== null}>
        <form action={'submit'} onSubmit={handleSubmit(sendData)}>
          <CModalBody className="pt-4">
            <div className="mb-4">
              <h4>Powiąż produkt {productData?.sourceName}</h4>
            </div>
            <BasicList listFields={listFields} data={productData} />
            {productData?.sourceStatus === 'active' && (
              <ProductAssigningModalForm register={register} watch={watch} setValue={setValue} />
            )}
          </CModalBody>
          <CModalFooter>
            {productData?.sourceStatus === 'active' && (
              <CButton
                label={'Powiąż produkty ze sobą'}
                type="submit"
                className=" fw-semibold my-1 py-1 ms-4 px-3"
              >
                Powiąż produkty ze sobą
              </CButton>
            )}
            <CancelButton onClick={() => setAssignProduct(null)} />
          </CModalFooter>
        </form>
      </CModal>
    </>
  )
}

export default ProductAssigningModal
