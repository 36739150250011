import React from 'react'

import { navigationStylingProps } from 'src/assets/custom/tableSorting'
import Badge from '../../components/Badge'
import { formatPriceToDisplayDecimals } from 'src/helpers'

export const tableColumns = [
  {
    key: 'id',
    label: 'ID',
    _style: { minWidth: '60px' },
  },
  {
    key: 'product',
    label: 'Produkt',
    _style: { minWidth: '80px' },
  },
  {
    key: 'numberOfCodes',
    label: 'Liczba kodów',
    _style: { minWidth: '130px' },
  },
  {
    key: 'deliveryTime',
    label: 'Czas dostawy',
    _style: { minWidth: '100px' },
    type: 'text',
  },
  {
    key: 'buyingPrice',
    label: 'Cena sprzedaży',
    _style: { minWidth: '130px' },
  },
  {
    key: 'moq',
    label: 'MOQ',
    _style: { minWidth: '130px' },
  },

  {
    key: 'status',
    label: 'Status',
    _style: { minWidth: '100px' },
  },
]

export const tableProps = (navigate = null) => {
  return {
    columns: tableColumns,
    scopedColumns: {
      product: ({ product }) => <td>{product.name}</td>,
      status: ({ status }) => (
        <td>
          <Badge status={status} module={'daily'}></Badge>
        </td>
      ),
      buyingPrice: ({ buyingPrice }) => <td>{formatPriceToDisplayDecimals(buyingPrice)} EUR </td>,
    },
    onRowClick: (e) => {
      navigate(`/dzienne/${e.id}/edit`)
    },
    clickableRows: true,
    columnSorter: false,
    pagination: true,
    itemsPerPageSelect: true,
    itemsPerPage: 10,
    tableProps: {
      striped: true,
      hover: true,
    },
    ...navigationStylingProps,
  }
}
