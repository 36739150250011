import React from 'react'
import { CCard, CCardBody, CCardHeader, CCol, CContainer, CRow } from '@coreui/react-pro'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import LoaderService from 'src/services/LoaderService'
import BasicForm from 'src/components/BasicForm'
import formFields from './ProductForm'
import DataService from 'src/services/DataService'
import { fileToBase64 } from 'src/helpers'
import EditButton from 'src/components/EditButton'
import CancelButton from 'src/components/CancelButton'

const ProductNew = () => {
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      name: '',
      platforms: [],
      region: '',
      charsInCode: '',
      codesInPhoto: 1,
      productCategory: '',
      // file: '',
    },
  })

  const navigate = useNavigate()

  const { mutate } = useMutation(DataService.products.addProduct, {
    onSuccess: () => {
      navigate('/produkty')
    },
  })

  const onSubmit = async (data) => {
    LoaderService.show()
    let newData = { ...data }
    if (!data.file[0]) delete newData.file
    if (data.file[0]) {
      newData.file = {
        base64: await fileToBase64(newData.file[0]),
        type: data.file[0].type,
      }
    }
    mutate(newData)
    LoaderService.hide()
  }

  return (
    <>
      <CContainer fluid className="h-100">
        <CRow>
          <CCol className="d-flex flex-row justify-content-between pb-4">
            <h2>Tworzenie nowego produktu</h2>
            <div>
              <EditButton label="Zapisz" />
              <CancelButton label="Anuluj" onClick={() => navigate('/produkty')} />
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>Dane produktu</CCardHeader>
              <CCardBody>
                <BasicForm
                  formFields={formFields}
                  onSubmit={handleSubmit((data) => onSubmit(data))}
                  register={register}
                  setValue={setValue}
                  watch={watch}
                ></BasicForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}
export default ProductNew
