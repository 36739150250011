import { CCard, CCardHeader, CCol, CContainer, CRow } from '@coreui/react-pro'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import DataService from 'src/services/DataService'
import { useNavigate, useParams } from 'react-router-dom'
import BasicList from 'src/components/BasicList'
import listFields from './FlashOfferList'
import EditButton from 'src/components/EditButton'
import { useState } from 'react'
import FlashOffersReservationTable from './FlashOfferReservationTable'
import IsLoading from 'src/components/IsLoading'
import { useHasAccess } from 'src/helpers'
// import AddAttachmentRecord from 'src/components/AddAttachmentRecord'

const FlashOffer = () => {
  const { id } = useParams()
  const [reserveModalVisible, setReserveModalVisible] = useState(false)
  const [reservationData, setReservationData] = useState()
  const [offerData, setOfferData] = useState()
  const [maxToReserve, setMaxToReserve] = useState(0)

  const { data, isLoading, isError, error } = useQuery(
    ['flashOffer', id],
    DataService.flashOffers.getFlashOffer(id)
  )

  //initialize order data
  useEffect(() => {
    if (data) {
      setOfferData(formatData({ ...data }))
    }
  }, [data])

  useEffect(() => {
    let reserved = 0
    if (data) {
      if (reservationData?.length > 0) {
        reservationData.forEach((reservation) => {
          if (reservation.status === 'POTWIERDZONE') reserved += reservation.amountOfCodes
        })
        // console.log('numberOfCodes: ', data.numberOfCodes)
      }
      if (!data.numberOfCodes) {
        setMaxToReserve(2147483647)
      } else {
        setMaxToReserve(data.numberOfCodes - reserved)
      }
    }
  }, [reservationData, data])

  const formatData = (data) => {
    let tmp = data
    if (tmp.inStock === false) tmp.inStock = 'BRAK'
    else tmp.inStock = 'TAK'

    if (tmp.numberOfCodes) {
      tmp.remainingCodes = tmp.numberOfCodes - tmp.totalConfirmedAmountOfCodes
    }
    tmp.remainingTime = 'unlimited'
    if (tmp.endDate !== null) {
      tmp.remainingTime = new Date(tmp.endDate) - new Date()
    }
    return tmp
  }

  const navigate = useNavigate()

  let _restrictedAcces = useHasAccess(['ROLE_ADMIN'])

  if (isLoading) return <IsLoading />
  if (isError) {
    console.log(error)
    return <span>błąd</span>
  }

  if (data) {
    return (
      <>
        <CContainer fluid className="h-100">
          <CRow>
            <CCol className="d-flex flex-row justify-content-between pb-4">
              <h2>{data?.name}</h2>
              {(!data.endDate ||
                (data.endDate &&
                  new Date(data.endDate).setHours(0, 0, 0, 0) >=
                    new Date().setHours(0, 0, 0, 0))) && (
                <div>
                  {((data.numberOfCodes && maxToReserve > 0) || !data.numberOfCodes) && (
                    <EditButton label="Rezerwuj" onClick={() => setReserveModalVisible(true)} />
                  )}
                  {_restrictedAcces && (
                    <EditButton
                      label="Edytuj Ofertę"
                      onClick={() => navigate(`/flash/${id}/edit`)}
                    />
                  )}
                </div>
              )}
            </CCol>
          </CRow>
          <CRow>
            <CCol xs={4}>
              <CCard>
                <CCardHeader>Dane szczegółowe</CCardHeader>
                <BasicList listFields={listFields} data={offerData} />
              </CCard>
            </CCol>
            <CCol xs={8}>
              <FlashOffersReservationTable
                id={id}
                visible={reserveModalVisible}
                setVisible={setReserveModalVisible}
                setReservationData={setReservationData}
                maxToReserve={maxToReserve}
              />
            </CCol>
          </CRow>
        </CContainer>
      </>
    )
  }
}

export default FlashOffer
