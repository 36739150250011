import React from 'react'
import { CButton, CSmartTable } from '@coreui/react-pro'
import DataService from '../../services/DataService'
import { navigationStylingProps, paginationProps } from '../../assets/custom/tableSorting'
import { useQuery } from 'react-query'
import IsLoading from '../../components/IsLoading'
import { useNavigate } from 'react-router-dom'
import { formatPriceToDisplayDecimals } from 'src/helpers'
import { useState } from 'react'
import { useEffect } from 'react'
import IsLoadingOverlay from 'src/components/IsLoadingOverlay'
import ShopAvailabilitySwitcher from '../../components/ShopAvailabilitySwitcher'
import { useStoreState } from 'easy-peasy'

export const tableProps = (navigate = null, productAvailableForSale = false) => {
  return {
    scopedColumns: {
      contractor: ({ contractor }) => <td>{contractor.name}</td>,
      deliveryId: ({ deliveryId }) => <td>JK-DELIVERY-{deliveryId}</td>,
      meanPrice: ({ meanPrice, currency }) => (
        <td>{formatPriceToDisplayDecimals(meanPrice) + ' ' + (currency ? currency : 'EUR')}</td>
      ),
      shopAvailability: ({ availableForSale, retailPrice, deliveryId, deliveryProductId }) => (
        <td>
          <ShopAvailabilitySwitcher
            availableForSale={availableForSale}
            retailPrice={retailPrice}
            deliveryProductId={deliveryProductId}
            deliveryId={deliveryId}
          />
        </td>
      ),
      goto: ({ deliveryId }) => (
        <td>
          <CButton
            onClick={() => {
              navigate('/dostawa/' + deliveryId)
            }}
          >
            Szczegóły
          </CButton>
        </td>
      ),
    },
    clickableRows: true,
    tableProps: {
      striped: true,
      hover: true,
    },
    ...navigationStylingProps,
  }
}

const PriceDistributionTable = ({ id, productAvailableForSale }) => {
  const [activePage, setActivePage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const isSuper = useStoreState((store) => store.auth.isSuper)

  const { data, isLoading, isSuccess, refetch, isRefetching } = useQuery(
    ['productPriceDistribution', id, activePage, itemsPerPage],
    DataService.deliveries.getProductDeliveries({
      id: id,
      page: activePage,
      perPage: itemsPerPage,
    }),
    { keepPreviousData: true }
  )

  const navigate = useNavigate()

  useEffect(() => {
    refetch()
  }, [activePage, itemsPerPage, refetch])

  const AdminColumns = [
    {
      key: 'meanPrice',
      label: 'Cena',
      _style: { minWidth: '100px' },
    },
    {
      key: 'amount',
      label: 'Ilość',
      _style: { minWidth: '100px' },
    },
    {
      key: 'format',
      label: 'Format',
      _style: { minWidth: '150px' },
    },
    {
      key: 'deliveryId',
      label: 'ID Dostawy',
      _style: { minWidth: '60px' },
    },
    {
      key: 'contractor',
      label: 'Dostawca',
      _style: { minWidth: '150px' },
    },
    {
      key: 'shopAvailability',
      label: 'Dostępność dostawy',
      _style: { maxWidth: '70px' },
    },
    {
      key: 'goto',
      label: 'Szczegóły',
      _style: { minWidth: '50px' },
    },
  ]
  const columns = [
    {
      key: 'meanPrice',
      label: 'Cena',
      _style: { minWidth: '100px' },
    },
    {
      key: 'amount',
      label: 'Ilość',
      _style: { minWidth: '100px' },
    },
    {
      key: 'format',
      label: 'Format',
      _style: { minWidth: '150px' },
    },
    {
      key: 'deliveryId',
      label: 'ID Dostawy',
      _style: { minWidth: '60px' },
    },
    {
      key: 'contractor',
      label: 'Dostawca',
      _style: { minWidth: '150px' },
    },
    {
      key: 'goto',
      label: 'Szczegóły',
      _style: { minWidth: '50px' },
    },
  ]

  return (
    <>
      <div>
        {isLoading && <IsLoading />}
        {isRefetching && <IsLoadingOverlay />}
        {isSuccess && (
          <CSmartTable
            columns={isSuper ? AdminColumns : columns}
            loading={isLoading}
            items={data.data.results.filter((prod) => prod.amount > 0)}
            itemsPerPage={itemsPerPage}
            itemsPerPageSelect
            pagination={{ external: true }}
            paginationProps={{
              activePage: activePage,
              pages: data.data.pages || 1,
              ...paginationProps,
            }}
            onActivePageChange={(selectedPage) => {
              setActivePage(selectedPage)
            }}
            onItemsPerPageChange={(itemsPerPage) => {
              setActivePage(1)
              setItemsPerPage(itemsPerPage)
            }}
            {...tableProps(navigate, productAvailableForSale)}
          />
        )}
      </div>
    </>
  )
}

export default PriceDistributionTable
