const formFields2 = [
  [
    {
      id: 'currency',
      category: '',
      label: 'Waluta',
      placeholder: 'EUR',
      type: [
        ['EUR', 'EUR'],
        ['PLN', 'PLN'],
        ['USD', 'USD'],
        ['GBP', 'GBP'],
      ],
      required: true,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
    {
      id: 'dateOfOrder',
      category: '',
      label: 'Data zamówienia',
      placeholder: 'np. FIFA',
      type: 'date',
      required: true,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
    {
      id: 'isPaid',
      category: '',
      label: 'Status opłaty',
      placeholder: '---',
      type: [
        ['false', 'NIEOPŁACONE'],
        ['true', 'OPŁACONE'],
      ],
      required: true,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
    {
      id: 'note',
      category: '',
      label: 'Uwagi',
      placeholder: '',
      type: 'textarea',
      required: false,
      rows: 4,
      access: ['ROLE_ACCOUNT_MANAGER'],
    },
  ],
]

export default formFields2
