import { cilMinus, cilPlus } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CFormInput, CFormLabel } from '@coreui/react-pro'
import React, { useEffect, useRef, useState } from 'react'

const OrderRecordHandling = ({ index, data, file, setValue, watch }) => {
  const stepsRef = useRef([]) //increments of posible codes number, starting from 0 i 0 position
  const stepsIdsRef = useRef(['']) //ids of files with codes, 0 position is empty, coresponding to above
  const currStepRef = useRef(0)
  const prevValueRef = useRef(0)
  const [inputValue, setInputValue] = useState(0)
  const deliveryIndex = data.id

  // useEffect(() => {
  //   console.log(watch().products)
  // }, [watch])

  useEffect(() => {
    if (file) {
      stepsRef.current = [0]
      stepsIdsRef.current = ['']
      // console.log(data)
      let prev = 0
      for (let i = 0; i < data.amountInFiles.length; i++) {
        stepsRef.current.push(Number(data.amountInFiles[i].amount) + prev)
        prev = Number(data.amountInFiles[i].amount) + prev
        stepsIdsRef.current.push(data.amountInFiles[i].id)
      }
    }
  }, [file, data.amountInFiles])

  const handleUp = () => {
    const val = Math.min(Number(inputValue) + 1, data.amountInTXT)
    // setInputValue((prev) => Math.min(Number(prev) + 1, data.amountInTXT))
    setInputValue(val)
    setValue(`products[${index}].addingCodes[${deliveryIndex}].amountInTXT`, val)
    setValue(`products[${index}].addingCodes[${deliveryIndex}].id`, data.id)
    // console.log(watch().products)
  }

  const handleDown = () => {
    const val = Math.max(Number(inputValue) - 1, 0)
    // setInputValue((prev) => {return Math.max(Number(prev) - 1, 0)})
    setInputValue(val)
    setValue(`products[${index}].addingCodes[${deliveryIndex}].amountInTXT`, val)
    setValue(`products[${index}].addingCodes[${deliveryIndex}].id`, data.id)
    // console.log(watch().products)
  }

  const handleBlur = () => {
    if (inputValue < 0) {
      setInputValue(0)
      setValue(`products[${index}].addingCodes[${deliveryIndex}].amountInTXT`, Number(inputValue))
      setValue(`products[${index}].addingCodes[${deliveryIndex}].id`, data.id)
    } else if (inputValue > data.amountInTXT) {
      setInputValue(data.amountInTXT)
      setValue(`products[${index}].addingCodes[${deliveryIndex}].amountInTXT`, data.amountInTXT)
      setValue(`products[${index}].addingCodes[${deliveryIndex}].id`, data.id)
    } else {
      setValue(`products[${index}].addingCodes[${deliveryIndex}].amountInTXT`, Number(inputValue))
      setValue(`products[${index}].addingCodes[${deliveryIndex}].id`, data.id)
    }
  }

  const filesIdsUpTo = (up) => {
    let ids = []
    for (let i = 1; i <= up; i++) {
      ids.push(stepsIdsRef.current[i])
    }
    return ids
  }

  const handleStepUp = () => {
    if (currStepRef.current + 1 === stepsRef.current.length) {
      // console.log('do nothing')
      setInputValue(stepsRef.current[currStepRef.current])
    } else {
      currStepRef.current++
      setValue(
        `products[${index}].addingCodes[${deliveryIndex}].sumOfSelectedCodesInFiles`,
        stepsRef.current[currStepRef.current]
      )
      setValue(`products[${index}].addingCodes[${deliveryIndex}].id`, data.id)
      setValue(
        `products[${index}].addingCodes[${deliveryIndex}].codesInFiles`,
        filesIdsUpTo(currStepRef.current)
      )
      setInputValue(stepsRef.current[currStepRef.current])
    }
  }

  const handleStepDown = () => {
    if (currStepRef.current === 0) {
      setInputValue(0)
      setValue(`products[${index}].addingCodes[${deliveryIndex}].codesInFiles`, [])
    } else {
      currStepRef.current--
      setValue(
        `products[${index}].addingCodes[${deliveryIndex}].sumOfSelectedCodesInFiles`,
        stepsRef.current[currStepRef.current]
      )
      setValue(`products[${index}].addingCodes[${deliveryIndex}].id`, data.id)
      setValue(
        `products[${index}].addingCodes[${deliveryIndex}].codesInFiles`,
        filesIdsUpTo(currStepRef.current)
      )
      prevValueRef.current = stepsRef.current[currStepRef.current]
      setInputValue(stepsRef.current[currStepRef.current])
      // console.log('steping down', stepsRef.current[currStepRef.current])
    }
  }

  const handleChange = (e) => {
    console.log('input: ', e.target.value)
    setInputValue(e.target.value)
    setValue(`products[${index}].addingCodes[${deliveryIndex}].amountInTXT`, e.target.value)
    setValue(`products[${index}].addingCodes[${deliveryIndex}].id`, data.id)
  }

  const handleStepBlur = () => {
    let val = 0
    for (let i = 1; i < stepsRef.current.length; i++) {
      if (stepsRef.current[i] <= Number(inputValue)) {
        val = stepsRef.current[i]
        currStepRef.current = i
      } else {
        //break from loop
        i = stepsRef.current.length
      }
    }
    setInputValue(0)
    if (val !== 0) {
      setInputValue(val)
      setValue(`products[${index}].addingCodes[${deliveryIndex}].sumOfSelectedCodesInFiles`, val)
      setValue(
        `products[${index}].addingCodes[${deliveryIndex}].codesInFiles`,
        filesIdsUpTo(currStepRef.current)
      )
    }
  }

  if (!file) {
    return (
      <div className="d-flex align-items-center arrowsDisabled" style={{ gap: '5px' }}>
        <CFormLabel className="p-0 m-0 me-2">Ilość:</CFormLabel>
        <CFormInput
          type="number"
          name={`products[${index}].addingCodes[${deliveryIndex}].amountInTXT`}
          className=" fs-5"
          style={{ maxWidth: '100px', maxHeight: '25px' }}
          value={inputValue}
          min={0}
          max={data.amountInTXT}
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e)
          }}
        />
        <CIcon
          icon={cilMinus}
          className="text-primary btn p-0 m-0 shadow-none"
          onClick={handleDown}
        />
        <CIcon icon={cilPlus} className="text-primary btn p-0 m-0 shadow-none" onClick={handleUp} />
      </div>
    )
  }

  //adding files, amount have steps
  else {
    return (
      <div className="d-flex align-items-center arrowsDisabled" style={{ gap: '5px' }}>
        <CFormLabel className="p-0 m-0 me-2">Ilość:</CFormLabel>
        <CFormInput
          type="number"
          name={`products[${index}].addingCodes[${deliveryIndex}].sumOfSelectedCodesInFiles`}
          className=" fs-5"
          style={{ maxWidth: '100px', maxHeight: '25px' }}
          min={0}
          max={data.amount}
          value={inputValue}
          onChange={(e) => {
            handleChange(e)
          }}
          onBlur={handleStepBlur}
        />
        <CIcon
          icon={cilMinus}
          className="text-primary btn p-0 m-0 shadow-none"
          onClick={handleStepDown}
        />
        <CIcon
          icon={cilPlus}
          className="text-primary btn p-0 m-0 shadow-none"
          onClick={handleStepUp}
        />
      </div>
    )
  }
}

export default OrderRecordHandling
