const formFields = [
  [
    {
      id: 'product',
      category: '',
      label: 'PRODUKT',
      placeholder: 'np. FIFA',
      type: 'dynamicDropdown',
      endpoint: 'products',
      nameField: 'name',
      required: true,
      valueAsNumber: true,
    },
    {
      id: 'contractor',
      category: '',
      label: 'DOSTAWCA',
      type: 'input',
      required: true,
      description: 'To pole tekstowe. Można tutaj podać dowolną wartość.',
    },
    {
      id: 'numberOfCodes',
      category: '',
      label: 'ILOŚĆ KODÓW',
      placeholder: '',
      type: 'number',
      required: true,
      valueAsNumber: true,
    },
    {
      id: 'buyingPrice',
      category: '',
      label: 'CENA SPRZEDAŻY',
      placeholder: '',
      type: 'number',
      required: true,
      valueAsNumber: true,
    },

    {
      id: 'moq',
      category: '',
      label: 'MOQ',
      placeholder: '',
      type: 'number',
      required: true,
      valueAsNumber: true,
    },
    {
      id: 'deliveryTime',
      category: '',
      label: 'CZAS DOSTAWY',
      placeholder: '',
      type: 'text',
      required: true,
    },
    {
      id: 'status',
      category: '',
      label: 'STATUS',
      placeholder: '',
      type: [
        ['AKTUALNE', 'Aktualne'],
        ['ARCHIWALNE', 'Archiwalne'],
        ['STAŁE', 'Stałe'],
      ],
      required: true,
    },
  ],
]

export default formFields
