import React from 'react'
import AddCodesSummary2 from './AddCodesSummary2'

const AddCodesSummaryWrapper2 = ({ data }) => {
  console.log(data)
  return (
    <>
      {data &&
        data.map((product, id) => (
          <div key={id}>
            {Array.isArray(product) ? (
              <AddCodesSummary2 data={product} id={id} />
            ) : (
              <AddCodesSummary2 data={[product]} id={id} />
            )}
          </div>
        ))}
    </>
  )
}

export default AddCodesSummaryWrapper2
