import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
  CSmartTable,
} from '@coreui/react-pro'
import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import DataService from 'src/services/DataService'
import { useParams } from 'react-router-dom'
import { tableProps } from './DeliveryProductCodesTable'
import IsLoading from 'src/components/IsLoading'
import { useForm } from 'react-hook-form'
import EditCodeModal from './EditCodeModal'
import DeleteCodeModal from './DeleteCodeModal'
import CancelButton from 'src/components/CancelButton'
import { useStoreState } from 'easy-peasy'

const DeliveryProductCodes = () => {
  const defaultValues = { code: '' }

  const isAdmin = useStoreState((store) => store.auth.isAdmin)

  const [filter, setFilter] = useState(defaultValues)
  const [editModalCode, setEditModalCode] = useState()
  const [deleteCodeModal, setDeleteCodeModal] = useState()
  const [prevVals, setPrevVals] = useState()
  const [filteredData, setFilteredData] = useState()
  const [selectedIds, setSelectedIds] = useState([])
  const [checkedPage, setCheckedPage] = useState(false)

  const { id, id2 } = useParams()
  const { data, isError, error, isLoading, isFetching, refetch } = useQuery(
    ['codesOfProductInDelivery', id2],
    DataService.deliveries.getCodesOfProductInDelivery(id2)
  )

  const { register, watch } = useForm({
    defaultValues: defaultValues,
  })

  useEffect(() => {
    if (data && data.data) {
      setFilteredData(data.data)
    }
  }, [data])

  const currVals = watch()

  //check if filter value has changed
  useEffect(() => {
    if (JSON.stringify(currVals) !== JSON.stringify(prevVals)) {
      setPrevVals({ ...currVals })
      setFilter(currVals)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currVals, prevVals])

  const timeoutRef = useRef()

  //use timeout to deffer filtering of data
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      if (data?.data) {
        setFilteredData(filterData(filter))
      }
    }, 500)

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
    // eslint-disable-next-line
  }, [filter])

  const handleEditCode = (code) => {
    setEditModalCode(code)
  }
  const handleDeleteCode = (code) => {
    setDeleteCodeModal(code)
  }

  const handleSelectCode = (id) => {
    if (selectedIds?.includes(id)) {
      setCheckedPage(false)
      setSelectedIds((prev) => {
        return prev.filter((item) => item !== id)
      })
    } else {
      setSelectedIds((prev) => {
        //double check for duplicates when checking all
        if (prev.includes(id)) return prev
        else return [...prev, id]
      })
    }
  }

  const handleSelectAll = () => {
    if (checkedPage) {
      setCheckedPage(false)
      setSelectedIds([])
    } else setCheckedPage(true)
  }

  const filterData = (filterVals = defaultValues) => {
    if (data !== undefined) {
      return data.data.filter((item) => {
        return Object.entries(filterVals).every(([key, value]) => {
          if (value === '') {
            return true
          }
          return item[key].includes(value)
        })
      })
    }
    return []
  }

  if (isLoading) return <IsLoading />

  if (isError) {
    console.log(error)
    return <span>bląd</span>
  }

  return (
    <>
      <CContainer fluid className="h-100">
        <CRow>
          <CCol className="d-flex flex-row justify-content-between pb-4">
            <h2>{`Dostawa JK-DELIVERY-${String(id).padStart(5, '0')}`}</h2>
          </CCol>
        </CRow>

        <CRow>
          <CCol xs={12}>
            <CCard>
              <CCardHeader>Kody dostawy</CCardHeader>
              <CCardBody>
                <CRow className="">
                  <CForm>
                    <CRow className="w-full px-2 ">
                      <CCol
                        xl={2}
                        lg={2}
                        md={3}
                        sm={4}
                        xs={5}
                        className="d-flex flex-row align-items-center"
                      >
                        <CFormLabel className="m-0 w-[200px]" htmlFor="properName">
                          Filtr kodów:
                        </CFormLabel>
                      </CCol>
                      <CCol xl={2} lg={3} md={4} sm={8} xs={7} className="px-1">
                        <CFormInput
                          type="string"
                          name="properName"
                          {...register('code')}
                        ></CFormInput>
                      </CCol>
                    </CRow>
                  </CForm>
                </CRow>

                {filteredData && (
                  <CSmartTable
                    items={filteredData}
                    loading={isFetching}
                    {...tableProps(
                      handleEditCode,
                      handleDeleteCode,
                      handleSelectCode,
                      handleSelectAll,
                      selectedIds,
                      checkedPage,
                      setCheckedPage,
                      setSelectedIds
                    )}
                  />
                )}
                <CRow>
                  <CCol className="d-flex flex-row justify-content-between pb-4">
                    {isAdmin && selectedIds && selectedIds.length > 0 && (
                      <CancelButton
                        onClick={() => handleDeleteCode(selectedIds)}
                        label={'Usuń zaznaczone kody'}
                      />
                    )}
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
      {editModalCode && (
        <EditCodeModal code={editModalCode} setCode={setEditModalCode} refetch={refetch} />
      )}
      {deleteCodeModal && (
        <DeleteCodeModal
          code={deleteCodeModal}
          setCode={setDeleteCodeModal}
          refetch={refetch}
          setSelectedIds={setSelectedIds}
        />
      )}
    </>
  )
}

export default DeliveryProductCodes
