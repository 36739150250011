import { CFormInput, CFormLabel, CFormSwitch } from '@coreui/react-pro'
import React, { useEffect } from 'react'
import DynamicDropdownInput from '../DynamicDropdownInput'

const ProductAssigningModalForm = ({ register, watch, setValue, editionMode, data }) => {
  /* eslint-disable */
  useEffect(() => {
    if (editionMode) {
      setValue('enabled', data.enabled)
      setValue('sourceMarginValue', data.sourceMarginValue / 100)
      setValue('sourceMarginType', data.sourceMarginType === 'percent')
    }
  }, [])
  /* eslint-enable */
  return (
    <div className="h5 mb-4 mt-3 px-2">
      {!editionMode && (
        <DynamicDropdownInput
          label={'Produkt zostanie powiązany z '}
          endpoint={'products'}
          required={true}
          initValue={''}
          onChange={(data) => setValue('mainProduct', data.value)}
        />
      )}
      {editionMode && (
        <div className="mb-4">
          <CFormLabel className="pb-3 m-0 me-2">Dostępność produktu</CFormLabel>
          <CFormSwitch
            name={'enabled'}
            size="xl"
            onClick={() => {
              setValue('enabled', !watch().enabled)
            }}
            value={watch().enabled}
            defaultChecked={data.enabled}
          />
        </div>
      )}
      <div className="d-flex align-items-center justify-content-between gap-3">
        <div className="flex-grow-1">
          <CFormLabel className="pb-3 m-0 me-2">
            {watch().sourceMarginType === true ? 'Marża procentowa' : 'Marża walutowa'}
          </CFormLabel>
          <CFormInput
            type="number"
            id={'margin'}
            placeholder={'Cena'}
            {...register('sourceMarginValue', {
              required: true,
              valueAsNumber: true,
            })}
            className="fs-5 me-3"
            step={0.01}
            min={0}
            required={true}
          />
        </div>
        <div className="flex-grow-1">
          <CFormLabel className="pb-3 m-0 me-2">Włącz marżę procentową:</CFormLabel>
          <CFormSwitch
            name={'sourceMarginType'}
            size="xl"
            onClick={() => {
              setValue('sourceMarginType', !watch().sourceMarginType)
            }}
            value={watch().sourceMarginType === 'percent'}
            defaultChecked={data?.sourceMarginType === 'percent'}
          />
        </div>
      </div>
      <small className="mt-3 d-block fw-light fst-italic">
        W tym miejscu podaj marżę, która zostanie doliczona do kwoty zakupu w walucie w jakiej
        dostępny jest produkt.
      </small>
    </div>
  )
}

export default ProductAssigningModalForm
