const formFields = [
  [
    {
      id: 'name',
      category: '',
      label: 'NAZWA OFERTY',
      placeholder: 'np. Nowa oferta',
      type: 'text',
      required: true,
    },
    {
      id: 'product',
      category: '',
      label: 'PRODUKT',
      placeholder: 'np. FIFA',
      type: 'dynamicDropdown',
      endpoint: 'products',
      nameField: 'name',
      required: true,
      valueAsNumber: true,
    },
    {
      id: 'numberOfCodes',
      category: '',
      label: 'ILOŚĆ KODÓW',
      placeholder: '',
      type: 'number',
      required: false,
      valueAsNumber: true,
    },
    {
      id: 'buyingPrice',
      category: '',
      label: 'CENA ZAKUPU',
      placeholder: '',
      type: 'number',
      required: true,
      valueAsNumber: true,
      step: 0.01,
    },

    {
      id: 'srp',
      category: '',
      label: 'SRP',
      placeholder: '',
      type: 'number',
      required: false,
      valueAsNumber: true,
      step: 0.01,
    },
  ],
  [
    {
      id: 'endDate',
      category: '',
      label: 'DATA ZAKOŃCZENIA',
      format: 'ddmmyyhhmm',
      placeholder: '',
      type: 'datetime-local',
      required: false,
    },
    {
      id: 'deliveryTime',
      category: '',
      label: 'CZAS DOSTAWY',
      placeholder: '',
      type: 'text',
      required: true,
    },
    {
      id: 'inStock',
      category: '',
      label: 'W MAGAZYNIE',
      placeholder: '',
      type: [
        [true, 'na stanie'],
        [false, 'brak na stanie'],
      ],
      required: true,
    },
    {
      id: 'codeType',
      category: '',
      label: 'RODZAJ KODU',
      placeholder: '',
      type: ['TXT', 'JPG', 'TXT/JPG'],
      required: true,
    },
    {
      id: 'supervisor',
      category: '',
      label: 'OPIEKUN',
      placeholder: '',
      type: 'dynamicDropdown',
      endpoint: 'users',
      nameField: 'name',
      required: true,
      valueAsNumber: true,
    },
  ],
  [
    {
      id: 'note',
      category: '',
      label: 'UWAGA',
      placeholder: '',
      type: 'textarea',
      rows: '10',
      required: false,
    },
  ],
]

export default formFields
