import React from 'react'
import {
  CButton,
  CCard,
  CCardHeader,
  CCol,
  CContainer,
  CListGroup,
  CListGroupItem,
  CRow,
} from '@coreui/react-pro'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import LoaderService from 'src/services/LoaderService'
import BasicForm from 'src/components/BasicForm'
import formFields from './UserForm'
import DataService from 'src/services/DataService'

import { toast } from 'react-toastify'
import IsLoading from 'src/components/IsLoading'
import { convertStringToBoolean } from 'src/helpers'

const UserEdit = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { data, isLoading, error } = useQuery(['user', id], DataService.getUser(id))

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    values: data,
  })
  console.log(watch())
  const formFieldsEdited = formFields(watch)

  const toastSucces = () => toast('Użytkownik został pomyślnie zmieniony')

  const { mutate } = useMutation(DataService.editUser(id), {
    onSuccess: () => {
      toastSucces()
      navigate('/uzytkownicy')
    },
    onError: (e) => {
      if (e?.message) {
        console.log('adding failed')
        console.log(e.message)
      }
    },
  })

  const onSubmit = async (data) => {
    LoaderService.show()
    mutate(convertStringToBoolean(data))
    LoaderService.hide()
  }

  if (isLoading) return <IsLoading />
  if (!data) {
    console.log(error)
    return <span>missing data</span>
  }

  return (
    <>
      {/* {console.log(watch())} */}
      <CContainer fluid className="h-100">
        <CRow>
          <CCol className="d-flex flex-row justify-content-between pb-4">
            <h2>Edycja użytkownika</h2>
            <div>
              <CButton type="submit" color="primary" className="ms-4 fw-semibold px-5 py-1">
                <label htmlFor="submit-form" tabIndex="0">
                  Zapisz
                </label>
              </CButton>
              <CButton
                color="danger"
                variant="outline"
                className="ms-4 fw-semibold px-5 py-1"
                onClick={() => navigate('/uzytkownicy')}
              >
                Anuluj
              </CButton>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>Dane szczegółowe</CCardHeader>
              <CListGroup flush className="p-2">
                <CListGroupItem className="fs-6">
                  <BasicForm
                    formFields={formFieldsEdited}
                    onSubmit={handleSubmit((data) => onSubmit(data))}
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    watch={watch}
                  ></BasicForm>
                </CListGroupItem>
              </CListGroup>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}
export default UserEdit
