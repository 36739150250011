import { useStoreActions, useStoreState } from 'easy-peasy'
import React, { useEffect, useState } from 'react'
import AuthService from './services/AuthService'
import IsLoading from './components/IsLoading'

const AuthProvider = ({ children }) => {
  const [authChecked, setAuthChecked] = useState(false)
  const setUser = useStoreActions((actions) => actions.auth.setUser)
  const clearUser = useStoreActions((actions) => actions.auth.clearUser)
  const logout = useStoreActions((actions) => actions.auth.logoutUser)

  const token = !!useStoreState((state) => state.auth.token)
  const checkUser = async () => {
    try {
      const _user = await AuthService.CheckToken()
      if (_user) {
        setUser({
          user: _user,
        })
        setAuthChecked(true)
      } else {
        clearUser()
        setAuthChecked(true)
      }
    } catch (e) {
      clearUser()
      setAuthChecked(true)
    }
  }

  useEffect(() => {
    if (token) checkUser()
    else {
      logout()
      setAuthChecked(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (authChecked) {
    return children
  }
  return <IsLoading />
}

export default AuthProvider
