import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import DataService from 'src/services/DataService'
import { useNavigate } from 'react-router-dom'
import EditButton from 'src/components/EditButton'
import ActiveFlashOffersTable from './ActiveFlashOffersTable'
import ExpiredFlashOffersTable from './ExpiredFlashOffersTable'
import IsLoading from 'src/components/IsLoading'
import { useHasAccess } from 'src/helpers'
// import { useStoreState } from 'easy-peasy'

const FlashOffers = () => {
  const { data, isLoading } = useQuery(['offersStats'], DataService.getOffersStats())

  const navigate = useNavigate()

  const [activeKey, setActiveKey] = useState(1)

  let _restrictedAcces = useHasAccess(['ROLE_ADMIN'])

  if (isLoading) return <IsLoading />

  return (
    <>
      <CContainer fluid className="h-100">
        <CRow>
          <CCol className="d-flex flex-row justify-content-end pb-4 ">
            {_restrictedAcces && (
              <EditButton label="Dodaj" onClick={() => navigate(`/flash/new`)} />
            )}
          </CCol>
        </CRow>

        <CRow className="mb-5" xs={{ gutterY: 3 }}>
          <CCol sm={3}>
            <CCard
              className="pt-2 px-3 h-100 thunderBg flex-column justify-content-between"
              style={{ maxHeight: '100px' }}
            >
              <span className="fs-6">Liczba aktywnych ofert</span>
              <span className="fs-1">{data?.active ? data?.active : 0}</span>
            </CCard>
          </CCol>
          <CCol sm={3}>
            <CCard
              className="pt-2 px-3 h-100 thunderBg flex-column justify-content-between"
              style={{ maxHeight: '100px' }}
            >
              <span className="fs-6">Liczba aktywnych flash ofert </span>

              <span className="fs-1">{data?.activeFlash ? data?.activeFlash : 0}</span>
            </CCard>
          </CCol>
          <CCol sm={3}>
            <CCard
              className="pt-2 px-3 h-100px thunderBg flex-column justify-content-between"
              style={{ maxHeight: '100px' }}
            >
              <span className="fs-6">Liczba aktywnych dziennych ofert </span>

              <span className="fs-1">{data?.activeDaily ? data?.activeDaily : 0}</span>
            </CCard>
          </CCol>
          <CCol sm={3}>
            <CCard
              className="pt-2 px-3 h-100px thunderBg flex-column justify-content-between"
              style={{ maxHeight: '100px' }}
            >
              <span className="fs-6">Liczba wygasających ofert </span>

              <span className="fs-1">{data?.expiring ? data?.expiring : 0}</span>
            </CCard>
          </CCol>
        </CRow>
        <CRow className="ms-1">
          <CCard>
            <CCardHeader>
              <CNav variant="tabs" className="card-header-tabs">
                <CNavItem>
                  <CNavLink href="#" active={activeKey === 1} onClick={() => setActiveKey(1)}>
                    Aktywne
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink href="#" active={activeKey === 2} onClick={() => setActiveKey(2)}>
                    Wyczerpane / Przedawnione
                  </CNavLink>
                </CNavItem>
              </CNav>
            </CCardHeader>
            <CCardBody>
              <CTabContent>
                <CTabPane role="tabpanel" aria-labelledby="orders" visible={activeKey === 1}>
                  <ActiveFlashOffersTable navigate={navigate} />
                </CTabPane>
                <CTabPane role="tabpanel" aria-labelledby="zamówienia" visible={activeKey === 2}>
                  <ExpiredFlashOffersTable navigate={navigate} />
                </CTabPane>
              </CTabContent>
            </CCardBody>
          </CCard>
          {/* {isAdmin ? 'Admin' : 'user'} */}
        </CRow>
      </CContainer>
    </>
  )
}

export default FlashOffers
