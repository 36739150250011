import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { CBadge } from '@coreui/react-pro'
import { useHasAccess } from 'src/helpers'

export const AppSidebarNav = ({ items }) => {
  const navLink = (name, icon, badge) => {
    return (
      <>
        {icon && icon}
        {name && name}
        {badge && badge}
      </>
    )
  }

  const navItem = (item, index) => {
    const { component, name, badge, icon, ...rest } = item
    const Component = component
    return (
      <Component
        className={rest.to ? ' text-uppercase ' : 'ps-1'}
        style={rest.to ? { minHeight: '48px' } : { marginTop: '10px' }}
        {...(rest.to && {
          component: NavLink,
        })}
        key={index}
        {...rest}
      >
        {navLink(name, icon)}
        {badge && (
          <CBadge style={{ marginLeft: 'auto' }} color={badge.color}>
            {badge.text}
          </CBadge>
        )}
      </Component>
    )
  }

  return (
    <React.Fragment>
      {items &&
        items.map((item, index) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          if (useHasAccess(item.access) === true) return navItem(item, index)
          return <React.Fragment key={index}></React.Fragment>
        })}
    </React.Fragment>
  )
}

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
}
