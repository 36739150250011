import React, { useState } from 'react'
import AddOrderProduct from './AddOrderProduct'
import DynamicDropdownInput from './DynamicDropdownInput'
import { CRow } from '@coreui/react-pro'
import { notifyError } from 'src/services/notify'
import IsLoading from './IsLoading'

const AddOrderProductWrapper = ({ register, watch, setValue, unregister, disabled }) => {
  const products = watch().products
  const [reset, setReset] = useState(0)

  const handleReset = () => {
    setReset((prev) => prev + 1)
  }

  const handleProductSelect = (e) => {
    const selected = watch().products.find((prod) => prod.id === e.value)
    //check if not already selected
    if (!selected) {
      const index = products.length
      //check if already registerd

      register(`products.${index}`)
      setValue(`products.${index}.id`, e.value)
      setValue(`products.${index}.name`, e.label)
      setValue(`products.${index}.addingCodesSum`, 0) // sum of codes being added to product
      setValue(`products.${index}.addingCodes`, []) // details of deliveries of codes beeing added
    } else {
      notifyError('Próbujesz dodać już dodany produkt!')
    }
    handleReset()
  }

  if (!products) return <IsLoading />

  return (
    <div id={reset}>
      {products.length > 0 &&
        products.map((product, id) => {
          return (
            <AddOrderProduct
              product={product}
              index={id}
              register={register}
              unregister={unregister}
              key={id}
              setValue={setValue}
              watch={watch}
              disabled={disabled}
              reset={handleReset}
            />
          )
        })}

      {!disabled && (
        <CRow className="px-5 pt-0 position-relative">
          <DynamicDropdownInput
            key={reset}
            label={'Dodaj produkt'}
            endpoint={'products'}
            onChange={handleProductSelect}
            disabled={disabled}
            required={true}
            initValue={''}
          />
        </CRow>
      )}
    </div>
  )
}

export default AddOrderProductWrapper
