import { CContainer, CSpinner } from '@coreui/react-pro'
import React from 'react'

const IsLoadingOverlay = () => {
  return (
    <CContainer
      fluid
      className="fill p-0 m-0 d-flex h-100 mx-auto my-auto px-auto align-items-center justify-content-center position-absolute top-50 start-50 translate-middle bg-body opacity-75 zindex-toast"
      style={{ zIndex: '10' }}
    >
      <CSpinner color="primary" />
    </CContainer>
  )
}

export default IsLoadingOverlay
