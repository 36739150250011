import React, { useState, useEffect } from 'react'
import { useStoreState } from 'easy-peasy'

const timeDiff = (now, target) => {
  if (typeof target === 'string') target = new Date(target)
  let diff = Math.floor((target - now) / 1000)
  const minutes = Math.floor(diff / 60)
    .toString()
    .padStart(2, '0')
  const seconds = (diff % 60).toString().padStart(2, '0')
  return { minutes, seconds }
}

const SessionTimer = () => {
  const expirationTime = useStoreState((state) => state.auth.tokenExpiration)
  const [countdown, setCountdown] = useState(timeDiff(new Date(), expirationTime))

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date()
      let diff = Math.floor((expirationTime - now) / 1000)
      if (diff < 0) {
        clearInterval(interval)
        return
      }
      setCountdown(timeDiff(now, expirationTime))
    }, 1000)

    return () => clearInterval(interval)
  }, [expirationTime])

  return (
    <div>
      {(countdown.minutes < 0) & (countdown.seconds < 0)
        ? '00:00'
        : `${countdown.minutes}:
      ${countdown.seconds}`}
    </div>
  )
}

export default SessionTimer
