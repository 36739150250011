import { CButton } from '@coreui/react-pro'
import React from 'react'
import { useQuery } from 'react-query'
// import { downloadFile } from 'src/helpers'
import DataService from 'src/services/DataService'

const DownloadButton = ({ endpoint }) => {
  const { refetch } = useQuery(['file', endpoint], DataService.getFileUrl(endpoint), {
    enabled: false,
    onError: (err) => {
      console.log(err)
    },
  })

  const download = () => {
    refetch().then((data) => {
      console.log(data)
      const href = window.URL.createObjectURL(new Blob([data.data]))
      const link = document.createElement('a')
      link.href = href
      const names = endpoint.split('/')
      link.download = names[names.length - 1]
      console.log(link)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
  }

  return (
    <CButton size="sm" variant="outline" onClick={() => download()}>
      Pobierz załącznik
    </CButton>
  )
}

export default DownloadButton
