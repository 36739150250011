import { CCard, CCardBody, CCol, CContainer, CRow, CSmartTable } from '@coreui/react-pro'
import React from 'react'
import { useQuery } from 'react-query'
import { externalTableProps } from '../productsExternal/ExternalProductsTable'
import { useNavigate } from 'react-router-dom'
import DataService from 'src/services/DataService'
import { useState } from 'react'

import IsLoading from 'src/components/IsLoading'
import DisplayNews from 'src/components/DisplayNews'
import { useStoreState } from 'easy-peasy'
import ProductAssigningModal from '../../components/ProductAssigningModal/ProductAssigningModal'

const ExternalProducts = () => {
  const navigate = useNavigate()
  const isAdmin = useStoreState((store) => store.auth.isAdmin)
  const [assignProduct, setAssignProduct] = useState(null)

  const {
    data: kalixoData,
    isLoading: kalixoIsLoading,
    /*    isSuccess: kalixoIsSuccess,
    isRefetching: kalixoIsRefetching,*/
  } = useQuery(['kalixo-products'], DataService.externalApi.kalixo.getProducts(), {
    keepPreviousData: true,
  })

  /*  useEffect(() => {
    // check if data is present from first req and mark that as mounted
    if (data && isMountedRef.current === false) {
      isMountedRef.current = true
    }
  }, [data])*/

  const openProductAssigningModal = (product) => {
    setAssignProduct(product)
  }

  if (kalixoIsLoading) return <IsLoading />

  return (
    <>
      <CContainer fluid>
        <DisplayNews />
        <CRow>
          <CCol className="d-flex justify-content-between pb-4">
            <div className="d-flex flex-row">
              <h2>Produkty Zewnętrzne</h2>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol lg={12}>
            <CCard className="shadow-none">
              <CCardBody>
                <CSmartTable
                  tableFilter={true}
                  tableFilterLabel={'Szukaj produktu'}
                  tableFilterPlaceholder={'Wpisz nazwę produktu'}
                  loading={kalixoIsLoading}
                  items={kalixoData}
                  itemsPerPage={999999999999999}
                  {...externalTableProps(navigate, isAdmin, openProductAssigningModal)}
                />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <ProductAssigningModal setAssignProduct={setAssignProduct} productData={assignProduct} />
      </CContainer>
    </>
  )
}

export default ExternalProducts
