import { navigationStylingProps, paginationProps } from 'src/assets/custom/tableSorting'
import React from 'react'

const columns = [
  {
    key: 'id',
    label: 'ID',
    _style: { minWidth: '60px' },
    // filter: true,
  },
  {
    key: 'contractorDetails.properName',
    label: 'Nazwa własna',
    _style: { minWidth: '110px' },
    // filter: false,
    // sorter: false,
  },
  {
    key: 'contractorDetails.companyName',
    label: 'Nazwa firmy',
    _style: { minWidth: '150px' },
  },
  {
    key: 'ordersAmount',
    label: 'Operacje',
    _style: { minWidth: '100px' },
    filter: false,
  },
  {
    key: 'allOrdersAmount',
    label: 'Suma zam.',
    _style: { minWidth: '100px' },
  },
  {
    key: 'valueOfAllOrders',
    label: 'Wartość zam.',
    _style: { minWidth: '50px' },
  },
]

export const tableProps = (navigate = null) => {
  return {
    columns: columns,
    scopedColumns: {
      valueOfAllOrders: ({ valueOfAllOrders }) => <td>{valueOfAllOrders / 100} EUR</td>,
    },
    onRowClick: (e) => {
      navigate(`/kontrahent/${e.id}`)
    },
    clickableRows: true,
    // columnFilter: true,
    columnSorter: true,
    pagination: true,
    itemsPerPageSelect: true,
    itemsPerPage: 10,
    tableProps: {
      striped: true,
      hover: true,
    },
    paginationProps,
    ...navigationStylingProps,
  }
}
