import { CModal, CModalBody, CModalFooter } from '@coreui/react-pro'
import React from 'react'
import IsLoadingOverlay from 'src/components/IsLoadingOverlay'
import { useMutation } from 'react-query'
import DataService from 'src/services/DataService'
import { notifyError, notifySucces } from 'src/services/notify'
import EditButton from 'src/components/EditButton'
import CancelButton from 'src/components/CancelButton'

const DeleteCodeModal = ({ code, setCode, setSelectedIds, refetch }) => {
  const {
    mutate: deleteCodeMutation,
    isLoading,
    reset,
  } = useMutation(DataService.deliveries.deleteCodeOfProductInDelivery(), {
    onSuccess: () => {
      setCode(undefined)
      setSelectedIds([])
      notifySucces('Kod został usunięty')
      reset()
      refetch()
    },
    onError: (e) => {
      if (e?.response?.data?.error) {
        notifyError(e.response.data.error)
      }
    },
  })

  const handleDelete = async () => {
    deleteCodeMutation({
      codeIds: code,
    })
  }

  return (
    <>
      <CModal visible={code !== undefined}>
        {isLoading && <IsLoadingOverlay />}
        <CModalBody className="pt-4">
          <div className="mb-4">Potwierdź usunięcie kodu: </div>
          <div> Id: {code.join(', ')}</div>
          <div className="h5 mb-4">{code.code}</div>
        </CModalBody>
        <CModalFooter>
          <EditButton label={'Usuń Kod'} onClick={handleDelete} />
          <CancelButton onClick={() => setCode(undefined)} />
        </CModalFooter>
      </CModal>
    </>
  )
}

export default DeleteCodeModal
