import React from 'react'
import { navigationStylingProps } from 'src/assets/custom/tableSorting'
import { formatData, formatPriceToDisplayDecimals } from '../../helpers'
import { toUpper } from 'lodash'
import Badge from 'src/components/Badge'

const columns = [
  {
    key: 'id',
    label: 'Numer',
    _style: { minWidth: '60px' },
  },
  {
    key: 'contractor',
    label: 'Klient',
    _style: { minWidth: '110px' },
  },
  {
    key: 'dateOfOrder',
    label: 'Data',
    _style: { minWidth: '150px' },
  },
  {
    key: 'products',
    label: 'Produkt',
    _style: { minWidth: '100px' },
    sorter: false,
  },
  // {
  //   key: 'send',
  //   label: 'Wysłane/Kody',
  //   _style: { minWidth: '100px' },
  // },
  {
    key: 'status',
    label: 'Status',
    _style: { minWidth: '100px' },
  },
  {
    key: 'valueByCurrency',
    label: 'Wartość w walucie',
    sorter: false,
  },
  {
    key: 'value',
    label: 'Wartość w EUR',
    _style: { minWidth: '100px' },
  },
  {
    key: 'balance',
    label: 'Zysk',
    _style: { minWidth: '100px' },
  },
]

export const tableProps = (navigate = null) => {
  return {
    columns: columns,
    scopedColumns: {
      dateOfOrder: ({ dateOfOrder }) => (
        <td>
          {/* {console.log(data.dateOfOrder)} */}
          {dateOfOrder?.date ? formatData(dateOfOrder.date, 'ddmmyyyy') : '-'}
        </td>
      ),
      contractor: ({ contractor }) => <td>{contractor.name}</td>,
      products: ({ products }) => (
        <td>
          {products.length === 0
            ? '-'
            : products.length > 1
            ? products[0].name + ' {...}'
            : products[0].name}
        </td>
      ),
      send: ({ sumOfAllProductsCodes, sumOfAllSentProductsCodes }) => (
        <td>{sumOfAllSentProductsCodes + '/' + sumOfAllProductsCodes}</td>
      ),
      status: ({ status }) => (
        <td>
          <Badge module={'order'} status={status} />
        </td>
      ),

      value: ({ value, currency }) => <td>{formatPriceToDisplayDecimals(value) + ` EUR`}</td>,
      valueByCurrency: ({ valueByCurrency, currency }) => (
        <td>{formatPriceToDisplayDecimals(valueByCurrency) + ' ' + toUpper(currency)}</td>
      ),
      balance: ({ balance, currency }) => <td>{formatPriceToDisplayDecimals(balance) + ` EUR`}</td>,
    },
    onRowClick: (e) => {
      navigate(`/zamowienie/${e.id}`)
    },
    clickableRows: true,
    columnSorter: true,
    sorterValue: { column: 'id', state: 'desc' },
    tableProps: {
      striped: true,
      hover: true,
    },
    ...navigationStylingProps,
  }
}
