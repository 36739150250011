import React from 'react'
import { Outlet } from 'react-router-dom'
import { AppAside, AppSidebar, AppHeader } from '../components/index'
import 'react-toastify/dist/ReactToastify.css'
import SessionTimerHandler from 'src/components/SessionTimerHandler'

export const DefaultLayout = () => {
  return (
    <>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 ">
        <AppHeader />
        <div className="flex-grow-1 px-1 bg-gradient pt-3">
          <Outlet />
        </div>
      </div>
      <AppAside />
      <SessionTimerHandler />
    </>
  )
}

export default DefaultLayout
