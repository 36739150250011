import { parseObjectToUrlQuery } from 'src/helpers'
import axios from '../../axios'
import { notifyError, notifySucces } from '../notify'

const SettingsService = {
  getNews: (params) => async () => {
    return await axios
      .get(`/api/settings${parseObjectToUrlQuery(params)}`)
      .then((res) => {
        console.log('@GetNews (settings) data: ', res.data)
        // notifySucces()
        return res.data.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },

  addNews: async (data) => {
    console.log('@AddNews (settings) sending: ', data)
    return await axios
      .post('/api/settings', data)
      .then((res) => {
        console.log('@AddNews (settings) res data: ', res.data)
        notifySucces('Nowa wiadomość została dodana')
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },

  editNews: async (data) => {
    console.log('@EditNews (settings) sending: ', data)
    return await axios
      .put(`/api/settings`, data)
      .then((res) => {
        console.log('@EditNews (settings) res data: ', res.data)
        notifySucces('Wiadomość została zmieniona')
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
}

export default SettingsService
