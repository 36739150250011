import {
  CButton,
  CCol,
  CContainer,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CSmartTable,
  CTabPane,
  CCard,
  CTabContent,
  CCardBody,
  CCardHeader,
} from '@coreui/react-pro'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import DataService from 'src/services/DataService'
import { useNavigate } from 'react-router-dom'
import IsLoading from '../../components/IsLoading'
import { tableProps } from './dailyOffersTable'

const DailyOffers = () => {
  const { data, isLoading } = useQuery(['dailyOffers'], DataService.dailyOffers.getDailyOffers())
  const [activeKey, setActiveKey] = useState(1)

  const navigate = useNavigate()

  if (isLoading) return <IsLoading />

  return (
    <>
      <CContainer fluid>
        <CRow>
          <CCol className="d-flex justify-content-between pb-4">
            <h2>Oferty dzienne</h2>
            <CButton
              color="primary"
              className="ms-4 fw-semibold px-5 py-1"
              onClick={() => navigate(`/dzienne/new`)}
            >
              + Dodaj ofertę
            </CButton>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>
                <CNav variant="tabs" className="card-header-tabs">
                  <CNavItem>
                    <CNavLink href="#" active={activeKey === 1} onClick={() => setActiveKey(1)}>
                      AKTUALNE
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink href="#" active={activeKey === 3} onClick={() => setActiveKey(3)}>
                      STAŁE
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink href="#" active={activeKey === 2} onClick={() => setActiveKey(2)}>
                      ARCHIWALNE
                    </CNavLink>
                  </CNavItem>
                </CNav>
              </CCardHeader>
              <CCardBody>
                <CTabContent>
                  <CTabPane role="tabpanel" aria-labelledby="aktualne" visible={activeKey === 1}>
                    <CSmartTable
                      items={data.data.results.filter((of) => of.status === 'AKTUALNE')}
                      loading={isLoading}
                      {...tableProps(navigate)}
                    />
                  </CTabPane>
                  <CTabPane role="tabpanel" aria-labelledby="archiwalne" visible={activeKey === 2}>
                    <CSmartTable
                      items={data.data.results.filter((of) => of.status === 'ARCHIWALNE')}
                      loading={isLoading}
                      {...tableProps(navigate)}
                    />
                  </CTabPane>
                  <CTabPane role="tabpanel" aria-labelledby="stałe" visible={activeKey === 3}>
                    <CSmartTable
                      items={data.data.results.filter((of) => of.status === 'STAŁE')}
                      loading={isLoading}
                      {...tableProps(navigate)}
                    />
                  </CTabPane>
                </CTabContent>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default DailyOffers
