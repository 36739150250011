const formFields = [
  [
    {
      id: 'code',
      category: '',
      label: 'Kod',
      placeholder: 'np. ABCD-1234',
      type: 'text',
      required: true,
    },
    // {
    //   id: 'status',
    //   category: '',
    //   label: 'Status',
    //   placeholder: 'wybierz...',
    //   type: ['OTWARTE', 'W TRAKCIE', 'ROZWIĄZANE', 'ZAMKNIĘTE'],
    //   required: true,
    // },
    {
      id: 'product',
      category: '',
      label: 'Produkt',
      placeholder: 'np. FIFA',
      type: 'dynamicDropdown',
      endpoint: 'products',
      nameField: 'name',
      required: true,
      valueAsNumber: true,
    },
    {
      id: 'reason',
      category: '',
      label: 'Przyczyna',
      placeholder: 'np. Ulica 12',
      type: 'text',
      required: true,
    },
    {
      id: 'contractor',
      category: '',
      label: 'Klient',
      placeholder: 'np. Firma Sp z o.o.',
      type: 'dynamicDropdown',
      endpoint: 'contractors',
      nameField: 'name',
      required: true,
      valueAsNumber: true,
    },
    {
      id: 'id',
      category: 'order',
      label: 'ID Zamówienia',
      placeholder: 'np. JK-ORDER-123',
      type: 'number',
      required: true,
      valueAsNumber: true,
    },
  ],
]
const formFieldsNew = [
  [
    {
      id: 'code',
      category: '',
      label: 'Kod',
      placeholder: 'np. ABCD-1234',
      type: 'text',
      required: true,
    },
    {
      id: 'product',
      category: '',
      label: 'Produkt',
      placeholder: 'np. FIFA',
      type: 'dynamicDropdown',
      endpoint: 'products',
      nameField: 'name',
      required: true,
      valueAsNumber: true,
    },
    {
      id: 'reason',
      category: '',
      label: 'Przyczyna',
      placeholder: 'np. Ulica 12',
      type: 'text',
      required: true,
    },
    {
      id: 'contractor',
      category: '',
      label: 'Klient',
      placeholder: 'np. Firma Sp z o.o.',
      type: 'dynamicDropdown',
      endpoint: 'contractors',
      nameField: 'name',
      required: true,
      valueAsNumber: true,
    },
    {
      id: 'orderId',
      category: '',
      label: 'ID Zamówienia',
      placeholder: 'np. JK-ORDER-123',
      type: 'number',
      required: true,
      valueAsNumber: true,
    },
    {
      id: 'note',
      category: '',
      label: 'Notatka',
      placeholder: 'np. notatka',
      type: 'textarea',
      rows: 5,
    },
  ],
]

export { formFieldsNew, formFields }
