import React from 'react'
import { useForm } from 'react-hook-form'

import {
  CCard,
  CCardHeader,
  CCol,
  CContainer,
  CListGroup,
  CListGroupItem,
  CRow,
} from '@coreui/react-pro'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import LoaderService from 'src/services/LoaderService'
import BasicForm from 'src/components/BasicForm'
import DataService from 'src/services/DataService'
import CancelButton from 'src/components/CancelButton'
import EditButton from 'src/components/EditButton'
import formFields from './FlashOfferForm'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import IsLoading from 'src/components/IsLoading'
import { convertPriceToWholeNumber, formatPriceToDisplayDecimals } from 'src/helpers'

const FlashOfferEdit = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { data, isLoading } = useQuery(['flash', id], DataService.flashOffers.getFlashOffer(id))

  const { register, handleSubmit, setValue, watch } = useForm({
    values: data,
  })

  useEffect(() => {
    if (data?.srp) {
      setValue('srp', formatPriceToDisplayDecimals(data.srp))
    }
    if (data?.buyingPrice) {
      setValue('buyingPrice', formatPriceToDisplayDecimals(data.buyingPrice))
    }
    if (data?.endDate) {
      setValue('endDate', watch().endDate.split('+')[0])
    }
  }, [data, setValue, watch])

  // console.log(watch())

  const { mutate: getFlashOfferMutation } = useMutation(
    DataService.flashOffers.editFlashOffer(id),
    {
      onSuccess: () => {
        navigate(`/flash/${id}`)
        toast.success('Flash offerta została zaktualizowana')
      },
      onError: (e) => {
        if (e?.message) {
          console.log(e.message)
          // toast.error(e.message)
        }
      },
    }
  )

  if (isLoading) return <IsLoading />
  // if (!data) {
  //   console.log(error)
  //   return <span>missing data</span>
  // }

  const onSubmit = async (data) => {
    LoaderService.show()
    let newData = { ...data }
    newData.srp = convertPriceToWholeNumber(data.srp)
    newData.buyingPrice = convertPriceToWholeNumber(data.buyingPrice)
    newData.supervisor = data.supervisor.id
    if (typeof newData.srp !== 'number') {
      console.log('deleting srp')
      delete newData.srp
    }
    newData.product = data.product.id
    getFlashOfferMutation(newData)
    LoaderService.hide()
  }

  return (
    <>
      <CContainer fluid className="h-100">
        <CRow>
          <CCol className="d-flex flex-row justify-content-between pb-4">
            <h2>Edycja Flash oferty</h2>
            <div>
              <EditButton label="Zapisz" />
              <CancelButton label="Anuluj" onClick={() => navigate(`/flash/${id}`)} />
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>Dane szczegółowe</CCardHeader>
              <CListGroup flush className="p-2">
                <CListGroupItem className="fs-6">
                  <BasicForm
                    formFields={formFields}
                    onSubmit={handleSubmit((data) => onSubmit(data))}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                  ></BasicForm>
                </CListGroupItem>
              </CListGroup>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default FlashOfferEdit
