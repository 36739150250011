import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { StoreProvider } from 'easy-peasy'
import store from './store'
import './scss/style.scss'
import AuthProvider from './AuthProvider'
import Routing from './Routing'
import { ToastContainer } from 'react-toastify'

export const queryCache = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      onError: (error) => {
        if (error?.response?.status === 401) {
          console.log('Token wygasł, zaloguj się ponownie')
          store.getActions().auth.clearUser()
        }
        if (error?.response?.status === 403) {
          console.log('Brak uprawnień do wyświetlenia tej strony')
        }
      },
    },
  },
})

class App extends Component {
  render() {
    return (
      <StoreProvider store={store}>
        <QueryClientProvider client={queryCache}>
          <AuthProvider>
            <ToastContainer position="bottom-right" />
            <BrowserRouter basename="/">
              <Routing />
            </BrowserRouter>
          </AuthProvider>
        </QueryClientProvider>
      </StoreProvider>
    )
  }
}

export default App
