import React from 'react'
import { CCard, CCardBody, CCardHeader, CCol, CContainer, CRow } from '@coreui/react-pro'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import LoaderService from 'src/services/LoaderService'
import BasicForm from 'src/components/BasicForm'
import formFields from './FlashOfferForm'
import DataService from 'src/services/DataService'
import EditButton from 'src/components/EditButton'
import CancelButton from 'src/components/CancelButton'
import { toast } from 'react-toastify'
import { convertPriceToWholeNumber } from 'src/helpers'

const FlashOfferNew = () => {
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      name: '',
      product: '',
      numberOfCodes: '',
      buyingPrice: '',
      // srp: null,
      endDate: '',
      deliveryTime: '',
      inStock: false,
      codeType: '',
      note: '',
    },
  })

  const navigate = useNavigate()

  const { mutate: addFlashOfferMutation } = useMutation(DataService.flashOffers.addFlashOffer, {
    onSuccess: (res) => {
      navigate(`/flash/${res.data.id}`)
      toast.success('Flash oferta została utworzona')
    },
    // onError: (e) => {
    //   if (e?.message) {
    //   }
    // },
  })

  const onSubmit = async (data) => {
    LoaderService.show()
    data.buyingPrice = convertPriceToWholeNumber(data.buyingPrice)
    if (data.srp) {
      data.srp = convertPriceToWholeNumber(data.srp)
    }
    addFlashOfferMutation(data)
    LoaderService.hide()
  }

  return (
    <>
      <CContainer fluid className="h-100">
        <CRow>
          <CCol className="d-flex flex-row justify-content-between pb-4">
            <h2>Tworzenie nowej Flash oferty</h2>
            <div>
              <EditButton label="Zapisz" />
              <CancelButton label="Anuluj" onClick={() => navigate('/flash')} />
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>Dane oferty</CCardHeader>
              <CCardBody>
                <BasicForm
                  formFields={formFields}
                  onSubmit={handleSubmit((data) => onSubmit(data))}
                  register={register}
                  setValue={setValue}
                  watch={watch}
                ></BasicForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}
export default FlashOfferNew
