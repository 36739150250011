import {
  CCard,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
  CSmartTable,
} from '@coreui/react-pro'
import React, { useEffect, useRef, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { tableProps } from './PartnersTable'
import { useNavigate } from 'react-router-dom'
import DataService from 'src/services/DataService'
import { flattenDataStructure } from 'src/helpers'
import EditButton from 'src/components/EditButton'
import IsLoading from 'src/components/IsLoading'
import { useForm } from 'react-hook-form'

const Partners = () => {
  const navigate = useNavigate()
  const [filter, setFilter] = useState()
  const [prevVals, setPrevVals] = useState()

  const { data, error, isLoading, isFetching, refetch, isRefetching } = useQuery(
    'partners',
    DataService.partners.getPartners(filter)
  )

  const { register, watch } = useForm({
    defaultValues: {
      properName: '',
    },
  })

  const currVals = watch()

  useEffect(() => {
    if (JSON.stringify(currVals) !== JSON.stringify(prevVals)) {
      setPrevVals({ ...currVals })
      setFilter(currVals)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currVals, prevVals])

  const timeoutRef = useRef()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (isRefetching) queryClient.cancelQueries({ queryKey: 'partners' })

    if (timeoutRef.current) clearTimeout(timeoutRef.current)

    timeoutRef.current = setTimeout(() => {
      refetch()
    }, 500)

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
    // eslint-disable-next-line
  }, [filter])

  if (isLoading) return <IsLoading />
  if (!data) {
    console.log(error)
    return <span>missing data</span>
  }

  return (
    <>
      <CContainer fluid>
        <CRow>
          <CCol className="d-flex justify-content-between pb-4">
            <h2>Kontrahenci</h2>
            <div>
              <EditButton
                label={'+ Dodaj kontrahenta'}
                onClick={() => {
                  navigate('/kontrahent/new')
                }}
                lg
              />
            </div>
          </CCol>
        </CRow>
        <CRow className="px-3">
          <CCard className="mt-3 overflow-hidden py-3 fill h-100">
            <CForm>
              <CRow className="w-full px-2 ">
                <CCol
                  xl={1}
                  lg={2}
                  md={3}
                  sm={4}
                  xs={5}
                  className="d-flex flex-row align-items-center"
                >
                  <CFormLabel className="m-0" htmlFor="properName">
                    Nazwa Własna:
                  </CFormLabel>
                </CCol>
                <CCol xl={2} lg={3} md={4} sm={8} xs={7} className="px-1">
                  <CFormInput
                    type="string"
                    name="properName"
                    {...register('properName')}
                  ></CFormInput>
                </CCol>
              </CRow>
            </CForm>
          </CCard>
        </CRow>
        <CRow>
          <CCol>
            {data && (
              <CSmartTable
                items={flattenDataStructure(data.data.results)}
                loading={isFetching}
                {...tableProps(navigate)}
              />
            )}
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default Partners
