export const listFields = [
  {
    id: 'sourceName',
    category: '',
    label: 'NAZWA PRODUKTU',
    placeholder: '-',
  },
  // {
  //   id: 'name',
  //   category: 'productCategory',
  //   label: 'KATEGORIA PRODUKTU',
  //   placeholder: '-',
  // },

  {
    id: 'sourcePrice',
    category: '',
    label: 'Cena zakupu',
    placeholder: '-',
    component: {
      type: 'price',
    },
    suffix: 'EUR',
  },
  {
    id: 'sourceEan',
    category: '',
    label: 'EAN',
    placeholder: '-',
  },
  {
    id: 'sourceStatus',
    category: '',
    label: 'Status Kalixo',
    placeholder: '-',
    component: {
      type: 'badge',
      param: 'externalProducts',
    },
  },
]
