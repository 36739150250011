import { CFormInput, CInputGroup } from '@coreui/react-pro'
import React from 'react'

const TfaInput = ({ register, setFocus }) => {
  const check2FAInput = (a) => {
    if (!a.target.value.match(/[0-9]/g)) {
      return (a.target.value = '')
    }

    const nextDigit = parseInt(a.target.name.substr(a.target.name.length - 1)) + 1
    setFocus(a.target.name.substring(0, a.target.name.length - 1) + nextDigit)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Backspace' && event.target.value === '') {
      const prevDigit = parseInt(event.target.name.substr(event.target.name.length - 1)) - 1
      setFocus(event.target.name.substring(0, event.target.name.length - 1) + prevDigit)
    }
  }

  return (
    <CInputGroup className="gap-2">
      <CFormInput
        autoFocus
        className="text-center flex-grow-0"
        style={{ width: '48px', height: '48px' }}
        size="lg"
        name="tfaTokenDigit1"
        type="text"
        maxLength="1"
        onKeyDown={handleKeyDown}
        {...register('tfaTokenDigit.0', {
          onChange: check2FAInput,
          required: true,
          pattern: /[0-9]{1}/,
        })}
      />
      <CFormInput
        className="text-center flex-grow-0"
        style={{ width: '48px', height: '48px' }}
        size="lg"
        name="tfaTokenDigit2"
        type="text"
        maxLength="1"
        onKeyDown={handleKeyDown}
        {...register('tfaTokenDigit.1', {
          onChange: check2FAInput,
          required: true,
          pattern: /[0-9]{1}/,
        })}
      />
      <CFormInput
        className="text-center flex-grow-0"
        style={{ width: '48px', height: '48px' }}
        size="lg"
        name="tfaTokenDigit3"
        type="text"
        maxLength="1"
        onKeyDown={handleKeyDown}
        {...register('tfaTokenDigit.2', {
          onChange: check2FAInput,
          required: true,
          pattern: /[0-9]{1}/,
        })}
      />
      <div
        style={{
          height: '100%',
          marginTop: '23px',
          borderBottom: '1px solid white',
          width: '15px',
        }}
      ></div>
      <CFormInput
        className="text-center flex-grow-0"
        style={{ width: '48px', height: '48px' }}
        size="lg"
        name="tfaTokenDigit4"
        type="text"
        maxLength="1"
        onKeyDown={handleKeyDown}
        {...register('tfaTokenDigit.3', {
          onChange: check2FAInput,
          required: true,
          pattern: /[0-9]{1}/,
        })}
      />
      <CFormInput
        className="text-center flex-grow-0"
        style={{ width: '48px', height: '48px' }}
        size="lg"
        name="tfaTokenDigit5"
        type="text"
        maxLength="1"
        onKeyDown={handleKeyDown}
        {...register('tfaTokenDigit.4', {
          onChange: check2FAInput,
          required: true,
          pattern: /[0-9]{1}/,
        })}
      />
      <CFormInput
        className="text-center flex-grow-0"
        style={{ width: '48px', height: '48px' }}
        size="lg"
        name="tfaTokenDigit6"
        type="text"
        maxLength="1"
        onKeyDown={handleKeyDown}
        {...register('tfaTokenDigit.5', {
          onChange: check2FAInput,
          required: true,
          pattern: /[0-9]{1}/,
        })}
      />
    </CInputGroup>
  )
}

export default TfaInput
